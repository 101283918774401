import React, { FC, useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import useReportsDetails from "./useReportsData";
import {FaChevronRight} from "react-icons/fa";
import classes from "./style.module.scss";

const Reports: FC<{
  //  openStatus: boolean;
}> = ({}) => {
  const location = useLocation();
  const { activeTab, setActiveTab, allReportsData, searchText, setSearchText } =
    useReportsDetails({
      fromDate: "",
      toDate: "",
    });

  useEffect(() => {
    console.log(location.pathname);
    if (location.pathname === "/reports/credit-debit-reports") {
      setActiveTab("creditDebitReports");
    }else if (location.pathname === "/reports/payment-receipts") {
      setActiveTab("paymentReports");
    }
  }, []);
  return (
    <>
      <div className="p-4">
        <div className="flex gap-4">
          <div className="grow-[1] " style={{ width: "14%" }}>
            <div className="flex flex-col">
              <Link
                className={`py-2 px-4 cursor-pointer font-medium flex items-center justify-content-between br-10 mb-3 ${
                  activeTab === "creditDebitReports"
                    ? classes.active
                    : classes.tab
                }`}
                onClick={() => {
                  setActiveTab("creditDebitReports");
                }}
                to="/reports/debit-credit-reports"
              >
                Debits & Credits Report <FaChevronRight />
              </Link>
            </div>
            <div className="flex flex-col">
              <Link
                className={`py-2 px-4 cursor-pointer font-medium flex items-center justify-content-between br-10 mb-3 ${
                  activeTab === "paymentReports" ? classes.active : classes.tab
                }`}
                onClick={() => {
                  setActiveTab("paymentReports");
                }}
                to="/reports/payment-receipts"
              >
                Trade Summary Report <FaChevronRight />
              </Link>
            </div>
          </div>
          <div className="grow-[11] " style={{ width: "86%" }}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
