import { ChangeEvent, FC, useEffect, useState } from "react";
import { IoMdCloseCircleOutline, IoMdSearch } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import PartnerInfo from "../../components/partner-info";
import SelectCrop from "../../components/select-crop";
import { Button, Input, RadioButton } from "../../components/ui";
import SelectDate from "../../components/ui/select-date";
import PartyDropDown from "../../components/ui/select-party";
import { OnoSelect } from "../../components/ui/select2";
import { updateCropData } from "../../reducers/tradeSlice";
import useEditTrade from "./useEditTrade";

const TradeDetailsForm: FC<{
  editStatus: string;
  onTradeFormClosed: any;
}> = ({ editStatus, onTradeFormClosed }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [showTransporterOption, setShowTransporterOptions] = useState(false);
  const [showSrcOption, setShowSrcOption] = useState(false);
  const {
    tapalInvoice,
    receipts,
    otherDocuments,
    onUploadImage,
    onCreateTrade,
    onRemoveImage,
    tradeEditStatus,
    tradeDetail,
    selectedCrop,
    partyData,
    handelSearch,
    clearSearch,
    searchText,
    traderData,
    selectedPartner,
    setSeletedPartner,
    setSeletedSrc,
    setTraderData,
    setFreightAmt,
    freightAmt,
    handleDateSelection,
    selectedDate,
    tradeTypeMode,
    onChangeTradeMode,
    setInvoiceAmt,
    invoiceAmt,
    setTradeTypeMode,
    selectedTrader,
    setAdvanceAmt,
    setComments,
    tradecomments,
    freightAdv,
    selectedSrc,
    selectedSrcParty,
    setComanyData,
    companyData,
    setTradeType,
    tradeType,
    setTradeTruckNumber,
    srcVehNum,
    setCommodityCost,
    commodityCost,
    tententType,
    driverMobile,
    driverName,
    containerId,
    setDriverMobile,
    setDriverName,
    setContainerId,
    miscCost,
    setMiscCost,
  } = useEditTrade({
    traderId: parseInt(params.traderId || "0"),
    tradeId: parseInt(params.tradeId || "0"),
    mode: editStatus,
    closeModal: onTradeFormClosed,
  });
  useEffect(() => {
    if (editStatus == "edit") {
      if (tradeDetail != null) {
        setSeletedPartner(selectedTrader);
        setFreightAmt(tradeDetail?.freightAmt);
        setInvoiceAmt(tradeDetail?.invAmt);
        setTradeTypeMode(tradeDetail?.tradeMode);
        setAdvanceAmt(tradeDetail?.freightAdv);
        setComments(tradeDetail?.tradecomments);
        setSeletedSrc(selectedSrcParty);
        setTradeTruckNumber(tradeDetail?.srcVehNum);
        setCommodityCost(tradeDetail?.commodityCost);
        setContainerId(tradeDetail?.containerId);
        setDriverName(tradeDetail?.driverName);
        setDriverMobile(tradeDetail?.driverMobile);
        setMiscCost(tradeDetail?.misc);
      }
    }
  }, [
    tradeDetail?.freightAmt,
    tradeDetail?.invAmt,
    tradeDetail?.tradeMode,
    tradeDetail?.freightAdv,
    tradeDetail?.tradecomments,
  ]);
  const onChangeRate = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateCropData({
        selectedCrop: {
          ...selectedCrop!,
          rate: e.target.value !== "" ? parseFloat(e.target.value) : "",
        },
      })
    );
    var weight = selectedCrop?.weight;
    if (
      selectedCrop?.weightUnit == "RATE_PER_KG" &&
      selectedCrop != null &&
      selectedCrop?.weight &&
      tententType == "BAYER" &&
      selectedCrop?.unitType
    ) {
      if (selectedCrop?.unitType == "QUINTAL") {
        weight = selectedCrop?.weight * 100;
      } else if (selectedCrop?.unitType == "MT/TON") {
        weight = selectedCrop?.weight * 1000;
      } else {
        weight = selectedCrop?.weight;
      }
    }
    if (
      selectedCrop != null &&
      selectedCrop?.weight &&
      selectedCrop?.weightUnit == "RATE_PER_KG"
    ) {
      setCommodityCost(
        parseFloat((parseFloat(e.target.value) * Number(weight)).toFixed(2))
      );
    } else if (selectedCrop?.weightUnit == "RATE_PER_UNIT") {
      setCommodityCost(
        parseFloat(
          (parseFloat(e.target.value) * Number(selectedCrop?.qty)).toFixed(2)
        )
      );
    }
    // if (selectedCrop != null && selectedCrop?.weight) {
    //   setInvoiceAmt(
    //     parseFloat(
    //       (parseFloat(e.target.value) * Number(selectedCrop?.weight)).toFixed(2)
    //     )
    //   );
    // }
  };
  const onChangeCost = (e: ChangeEvent<HTMLInputElement>) => {
    setCommodityCost(e.target.value !== "" ? parseFloat(e.target.value) : "");
    if (
      selectedCrop != null &&
      selectedCrop?.weight &&
      selectedCrop?.weightUnit == "RATE_PER_KG"
    ) {
      dispatch(
        updateCropData({
          selectedCrop: {
            ...selectedCrop!,
            rate: parseFloat(
              (
                parseFloat(e.target.value) / Number(selectedCrop?.weight)
              ).toFixed(2)
            ),
          },
        })
      );
    } else if (selectedCrop?.weightUnit == "RATE_PER_UNIT") {
      dispatch(
        updateCropData({
          selectedCrop: {
            ...selectedCrop!,
            rate: parseFloat(
              (parseFloat(e.target.value) / Number(selectedCrop?.qty)).toFixed(
                2
              )
            ),
          },
        })
      );
    }
  };
  // const onChangeInvoice = (e: ChangeEvent<HTMLInputElement>) => {
  //   setInvoiceAmt(e.target.value !== "" ? parseFloat(e.target.value) : "");
  //   if (selectedCrop != null && selectedCrop?.weight) {
  //     dispatch(
  //       updateCropData({
  //         selectedCrop: {
  //           ...selectedCrop!,
  //           rate: parseFloat(
  //             (
  //               parseFloat(e.target.value) / Number(selectedCrop?.weight)
  //             ).toFixed(2)
  //           ),
  //         },
  //       })
  //     );
  //   }
  // };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Or use any breakpoint you prefer
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div
        className="pb-3 overflow-y-auto"
        style={{
          height: isMobile ? "calc(100vh - 18rem)" : "calc(100vh - 22rem)",
        }}
      >
        <div className="pb-0 md:pb-3 w-full blcok md:flex gap-4">
          <div className="grow-[3]">
            <span className="text-sm">
              Source Trader<span className="color-red">*</span>
            </span>
            <div
              className={`relative flex items-center bg-white shrink-0 px-2 py-2 border br-10 mt-2`}
              style={{ borderRightWidth: "1px" }}
            >
              <div
                className={`flex items-center cursor-pointer w-full`}
                onClick={() => {
                  setShowSrcOption(true);
                  setTradeType("SOURCE");
                }}
              >
                {selectedSrc != null ? (
                  <PartnerInfo
                    profilePic=""
                    name={selectedSrc.tradeName}
                    shortName={""}
                    partnerType={"Trader"}
                    partnerId={selectedSrc.traderId}
                    partnerMobile={selectedSrc.mobile}
                    partyName={selectedSrc.name}
                  />
                ) : (
                  <div className={`relative w-full `}>
                    <input
                      className="w-full px-5 cursor-pointer"
                      placeholder="Search by Trade Name / Trade Id"
                      value={searchText}
                      onChange={handelSearch}
                    />
                    <IoMdSearch
                      className="absolute color-primary"
                      style={{
                        top: ".3rem",
                        left: "0rem",
                        fontSize: "1.2rem",
                      }}
                    />
                  </div>
                )}
              </div>
              {(searchText !== "" || selectedSrc != null) && (
                <IoMdCloseCircleOutline
                  onClick={() => {
                    clearSearch("SOURCE");
                  }}
                  className="absolute color-primary cursor-pointer w-10"
                  style={{
                    top: "32%",
                    right: "1rem",
                    fontSize: "1.2rem",
                    color: "gray",
                    cursor: "pointer",
                  }}
                />
              )}
              <PartyDropDown
                open={showSrcOption}
                onChange={(trader) => {
                  setShowSrcOption(false);
                  dispatch(
                    updateCropData({
                      selectedSrcParty: trader,
                      selectedCrop: selectedCrop,
                    })
                  );
                  setSeletedSrc(trader);
                  setTraderData(partyData);
                }}
                allTraders={traderData}
                selectedPartner={selectedSrc}
                onClose={() => {
                  setShowSrcOption(false);
                }}
              />
            </div>
          </div>
          <div className="grow-[3] pt-3 md:pt-0">
            <span className="text-sm">
              Destination Trader<span className="color-red">*</span>
            </span>
            <div
              className={`relative flex items-center bg-white shrink-0 px-2 py-2 border br-10 mt-2`}
              style={{ borderRightWidth: "1px" }}
            >
              <div
                className={`flex items-center cursor-pointer w-full`}
                onClick={() => {
                  setShowTransporterOptions(true);
                  setTradeType("DEST");
                }}
              >
                {selectedPartner != null ? (
                  <PartnerInfo
                    profilePic=""
                    name={selectedPartner.tradeName}
                    shortName={""}
                    partnerType={"Trader"}
                    partnerId={selectedPartner.traderId}
                    partnerMobile={selectedPartner.mobile}
                    partyName={selectedPartner.name}
                  />
                ) : (
                  <div className={`relative w-full `}>
                    <input
                      className="w-full px-5 cursor-pointer"
                      placeholder="Search by Trade Name / Trade Id"
                      value={searchText}
                      onChange={handelSearch}
                    />
                    <IoMdSearch
                      className="absolute color-primary"
                      style={{
                        top: ".3rem",
                        left: "0rem",
                        fontSize: "1.2rem",
                      }}
                    />
                  </div>
                )}
              </div>
              {(searchText !== "" || selectedPartner != null) && (
                <IoMdCloseCircleOutline
                  onClick={() => {
                    clearSearch("DEST");
                  }}
                  className="absolute color-primary cursor-pointer w-10"
                  style={{
                    top: "32%",
                    right: "1rem",
                    fontSize: "1.2rem",
                    color: "gray",
                    cursor: "pointer",
                  }}
                />
              )}
              <PartyDropDown
                open={showTransporterOption}
                onChange={(trader) => {
                  setShowTransporterOptions(false);
                  dispatch(
                    updateCropData({
                      selectedParty: trader,
                      selectedCrop: selectedCrop,
                    })
                  );
                  setSeletedPartner(trader);
                  setTraderData(partyData);
                }}
                allTraders={traderData}
                selectedPartner={selectedPartner}
                onClose={() => {
                  setShowTransporterOptions(false);
                }}
              />
            </div>
          </div>
        </div>
        <div className="pt-3 w-full blcok md:flex gap-4">
          <div className="grow-[3]">
            <label className="text-sm">
              Trade Type <span className="color-red">{"*"}</span>
            </label>
            <RadioButton
              displayType="horizontal"
              data={[
                { label: "Order Mode", value: "ORDER" },
                { label: "Commission Mode", value: "COMM" },
              ]}
              value={tradeTypeMode}
              pointer={{ label: "label", value: "value" }}
              onChange={(value) => {
                onChangeTradeMode(value);
              }}
              styles={{ display: "flex" }}
            />
          </div>
          <div className="grow-[3] pt-3 md:pt-0">
            <span className="text-sm">
              Dispatch Date<span className="color-red">*</span>
            </span>
            <SelectDate
              onChange={handleDateSelection}
              selectedDate={selectedDate}
            />
          </div>
        </div>
        <div className="pt-3">
          <span className="text-sm">
            Select Crop<span className="color-red">*</span>
          </span>
          <div className="grow overflow-auto scrollbar mt-2">
            <SelectCrop />
          </div>
        </div>
        <div className="pt-3 w-full blcok md:flex gap-4">
          <div className="grow-[3]">
            <span className="text-sm">
              Total Quantity<span className="color-red">*</span>
            </span>
            <div className="mt-2 relative">
              <Input
                label=""
                type="number"
                required={true}
                value={selectedCrop?.qty}
                onFocus={(e) => {
                  e.target.value === "0" &&
                    dispatch(
                      updateCropData({
                        selectedCrop: {
                          ...selectedCrop!,
                          qty: "",
                        },
                      })
                    );
                }}
                onChange={(e) => {
                  dispatch(
                    updateCropData({
                      selectedCrop: {
                        ...selectedCrop!,
                        qty:
                          e.target.value !== ""
                            ? parseFloat(e.target.value)
                            : "",
                        weight:
                          selectedCrop?.qtyUnit == "KGS"
                            ? parseFloat(e.target.value)
                            : selectedCrop?.weight!,
                      },
                    })
                  );
                }}
              />
              {selectedCrop != null && (
                <>
                  <div
                    className="absolute cursor-pointer border-l pl-2 flex justify-center text-sm"
                    style={{
                      top: ".6rem",
                      right: "1rem",
                      cursor: "pointer",
                      width: "6rem",
                    }}
                  >
                    <OnoSelect
                      value={selectedCrop?.qtyUnit}
                      options={[
                        { label: "CRATES", value: "CRATES" },
                        { label: "BAGS", value: "BAGS" },
                        { label: "SACS", value: "SACS" },
                        { label: "BOXES", value: "BOXES" },
                        { label: "KGS", value: "KGS" },
                        { label: "LOADS", value: "LOADS" },
                        { label: "PIECES", value: "PIECES" },
                      ]}
                      onChange={(e) => {
                        dispatch(
                          updateCropData({
                            selectedCrop: {
                              ...selectedCrop,
                              qtyUnit: e.value,
                              weight:
                                e.value == "KGS"
                                  ? selectedCrop?.qty
                                  : selectedCrop?.weight,
                            },
                          })
                        );
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="grow-[3] pt-3 md:pt-0">
            <span className="text-sm">
              Total Weight<span className="color-red">*</span>
            </span>
            <div className="mt-2 relative">
              {selectedCrop?.qtyUnit == "KGS" ? (
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={selectedCrop?.qty}
                />
              ) : (
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={selectedCrop?.weight}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      dispatch(
                        updateCropData({
                          selectedCrop: {
                            ...selectedCrop!,
                            weight: "",
                          },
                        })
                      );
                  }}
                  onChange={(e) => {
                    dispatch(
                      updateCropData({
                        selectedCrop: {
                          ...selectedCrop!,
                          weight:
                            e.target.value !== ""
                              ? parseFloat(e.target.value)
                              : "",
                        },
                      })
                    );
                  }}
                />
              )}
              {selectedCrop != null && (
                <>
                  {tententType == "BAYER" ? (
                    <div
                      className="absolute cursor-pointer border-l pl-2 flex justify-center text-sm"
                      style={{
                        top: ".7rem",
                        right: "1rem",
                        cursor: "pointer",
                        width: "8rem",
                      }}
                    >
                      <OnoSelect
                        value={selectedCrop?.unitType}
                        options={[
                          { label: "KGS", value: "KGS" },
                          {
                            label: "Quintal",
                            value: "QUINTAL",
                          },
                          {
                            label: "MT/TON",
                            value: "MT/TON",
                          },
                        ]}
                        onChange={(e) => {
                          dispatch(
                            updateCropData({
                              selectedCrop: {
                                ...selectedCrop!,
                                unitType: e.value,
                              },
                            })
                          );
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className="absolute cursor-pointer border-l pl-2 flex justify-center text-sm"
                      style={{
                        top: ".8rem",
                        right: "1rem",
                        cursor: "pointer",
                        width: "6rem",
                      }}
                    >
                      <span className="">{"KGS"}</span>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        {tradeTypeMode == "ORDER" && (
          <div className="pt-3 flex gap-4">
            <div className="grow-[2]" style={{ width: "50%" }}>
              <span className="text-sm">
                Rate<span className="color-red">*</span>
              </span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={selectedCrop?.rate}
                  onChange={(e) => {
                    onChangeRate(e);
                  }}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      dispatch(
                        updateCropData({
                          selectedCrop: {
                            ...selectedCrop!,
                            rate: "",
                          },
                        })
                      );
                  }}
                />
              </div>
            </div>
            <div className="grow-[2]" style={{ width: "50%" }}>
              <span className="text-sm">
                Rate Type<span className="color-red">*</span>
              </span>
              <div className="grow-[3]  mt-2" style={{ height: "50px" }}>
                <OnoSelect
                  value={selectedCrop?.weightUnit}
                  options={[
                    { label: "Rate per Kg", value: "RATE_PER_KG" },
                    { label: "Rate per Unit", value: "RATE_PER_UNIT" },
                  ]}
                  onChange={(e) => {
                    dispatch(
                      updateCropData({
                        selectedCrop: {
                          ...selectedCrop!,
                          weightUnit: e.value,
                        },
                      })
                    );
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div className="pt-3">
          <div className="flex gap-2">
            <div className="grow-[2]">
              <span className="text-sm">
                Freight Amount<span className="color-red">*</span>
              </span>
            </div>
            {tradeTypeMode == "ORDER" && (
              <div className="grow-[2]">
                <span className="text-sm">
                  Commodity Cost<span className="color-red">*</span>
                </span>
              </div>
            )}
          </div>
          <div className="mt-2 relative gap-4 flex">
            <div className="grow-[2]">
              <Input
                label=""
                type="number"
                required={true}
                value={freightAmt}
                onChange={(e) => {
                  setFreightAmt(
                    e.target.value !== "" ? parseFloat(e.target.value) : ""
                  );
                }}
                onFocus={(e) => {
                  e.target.value === "0" && setFreightAmt("");
                }}
                style={{
                  borderRight: 0,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              />
            </div>
            {tradeTypeMode == "ORDER" && (
              <div className="grow-[2]">
                <Input
                  label=""
                  type="number"
                  required={false}
                  value={commodityCost}
                  onChange={(e) => onChangeCost(e)}
                  onFocus={(e) => {
                    e.target.value === "0" && setCommodityCost("");
                  }}
                  style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {tradeTypeMode == "ORDER" && (
          <div className="mt-3 relative gap-4 flex">
            <div className="grow-[2]">
              <span className="text-sm">Miscellaneous</span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={miscCost}
                  onChange={(e) => {
                    setMiscCost(parseFloat(e.target.value) || "");
                  }}
                  onFocus={(e) => {
                    e.target.value === "0" && setMiscCost("");
                  }}
                />
              </div>
            </div>
            <div className="grow-[2]">
              <span className="text-sm">
                Invoice Amount<span className="color-red">*</span>
              </span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={invoiceAmt}
                  disabled={true}
                  // onChange={(e) => {
                  //   onChangeCost(e);
                  // }}
                  onFocus={(e) => {
                    e.target.value === "0" && setInvoiceAmt("");
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div className="pt-3">
          Freight Advance
          <div className="mt-2 relative">
            <Input
              label=""
              type="number"
              required={false}
              value={freightAdv}
              onChange={(e) => {
                setAdvanceAmt(
                  e.target.value !== "" ? parseFloat(e.target.value) : ""
                );
              }}
              onFocus={(e) => {
                e.target.value === "0" && setAdvanceAmt("");
              }}
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            />
          </div>
          <p className="text-sm">
            Balance Freight: {Number(freightAmt) - Number(freightAdv)}
          </p>
        </div>
        <div className="mt-3 relative gap-4 flex">
          <div className="grow-[2]">
            <span className="text-sm"> Truck Number</span>
            <div className="mt-2 relative">
              <Input
                label=""
                type="text"
                required={true}
                value={srcVehNum}
                onChange={(e) => {
                  setTradeTruckNumber(e.target.value || "");
                }}
                onFocus={(e) => {
                  e.target.value === "0" && setTradeTruckNumber("");
                }}
              />
            </div>
          </div>
          <div className="grow-[2]">
            <span className="text-sm">Container Number</span>
            <div className="mt-2 relative">
              <Input
                label=""
                type="text"
                required={true}
                value={containerId}
                onChange={(e) => {
                  setContainerId(e.target.value || "");
                }}
                onFocus={(e) => {
                  e.target.value === "0" && setContainerId("");
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-3 relative gap-4 flex">
          <div className="grow-[2]">
            <span className="text-sm">Driver Name</span>
            <div className="mt-2 relative">
              <Input
                label=""
                type="text"
                required={true}
                value={driverName}
                onChange={(e) => {
                  setDriverName(e.target.value || "");
                }}
                onFocus={(e) => {
                  e.target.value === "0" && setDriverName("");
                }}
              />
            </div>
          </div>
          <div className="grow-[2]">
            <span className="text-sm">Driver Number</span>
            <div className="mt-2 relative">
              <Input
                label=""
                type="number"
                required={true}
                value={driverMobile}
                onChange={(e) => {
                  setDriverMobile(e.target.value || "");
                }}
                onFocus={(e) => {
                  e.target.value === "0" && setDriverMobile("");
                }}
              />
            </div>
          </div>
        </div>
        <div className="pt-3">
          <span className="text-sm">Comments</span>
          <div className="mt-2 relative">
            <Input
              label=""
              type="text"
              required={false}
              value={tradecomments}
              onChange={(e) => {
                setComments(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="pt-3">
          <span className="color-primary">Platform Facilitator Details</span>
        </div>
        <div className="blcok md:flex pt-3 gap-4">
          <div className="grow-[3]">
            <span className="text-sm">Company</span>
            <div className="mt-2 relative">
              <Input
                label=""
                type="text"
                required={false}
                value={companyData?.company}
                onChange={(e) => {
                  setComanyData({
                    ...companyData,
                    company: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="blcok md:flex pt-3 gap-4">
          <div className="grow-[3]">
            <span className="text-sm">Relationship Manager (Source)</span>
            <div className="mt-2 relative">
              <Input
                label=""
                type="text"
                required={false}
                value={tradeDetail?.srcRmName}
                onChange={(e) => {
                  setComanyData({
                    ...companyData,
                    srcRmName: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="grow-[3] pt-3 md:pt-0">
            <span className="text-sm">Contact No (Source)</span>
            <div className="mt-2 relative">
              <Input
                label=""
                type="number"
                required={false}
                value={tradeDetail?.srcRmMobile}
                onChange={(e) => {
                  setComanyData({
                    ...companyData,
                    srcRmMobile: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="blcok md:flex pt-3 gap-4">
          <div className="grow-[3]">
            <span className="text-sm">Relationship Manager (Destination)</span>
            <div className="mt-2 relative">
              <Input
                label=""
                type="text"
                required={false}
                value={tradeDetail?.destRmName}
                onChange={(e) => {
                  setComanyData({
                    ...companyData,
                    destRmName: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="grow-[3] pt-3 md:pt-0">
            <span className="text-sm">Contact No (Destination)</span>
            <div className="mt-2 relative">
              <Input
                label=""
                type="number"
                required={false}
                value={tradeDetail?.destRmMobile}
                onChange={(e) => {
                  setComanyData({
                    ...companyData,
                    destRmMobile: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="flex items-center bottom-0 right-0 w-full bg-white px-4 py-4 border-top gap-2">
          <Button
            variant="primaryLight"
            className="w-full"
            text="CANCEL"
            onClick={() => {
              onTradeFormClosed(false);
            }}
          />
          <Button
            variant="contained"
            className="w-full"
            onClick={() => {
              onCreateTrade();
            }}
          >
            SAVE
          </Button>
        </div>
      </div>
    </>
  );
};
export default TradeDetailsForm;
