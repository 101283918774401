import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../../../store";
import {
  updatePayoutDetails,
  storePayoutDetails,
  setSelectedPayout,
} from "../../../../reducers/tradeSlice";
import { TPayoutDetails, TPayoutFields } from "../../../../types";
import { toast } from "react-toastify";
import useAxiosClient from "../../../../hooks/useAxiosClient";
import useCustomerDocs from "../../../../hooks/useCustomerDocs";
import moment from "moment";

const usePayoutDetails = () => {
  const dispatch = useDispatch();

  const { handlePostCall, handlePutCall } = useAxiosClient();

  const [loading, setLoading] = useState(false);

  const { traderView } = useCustomerDocs({});

  const { addPayoutDetails, adminId, payoutDetails, selectedPayout } =
    useSelector((state: TRootState) => ({
      adminId: state.auth.adminId,
      addPayoutDetails: state.trades.addPayoutDetails,
      payoutDetails: state.trades.payoutDetails,
      selectedPayout: state.trades.selectedPayout,
    }));

  const onSelectPayout = (payout: TPayoutDetails) => {
    dispatch(setSelectedPayout(payout));
  };

  const setPayoutDetails = (value: string, type: TPayoutFields) => {
    let updatedPayoutDetails = addPayoutDetails;
    switch (type) {
      case TPayoutFields.accountHolder:
        updatedPayoutDetails = {
          ...updatedPayoutDetails,
          accountHolder: value,
        };
        break;
      case TPayoutFields.accountNumber:
        updatedPayoutDetails = {
          ...updatedPayoutDetails,
          accountNumber: value,
        };
        break;
      case TPayoutFields.bankName:
        updatedPayoutDetails = {
          ...updatedPayoutDetails,
          bankName: value,
        };
        break;
      case TPayoutFields.ifscCode:
        updatedPayoutDetails = {
          ...updatedPayoutDetails,
          ifscCode: value,
        };
        break;
      case TPayoutFields.status:
        updatedPayoutDetails = {
          ...updatedPayoutDetails,
          status: value,
        };
        break;
      case TPayoutFields.accountType:
        updatedPayoutDetails = {
          ...updatedPayoutDetails,
          accountType: value,
        };
        break;
      case TPayoutFields.contactNumber:
        updatedPayoutDetails = {
          ...updatedPayoutDetails,
          contactNumber: value,
        };
        break;
      case TPayoutFields.emailAddress:
        updatedPayoutDetails = {
          ...updatedPayoutDetails,
          emailAddress: value,
        };
        break;
      case TPayoutFields.note1:
        updatedPayoutDetails = {
          ...updatedPayoutDetails,
          note1: value,
        };
        break;
      case TPayoutFields.note2:
        updatedPayoutDetails = {
          ...updatedPayoutDetails,
          note2: value,
        };
        break;
      case TPayoutFields.type:
        updatedPayoutDetails = {
          ...updatedPayoutDetails,
          type: value,
        };
        break;
      default:
        break;
    }

    dispatch(
      updatePayoutDetails({
        addPayoutDetails: updatedPayoutDetails,
      })
    );
  };

  const onSubmit = (onClose: any) => {
    if (validateAddPayout()) {
      if (!addPayoutDetails.isApiCall) {
        let payAccs = [...payoutDetails];
        payAccs.push({
          id: 0,
          traderId: addPayoutDetails.traderId,
          actHolder: addPayoutDetails.accountHolder,
          actNum: addPayoutDetails.accountNumber,
          ifsc: addPayoutDetails.ifscCode,
          bank: addPayoutDetails.bankName,
          rzpFa: "",
          rzpCont: "",
          mobile: "",
          status: 1,
          createdOn: moment().format(),
          traderType: addPayoutDetails.traderType,
          contactNumber: addPayoutDetails.contactNumber,
          emailAddress: addPayoutDetails.emailAddress,
          note1: addPayoutDetails.note1,
          note2: addPayoutDetails.note2,
          actType: addPayoutDetails.accountType,
          email: "",
          type: addPayoutDetails?.type,
        });
        dispatch(storePayoutDetails(payAccs));
        onClose();
      } else {
        setLoading(true);
        let apiParams = {
          adminId: adminId,
          traderId: addPayoutDetails.traderId,
          traderType: addPayoutDetails.traderType,
          actHolder: addPayoutDetails.accountHolder,
          actNum: addPayoutDetails.accountNumber,
          bankName: addPayoutDetails.bankName,
          ifsc: addPayoutDetails.ifscCode,
          actType: addPayoutDetails.accountType,
          contactNum: addPayoutDetails.contactNumber,
          email: addPayoutDetails.emailAddress,
          notes: {
            notes_key_1: addPayoutDetails.note1,
            notes_key_2: addPayoutDetails.note2,
          },
          type: addPayoutDetails?.type,
        };
        handlePostCall({
          URL: "admin/payouts/traders/accounts",
          apiParams: apiParams,
        })
          .then(({ data, status }) => {
            toast.success(status.message);
            onClose();
          })
          .catch((err: any) => {
            setLoading(false);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const updatePayoutAccount = (
    id: number,
    status: number,
    onSubmit: () => void
  ) => {
    setLoading(true);
    let apiParams = {};
    handlePutCall({
      URL: `admin/trade-platform/traders/accounts/adminId/${adminId}/id/${id}?accStatus=${status}`,
      apiParams: apiParams,
    })
      .then(({ data, status }) => {
        toast.success(status.description);
        onSubmit();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateAddPayout = () => {
    let hasValidationErrors = true;
    if (!addPayoutDetails.accountHolder) {
      toast.error("Please enter account holder");
      hasValidationErrors = false;
    } else if (!addPayoutDetails.accountNumber) {
      toast.error("Please enter account number");
      hasValidationErrors = false;
    } else if (addPayoutDetails.accountNumber.length < 5) {
      toast.error("Account number should have min 5 characters");
      hasValidationErrors = false;
    } else if (addPayoutDetails.accountNumber.length > 35) {
      toast.error("Account number should have max 35 characters");
      hasValidationErrors = false;
    } else if (!addPayoutDetails.ifscCode) {
      toast.error("Please enter ifsc code");
      hasValidationErrors = false;
    }
    // else if (!addPayoutDetails.status) {
    //     toast.error('Please select status');
    //     hasValidationErrors = false;
    // }
    else if (!addPayoutDetails.accountType) {
      toast.error("Please enter account type");
      hasValidationErrors = false;
    } else if (!addPayoutDetails.contactNumber) {
      toast.error("Please enter contact number");
      hasValidationErrors = false;
    } else if (addPayoutDetails.contactNumber.length != 10) {
      toast.error("Please enter 10 digit contact number");
      hasValidationErrors = false;
    }
    // else if (!addPayoutDetails.emailAddress) {
    //     toast.error('Please enter email address');
    //     hasValidationErrors = false;
    // }
    return hasValidationErrors;
  };

  return {
    loading,
    addPayoutDetails,
    setPayoutDetails,
    onSubmit,
    updatePayoutAccount,
    selectedPayout,
    onSelectPayout,
  };
};

export default usePayoutDetails;
