import { useState } from "react";
import PartnerInfo from "../../../../components/partner-info";
import { Button } from "../../../../components/ui";
import Modal from "../../../../components/ui/modal";
import ModalHeader from "../../../../components/ui/modal/modal-header";
import AdvDisbursalModal from "../Advance-Disbursal/AdvDisbursalModal";
import { TEmployeInfo } from "../../../../types/auth";
import { TApplicationViewDetails } from "../../../../types";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../../../store";
import {
  setApproveComment,
  setDisburseComment,
  setReviewComment,
  setVerifyComment,
  setRejectComment,
  updateDisRecordPayoutData,
} from "../../../../reducers/loansSlice";
import RejectModal from "../Advance-Disbursal/RejectLoanModal";
import RejectLoanModal from "../Advance-Disbursal/RejectLoanModal";
import { setPayoutCurrentStep } from "../../../../reducers/tradeSlice";
import moment from "moment";

const LoanManagerInfo: React.FC<{
  info: TEmployeInfo;
  nextStepEvent: any;
  setApplicationDetails: any;
  applicationData: TApplicationViewDetails | null;
  activeStep?: number;
  rejectStep: any;
  activeTab?: string;
}> = ({
  info,
  nextStepEvent,
  setApplicationDetails,
  applicationData,
  activeStep,
  rejectStep,
  activeTab,
}) => {
    const [openDisbursal, setOpenDisbursal] = useState(false);
    const params = useParams();
    const dispatch = useDispatch();
    const [rejectModal, setRejectModal] = useState(false);
    const {
      reviewComments,
      verifyComments,
      approveComments,
      disburseComments,
      rejectComments,
      role,
      disRecordPayoutData,
    } = useSelector((state: TRootState) => ({
      reviewComments: state.loans.reviewedComment,
      verifyComments: state.loans.verifyComment,
      approveComments: state.loans.approveComment,
      disburseComments: state.loans.disburseComment,
      rejectComments: state.loans.rejectComment,
      role: state.auth.userInfo?.roleType,
      disRecordPayoutData: state.loans.disRecordPayoutData,
    }));

    const isDisableComment = (progStatus: string) => {
      let value = false;
      if (applicationData?.disbStatus === "REJECTED") {
        value = true;
      }
      let count = applicationData?.disbPrgs?.filter(
        (prog) => prog.progStatus == progStatus
      ).length!;
      if (count > 0) {
        value = true;
      }
      return value;
    };

    return (
      <>
        <div className="" style={{ width: "100%" }}>
          <div className="border br-10 mt-3 py-4 bg-white">
            <span className="text-sm px-4 mb-2 font-semibold">
              Loan Approver Info
            </span>
            <div className="border-b py-2"></div>
            <div
              className="grow-[2] basis-0 flex flex-col px-4 py-4"
              style={{ flexBasis: "4rem", width: "100%" }}
            >
              <PartnerInfo
                profilePic=""
                name={info?.name || ""}
                shortName={""}
                partnerType={"USER ID "}
                partnerId={info?.adminId}
                partnerMobile={info?.mobile || ""}
                partyName={""}
                fromLoansParty={true}
              />
            </div>
          </div>
          {
            (applicationData?.payoutRes == null || applicationData?.payoutRes?.status == 'FAILED' || applicationData?.payoutRes?.status == 'REJECTED') 
            &&
            <div className="bg-white">
              {applicationData?.disbStatus !== "DISBURSED" &&
                applicationData?.disbStatus !== "CLOSED" ? (
                <div className="br-10 mt-3 py-4">
                  <div className="pb-1">
                    <span className="text-sm px-4 font-semibold">
                      Review Comments <span className="color-red">*</span>
                    </span>
                  </div>
                  <div className="px-4">
                    {activeTab === "Loan Details" && (
                      <textarea
                        className="h-[100px] w-[100%] p-2 border border-gray br-10"
                        value={reviewComments}
                        onChange={(e) => {
                          dispatch(setReviewComment(e.target.value));
                        }}
                        disabled={isDisableComment("REVIEWED")}
                      />
                    )}
                    {activeTab === "Risk & Fraud Assessment" && (
                      <textarea
                        className="h-[100px] w-[100%] p-2 border border-gray br-10"
                        value={verifyComments}
                        onChange={(e) => {
                          dispatch(setVerifyComment(e.target.value));
                        }}
                        disabled={isDisableComment("VERIFIED")}
                      />
                    )}
                    {activeTab === "CASH History" && (
                      <textarea
                        className="h-[100px] w-[100%] p-2 border border-gray br-10"
                        value={approveComments}
                        onChange={(e) => {
                          dispatch(setApproveComment(e.target.value));
                        }}
                        disabled={isDisableComment("APPROVED")}
                      />
                    )}
                    {activeTab === "Final Review and Approvals" && (
                      <textarea
                        className="h-[100px] w-[100%] p-2 border border-gray br-10"
                        value={disburseComments}
                        onChange={(e) => {
                          dispatch(setDisburseComment(e.target.value));
                        }}
                        disabled={isDisableComment("DISBURSED")}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div className="br-10 mt-3 py-4 bg-white">
                  <span className="text-sm font-semibold px-4 mb-2">
                    Review Comments:
                  </span>
                  <p className="text-sm px-4 py-2">
                    {disburseComments ? disburseComments : "-"}
                  </p>
                </div>
              )}
              <div className="px-4 pb-4">
                {applicationData?.disbStatus !== "REJECTED" &&
                  applicationData?.disbStatus !== "DISBURSED" &&
                  applicationData?.disbStatus !== "CLOSED" &&
                  role === "ADMIN" && (
                    <div className="modal-header flex justify-between items-center mt-2">
                      <div>
                        <Button
                          text="Reject"
                          className="w-[100px]"
                          variant="lightRed"
                          onClick={() => {
                            let comment = "";
                            if (activeTab === "Loan Details") {
                              comment = reviewComments;
                            } else if (activeTab === "Risk & Fraud Assessment") {
                              comment = verifyComments;
                            } else if (activeTab === "CASH History") {
                              comment = approveComments;
                            } else if (activeTab === "Final Review and Approvals") {
                              comment = disburseComments;
                            }

                            if (!comment.trim()) {
                              toast.error("Please Enter Review Comments");
                              return;
                            }
                            setRejectModal(true);
                          }}
                          disabled={isDisableComment(
                            activeTab === "Loan Details"
                              ? "REVIEWED"
                              : activeTab === "Risk & Fraud Assessment"
                                ? "VERIFIED"
                                : activeTab === "CASH History"
                                  ? "APPROVED"
                                  : "DISBURSED"
                          )}
                        />
                      </div>
                      <div>
                        <Button
                          text={
                            applicationData?.disbStatus === "APPROVED" &&
                              activeStep === 4
                              ? "Disburse"
                              : "Next Step"
                          }
                          className="w-[200px]"
                          onClick={() => {
                            if (
                              applicationData?.disbStatus === "APPROVED" &&
                              activeStep === 4
                            ) {
                              if (!disburseComments) {
                                toast.error("Please enter Review Comments");
                                return;
                              }
                              setOpenDisbursal(true);
                            } else {
                              nextStepEvent();
                            }
                          }}
                          disabled={isDisableComment(
                            activeTab === "Loan Details"
                              ? "REVIEWED"
                              : activeTab === "Risk & Fraud Assessment"
                                ? "VERIFIED"
                                : activeTab === "CASH History"
                                  ? "APPROVED"
                                  : "DISBURSED"
                          )}
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
          }
        </div>
        <div>
          <Modal
            open={openDisbursal}
            onClose={() => {
              setOpenDisbursal(false);
              dispatch(setPayoutCurrentStep(1));
              dispatch(
                updateDisRecordPayoutData({
                  ...disRecordPayoutData,
                  tenure: "",
                  pmtMode: "",
                  disbDate: moment().format("YYYY-MM-DD"),
                  comments: "",
                })
              );
            }}
            width="50vw"
          >
            <>
              <ModalHeader title={"Advance Disbursal"} content={<></>} />
              <div>
                <AdvDisbursalModal
                  applicationData={applicationData}
                  disbModalClose={() => {
                    setOpenDisbursal(false);
                  }}
                />
              </div>
            </>
          </Modal>
        </div>

        <div>
          <Modal
            open={rejectModal}
            onClose={() => {
              setRejectModal(true);
            }}
            width="30vw"
          >
            <>
              <div className="px-8 py-8">
                <RejectLoanModal
                  onClose={() => {
                    setRejectModal(false);
                  }}
                />
              </div>
            </>
          </Modal>
        </div>
      </>
    );
  };
export default LoanManagerInfo;
