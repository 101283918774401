import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { TRootState } from "../../../../store";
import { setRecordPayoutData, updateRecordPayoutData } from "../../../../reducers/tradeSlice";


const usePayoutStep1 = () => {

    const dispatch = useDispatch();
    const {
        recordPayoutData,
        tradeDetail,
    } = useSelector((state: TRootState) => ({
        recordPayoutData: state.trades.recordPayoutData,
        tradeDetail: state.trades.tradeDetails,
    }));

    const setPaymentDate = (value: string) => {
        let date = moment(value,'YYYY-MMM-DD').format("YYYY-MM-DD");
        dispatch(
            updateRecordPayoutData({
                ...recordPayoutData,
                paymentDate: date,
            }),
        );
    }

    const setAmount = (value: string) => {
        dispatch(
            updateRecordPayoutData({
                ...recordPayoutData,
                paidAmount: parseFloat(value),
            }),
        );
    }

    const setAdvance = (value: string) => {
        dispatch(
            updateRecordPayoutData({
                ...recordPayoutData,
                paidAmount: parseFloat(value),
                requestAdv: parseFloat(value),
            }),
        );
    }

    const setPaymentType = (value: string) => {
        if (value == 'ADVANCE') {
            if (tradeDetail?.reqAdv != null) {
                let requestAdv = tradeDetail?.reqAdv?.requestAdv;
                if (recordPayoutData.paidAmount) {
                    requestAdv = recordPayoutData.paidAmount;
                }
                dispatch(
                    updateRecordPayoutData({
                        ...recordPayoutData,
                        paymentType: value,
                        requestAdv: requestAdv,
                        paidAmount: requestAdv,
                    }),
                );
            }else{
                dispatch(
                    updateRecordPayoutData({
                        ...recordPayoutData,
                        paymentType: value,
                    }),
                );
            }
        } else {
            dispatch(
                updateRecordPayoutData({
                    ...recordPayoutData,
                    paymentType: value,
                    paidAmount: recordPayoutData.paidAmount ? recordPayoutData.paidAmount : '',
                }),
            );
        }

    }

    const setPaymentMode = (value: string) => {
        dispatch(
            updateRecordPayoutData({
                ...recordPayoutData,
                paymentMode: value,
            }),
        );
    }

    const setComments = (value: string) => {
        dispatch(
            updateRecordPayoutData({
                ...recordPayoutData,
                comments: value,
            }),
        );
    }

    return {
        recordPayoutData,
        tradeDetail,
        setPaymentDate,
        setPaymentType,
        setPaymentMode,
        setComments,
        setAmount,
        setAdvance,
    };
}

export default usePayoutStep1;

function navigate(arg0: string) {
    throw new Error("Function not implemented.");
}
