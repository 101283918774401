import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import useAxiosClient from "../../../../../hooks/useAxiosClient";
import { TRootState } from "../../../../../store";
import useDisRecordPayout from "../useDisRecordPayout";
import { updateDisRecordPayoutData } from "../../../../../reducers/loansSlice";

const useDisPayoutStep2 = () => {
  const dispatch = useDispatch();
  const { handlePutCall } = useAxiosClient();
  const { adminId, disRecordPayoutData, applicationViewDetails } = useSelector(
    (state: TRootState) => ({
      adminId: state.auth.adminId,
      disRecordPayoutData: state.loans.disRecordPayoutData,
      applicationViewDetails: state.loans.applicationViewDetails,
    })
  );

  const { billCreationStatus, setBillCreationStatus } = useDisRecordPayout();

  const setOtp = (value: string) => {
    dispatch(
      updateDisRecordPayoutData({
        ...disRecordPayoutData,
        otp: value,
      })
    );
  };

  const onVerifyOtp = () => {
    if (disRecordPayoutData.otp.length != 6) {
      toast.error("Please enter 6 digit OTP");
      return;
    }
    setBillCreationStatus({ ...billCreationStatus, status: "LOADING" });
    let apiParams = {
      adminId: adminId,
      id: disRecordPayoutData.id,
      otp: disRecordPayoutData.otp,
    };
    handlePutCall({
      URL: "admin/trade-platform/trades/payments/validate",
      apiParams: apiParams,
    })
      .then(({ data, status }) => {
        let d = data as any;
        console.log(data);
        if (data) {
          dispatch(
            updateDisRecordPayoutData({
              ...disRecordPayoutData,
              refId: d.refId,
            })
          );
          toast.success(status.description);
        } else {
          toast.error(status.description);
        }
        setBillCreationStatus({ ...billCreationStatus, status: "COMPLETED" });
      })
      .catch(() => {
        setBillCreationStatus({ ...billCreationStatus, status: "" });
      });
  };

  return {
    disRecordPayoutData,
    applicationViewDetails,
    setOtp,
    onVerifyOtp,
  };
};

export default useDisPayoutStep2;
