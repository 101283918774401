import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAxiosClient from "../../hooks/useAxiosClient";
import { setLoggedinStatus } from "../../reducers/authSlice";
import { TEmployeInfo } from "../../types/auth";
let otpTimer: any = null;
const useLogin = () => {
  const dispatch = useDispatch();
  const { handlePostCall } = useAxiosClient();
  const [otpExpTime, setOtpExpTime] = useState(0);
  const [sentOtpBtnText, setSendOtpButtonText] = useState("GET OTP");
  const [mobileNumber, setMobileNumber] = useState("");
  const [otpNumber, setOtpNumber] = useState<string>("");
  const [step, setStep] = useState(0);
  const verifyOtp = async () => {
    if (mobileNumber === "") {
      toast.error("Please enter mobile number");
      return;
    }
    if (mobileNumber.length !== 10) {
      toast.error("Mobile number must be 10 digit");
      return;
    }
    if (otpNumber === "") {
      toast.error("Please enter OTP");
      return;
    }
    if (otpNumber.length !== 6) {
      toast.error("Please enter 6 digit OTP");
      return;
    }
  };
  const runOtpTimer = (timer: number) => {
    if (timer >= 0) {
      setOtpExpTime(timer);
      setTimeout(() => {
        runOtpTimer(timer - 1);
      }, 1000);
    } else {
      setSendOtpButtonText("RESEND OTP");
    }
  };
  const getOtp = async () => {
    if (mobileNumber === "") {
      toast.error("Please enter mobile number");
      return;
    }
    if (mobileNumber.length !== 10) {
      toast.error("Mobile number must be 10 digit");
      return;
    }
    let toastid = toast.loading(`Sending OTP...`, { type: "info" });
    let postBody = {
      mobile: mobileNumber,
    };
    let { data, status } = await handlePostCall<{
      otpReqId: string;
      traderId: number;
    }>({
      URL: "account/mandi/portal/login",
      apiParams: postBody,
    });
    console.log(status, "status");
    if (status.code === "200") {
      toast.update(toastid, {
        type: "success",
        render: status.description,
        isLoading: false,
        autoClose: 3000,
      });
      setOtpExpTime(60);
      if (otpTimer !== null) {
        clearTimeout(otpTimer);
      }
      otpTimer = setTimeout(() => {
        runOtpTimer(59);
      }, 1000);
    }
  };
  const onLogin = (username: string, password: string) => {
    if (!username) {
      toast.error("Please enter your email id");
      return;
    }
    if (!password) {
      toast.error("please enter password");
      return;
    }
    handlePostCall<TEmployeInfo>({
      URL: "admin/trade-platform/trades/login",
      apiParams: {
        password: password,
        userId: username,
      },
    }).then(({ data, status }) => {
      dispatch(setLoggedinStatus({ loggedin: true, userInfo: data }));
      localStorage.setItem("clientId", data.clientId);
      localStorage.setItem("clientSecret", data.clientSecret);
    });
  };
  return {
    sentOtpBtnText,
    step,
    setStep,
    verifyOtp,
    setMobileNumber,
    getOtp,
    setOtpNumber,
    otpExpTime,
    onLogin,
  };
};
export default useLogin;
