import { FC } from "react";

import { Button } from "../../../../components/ui";
import Steps from "../../../../components/steps";
import DisPayoutStep1 from "./Disbursal-step1";
import DisPayoutStep2 from "./Disbursal-step2";
import { TApplicationViewDetails, TBillsInfo } from "../../../../types";
import useDisRecordPayout from "./useDisRecordPayout";
import { setPayoutCurrentStep } from "../../../../reducers/tradeSlice";
import { useDispatch } from "react-redux";

const RecordDisbursalPayout: FC<{
  applicationData: TApplicationViewDetails | null;
  // tradeId: number;
  disbModalClose: any;
  selectedBills: TBillsInfo[];
  setStep: any;
  inputValue: any;
}> = ({
  applicationData,
  disbModalClose,
  selectedBills,
  setStep,
  inputValue,
}) => {
  const {
    currentStep,
    onPrevious,
    onNext,
    billCreationStatus,
    disRecordPayoutData,
  } = useDisRecordPayout();
  const dispatch = useDispatch();
  return (
    <div>
      <div className="flex justify-center pr-8 mt-2">
        <Steps currentStep={currentStep} stepCount={2} />
      </div>
      <div
        style={{
          height: "calc(100vh - 20rem)",
          overflowY: "auto",
          width: "100%",
        }}
        id="scroll_style"
      >
        {currentStep == 1 && (
          <DisPayoutStep1
            applicationData={applicationData}
            disbModalClose={disbModalClose}
            selectedBills={selectedBills}
            setStep={setStep}
            inputValue={inputValue}
            disRecordPayoutData={disRecordPayoutData}
          />
        )}
        {currentStep == 2 && <DisPayoutStep2 />}
      </div>
      <div className="bg-white flex justify-between w-full px-6 py-4 ">
        {currentStep == 2 ? (
          <Button variant="outlined" onClick={onPrevious}>
            &nbsp;PREVIOUS
          </Button>
        ) : (
          <Button
            variant="outlined"
            onClick={() => {
              disbModalClose(false);
              dispatch(setPayoutCurrentStep(1));
            }}
          >
            CANCEL
          </Button>
        )}
        <Button
          onClick={() => {
            onNext(disbModalClose);
          }}
          disabled={
            billCreationStatus.status == "LOADING" ||
            (currentStep == 2 && disRecordPayoutData.refId.length == 0)
              ? true
              : false
          }
        >
          {currentStep < 2 ? "NEXT" : "SUBMIT"}
        </Button>
      </div>
    </div>
  );
};

export default RecordDisbursalPayout;
