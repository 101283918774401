import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TBeneficiaryDetails, TBorrowerDetails } from "../types";

type TinitialState = {
  borrowerDetails: TBorrowerDetails | null;
  beneficiaryDetails: TBeneficiaryDetails | null;
  activeTab: string;
  platformType: string
};
const initialState: TinitialState = {
  borrowerDetails: null,
  beneficiaryDetails: null,
  activeTab: "All",
  platformType: ''
};
const tradeSlice = createSlice({
  name: "borrowers",
  initialState: initialState,
  reducers: {
    updateBorrowerDetails: (
      state,
      action: PayloadAction<{
        borrowerDetails: TBorrowerDetails | null;
      }>
    ) => {
      state.borrowerDetails = action.payload.borrowerDetails;
    },
    updateBeneficiaryDetails: (
      state,
      action: PayloadAction<{
        beneficiaryDetails: TBeneficiaryDetails | null;
      }>
    ) => {
      state.beneficiaryDetails = action.payload.beneficiaryDetails;
    },

    UpdateActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
    },

    UpdateplatformType: (state, action: PayloadAction<string>) => {
      state.platformType = action.payload;
    },
  },
});

export const {
  updateBorrowerDetails,
  updateBeneficiaryDetails,
  UpdateActiveTab,
  UpdateplatformType
} = tradeSlice.actions;
export default tradeSlice.reducer;
