import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Button, Card, FileUploader } from "../../components/ui";
import DocumentPreview from "../../components/commons/document-preview";
import { FaXmark } from "react-icons/fa6";
import { toast } from "react-toastify";
import { TRootState } from "../../store";
import useAxiosClient from "../../hooks/useAxiosClient";
import useArrivalsView from "./useArrivalsView";
import PattiDocs from "./patti-docs";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

const ArrivalDocs: FC<{ type: string }> = ({ type }) => {
  const params = useParams();
  const { handlePutCall } = useAxiosClient();
  const navigate = useNavigate();
  const {
    onUploadReceiptImage,
    arrivalDocuments,
    onRemoveReceiptImage,
    alltradeDetail,
    arrivalWeightDocuments,
    setOtherDocuments,
    setArrivalWeightDocuments,
    role,
  } = useArrivalsView({
    traderId: parseInt(params.traderId || "0"),
    tradeId: parseInt(params.tradeId || "0"),
    mode: false,
    type: "",
  });
  const [removeImageStatus, setRemoveImageStatus] = useState(false);
  const { adminId } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
  }));
  useEffect(() => {
    if (arrivalDocuments.length > 0) {
      setTimeout(() => {
        // confrirmArrival();
      }, 1000);
    }
  }, [arrivalDocuments]);
  console.log(removeImageStatus, "jkkk");
  useEffect(() => {
    console.log(removeImageStatus, "removeImageStatus");
    if (removeImageStatus) {
      setTimeout(() => {
        // confrirmArrival();
      }, 1000);
    }
  }, [removeImageStatus]);
  const confrirmArrival = async () => {
    try {
      let imgs: Array<{
        id: number;
        imgFrom: string;
        reqId: number;
        status: number;
        addedBy: number;
        type:
          | "tapal"
          | "receipt"
          | "other"
          | "arrival"
          | "patti"
          | "payment"
          | "arrivalWight";
        url: string;
      }> = [];
      let arrivalImages = imgs;
      // if (removeImageStatus) {
      if (alltradeDetail != null) {
        if (alltradeDetail?.arrivalDocs.length > 0) {
          for (let file of alltradeDetail?.arrivalDocs) {
            if (file.status == 0) {
              arrivalImages.push({
                ...file,
                addedBy: adminId,
              });
            }
          }
        }
      }
      // }
      if (arrivalDocuments.length > 0) {
        for (let file of arrivalDocuments) {
          arrivalImages.push({
            id: 0,
            imgFrom: "TP_TRD",
            reqId: alltradeDetail?.tradeId || 0,
            status: 1,
            addedBy: adminId || 0,
            type: "arrival",
            url: file.url,
          });
        }
      }

      if (alltradeDetail != null) {
        if (alltradeDetail?.arrivalWeightDocs.length > 0) {
          for (let file of alltradeDetail?.arrivalWeightDocs) {
            if (file.status == 0) {
              arrivalImages.push({
                ...file,
                addedBy: adminId,
              });
            }
          }
        }
      }
      if (arrivalWeightDocuments.length > 0) {
        for (let file of arrivalWeightDocuments) {
          arrivalImages.push({
            id: 0,
            imgFrom: "TP_TRD",
            reqId: alltradeDetail?.tradeId || 0,
            status: 1,
            addedBy: adminId || 0,
            type: "arrivalWight",
            url: file.url,
          });
        }
      }

      let postBody = {};
      if (type == "ARRIVAL") {
        postBody = {
          adminId: adminId,
          arlConf: {
            arlDate: alltradeDetail?.arlDate,
            freightPaid: alltradeDetail?.freightPaid,
            images: arrivalImages,
            arlTime: alltradeDetail?.arlTime,
            comments: alltradeDetail?.arlComments || "UPDATED",
            addWastage: alltradeDetail?.lineItems[0].addWastage,
            destWeight: alltradeDetail?.lineItems[0].destWeight,
            // arlTime:18:1:50
            tradeId: alltradeDetail?.tradeId,
            destTradeStatus: alltradeDetail?.tradeStatus,
            destRate: alltradeDetail?.lineItems[0].destRate,
            invAmt: alltradeDetail?.invAmt,
            totalPayable: alltradeDetail?.totalPayable,
            totalReceivable: alltradeDetail?.totalReceivable,
            destVehNum: alltradeDetail?.destVehNum,
            cmdityCost:
              alltradeDetail?.tradeMode == "ORDER"
                ? alltradeDetail?.cmdityCost
                : "",
            finalPayable: alltradeDetail?.finalPayable,
            finalReceivable: alltradeDetail?.finalReceivable,
          },
          tradeId: alltradeDetail?.tradeId,
          tradeMode: alltradeDetail?.tradeMode,
        };
      }

      //   let statusVal = searchParams.get("tab") === "CLOSED" ? "CLOSED" : "OPEN";
      await handlePutCall<any>({
        URL: "admin/trade-platform/trades/metadata",
        apiParams: postBody,
      })
        .then(({ data, status }) => {
          toast.success(status.message);
          navigate(
            `/trade-detail/${alltradeDetail?.srcPartyId}/${alltradeDetail?.tradeId}?tab=OPEN`
          );
          setRemoveImageStatus(false);
          setOtherDocuments([]);
          setArrivalWeightDocuments([]);
          // setEditDetail({ section: "", mode: "view" });
          // getTradeDetail();
          //   navigate(
          //     `/trade-detail/${alltradeDetail?.srcPartyId}/${alltradeDetail?.tradeId}?tab=${statusVal}`
          //   );
        });
    } catch (ex) {}
  };

  const [onEdit, setOnEdit] = useState(false);
  return (
    <>
      {alltradeDetail != null && type == "ARRIVAL" && (
        <Card
          titile={
            <div className="flex items-center">
              <span>Arrival Document</span>
              {!onEdit && role == "ADMIN" && (
                <Button
                  variant="outlined"
                  className="ml-auto gap-1"
                  size="small"
                  onClick={() => {
                    setOnEdit(true);
                  }}
                >
                  <FaEdit className="" />
                  Edit
                </Button>
              )}

              {onEdit && role == "ADMIN" && (
                <Button
                  variant="outlined"
                  className="ml-auto gap-1"
                  size="small"
                  onClick={() => {
                    setOnEdit(false);
                    confrirmArrival();
                  }}
                >
                  <FaEdit className="" />
                  Save
                </Button>
              )}
            </div>
          }
          className="px-2"
        >
          <div className="px-2 mt-2">
            <h2 className="color-text-primary px-0 md:px-4 pb-2 pt-2 text-sm">
              Freight Payment Receipt / Truck Image :
            </h2>
            <div className="flex flex-wrap gap-2 border-b overflow-auto pb-4">
              {/* {editDetail.section === "arival_document" &&
                                    editDetail.mode === "edit" && ( */}
              <div className="shrink-0 overflow-hidden p-2">
                {alltradeDetail?.tradeStatus !== "CLOSED" &&
                  role == "ADMIN" && (
                    <FileUploader
                      label=""
                      onChange={(e) => {
                        onUploadReceiptImage(
                          "arrival",
                          e.target.files ? e.target.files[0] : null
                        );
                        setOnEdit(true);
                      }}
                    />
                  )}
              </div>
              {/* )} */}
              {arrivalDocuments.length > 0 &&
                arrivalDocuments.map((image, i) => (
                  <div
                    className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                    style={{
                      width: "33.333%",
                      height: "10rem",
                    }}
                    key={`arival-doc-${i}`}
                  >
                    <a href={image.url} target="_blank">
                      <DocumentPreview
                        url={image.url}
                        docType={image.docType}
                      />
                    </a>
                    {/* {editDetail.section === "trade_detail_document" &&
                      editDetail.mode === "edit" && ( */}
                    {onEdit && (
                      <span
                        className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                        style={{
                          background: "#ff0000de",
                        }}
                        onClick={() => {
                          onRemoveReceiptImage("arrival", i);
                        }}
                      >
                        <FaXmark style={{ color: "white" }} />
                      </span>
                    )}

                    {/* )} */}
                  </div>
                ))}
              {alltradeDetail.arrivalDocs.length > 0 &&
                alltradeDetail.arrivalDocs
                  .filter((img) => img.status == 1)
                  .map((image, i) => (
                    <div
                      className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                      style={{
                        width: "33.333%",
                        height: "10rem",
                      }}
                      key={`receipt-doc-${i}`}
                    >
                      <a href={image.url} target="_blank">
                        <DocumentPreview
                          url={image.url}
                          docType={image.docType}
                        />
                      </a>
                      {onEdit && (
                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{
                            background: "#ff0000de",
                          }}
                          onClick={() => {
                            onRemoveReceiptImage("arrival", image.id, true);
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      )}
                    </div>
                  ))}

              {alltradeDetail.arrivalDocs.length <= 0 &&
                arrivalDocuments.length <= 0 && (
                  <div
                    className="border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                    style={{
                      width: "33.333%",
                      height: "10rem",
                    }}
                  >
                    No Arrival Documents Uploaded
                  </div>
                )}
            </div>
          </div>

          <div className="px-2 mt-2">
            <h2 className="color-text-primary px-0 md:px-4 pb-2 pt-2 text-sm">
              Upload Weighbridge Receipt :
            </h2>
            <div className="flex flex-wrap gap-2 border-b overflow-auto pb-4">
              {/* {editDetail.section === "arival_document" &&
                                    editDetail.mode === "edit" && ( */}
              <div className="shrink-0 overflow-hidden p-2">
                {alltradeDetail?.tradeStatus !== "CLOSED" &&
                  role == "ADMIN" && (
                    <FileUploader
                      label=""
                      onChange={(e) => {
                        onUploadReceiptImage(
                          "arrivalWight",
                          e.target.files ? e.target.files[0] : null
                        );
                        setOnEdit(true);
                      }}
                    />
                  )}
              </div>
              {/* )} */}
              {arrivalWeightDocuments.length > 0 &&
                arrivalWeightDocuments.map((image, i) => (
                  <div
                    className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                    style={{
                      width: "33.333%",
                      height: "10rem",
                    }}
                    key={`arrivalWight-doc-${i}`}
                  >
                    <a href={image.url} target="_blank">
                      <DocumentPreview
                        url={image.url}
                        docType={image.docType}
                      />
                    </a>
                    {onEdit && (
                      <span
                        className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                        style={{
                          background: "#ff0000de",
                        }}
                        onClick={() => {
                          onRemoveReceiptImage("arrivalWight", i);
                        }}
                      >
                        <FaXmark style={{ color: "white" }} />
                      </span>
                    )}
                  </div>
                ))}
              {alltradeDetail.arrivalWeightDocs.length > 0 &&
                alltradeDetail.arrivalWeightDocs
                  .filter((img) => img.status == 1)
                  .map((image, i) => (
                    <div
                      className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                      style={{
                        width: "33.333%",
                        height: "10rem",
                      }}
                      key={`arrivalWight-doc-${i}`}
                    >
                      <a href={image.url} target="_blank">
                        <DocumentPreview
                          url={image.url}
                          docType={image.docType}
                        />
                      </a>
                      {onEdit && (
                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{
                            background: "#ff0000de",
                          }}
                          onClick={() => {
                            onRemoveReceiptImage(
                              "arrivalWight",
                              image.id,
                              true
                            );
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      )}
                    </div>
                  ))}

              {alltradeDetail.arrivalWeightDocs.length <= 0 &&
                arrivalWeightDocuments.length <= 0 && (
                  <div
                    className="border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                    style={{
                      width: "33.333%",
                      height: "10rem",
                    }}
                  >
                    No WeighBridge receipts Uploaded
                  </div>
                )}
            </div>
          </div>
        </Card>
      )}
      {/* {alltradeDetail != null && type == "PATTI" && <PattiDocs />} */}
      {/* <button
        onClick={() => {
          confrirmArrival();
        }}
      >
        save patti
      </button> */}
    </>
  );
};
export default ArrivalDocs;
