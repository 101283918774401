import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { TRootState } from "../../../../store";
import { updateRecordPayoutData } from "../../../../reducers/tradeSlice";
import useRecordPayout from "../useRecordPayout";
import useAxiosClient from "../../../../hooks/useAxiosClient";


const usePayoutStep2 = () => {

    const dispatch = useDispatch();
    const { handlePutCall } = useAxiosClient();
    const {
        adminId,
        recordPayoutData,
        tradeDetail,
    } = useSelector((state: TRootState) => ({
        adminId: state.auth.adminId,
        recordPayoutData: state.trades.recordPayoutData,
        tradeDetail: state.trades.tradeDetails,
    }));

    const {
        billCreationStatus,
        setBillCreationStatus
    } = useRecordPayout();

    const setOtp = (value: string) => {
        dispatch(
            updateRecordPayoutData({
                ...recordPayoutData,
                otp: value,
            })
        )
    }

    const onVerifyOtp = () => {
        if (recordPayoutData.otp.length != 6) {
            toast.error('Please enter 6 digit OTP');
            return;
        }
        setBillCreationStatus({ ...billCreationStatus, status: "LOADING" });
        let apiParams = {
            "adminId": adminId,
            "id": recordPayoutData.id,
            "otp": recordPayoutData.otp,
        };
        handlePutCall({
            URL: 'admin/trade-platform/trades/payments/validate',
            apiParams: apiParams,
        }).then(({ data, status }) => {
            let d = data as any;
            if (data) {
                dispatch(
                    updateRecordPayoutData({
                        ...recordPayoutData,
                        refId: d.refId,
                    }),
                );
                toast.success(status.description);
            } else {
                toast.error(status.description);
            }
            setBillCreationStatus({ ...billCreationStatus, status: "COMPLETED" });
        }).catch(() => {
            setBillCreationStatus({ ...billCreationStatus, status: "" });
        });
    }

    return {
        recordPayoutData,
        tradeDetail,
        setOtp,
        onVerifyOtp,
    };
}

export default usePayoutStep2;

