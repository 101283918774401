import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useAxiosClient from "../../../../hooks/useAxiosClient";
import { TRootState } from "../../../../store";
import {
  TApplicationViewDetails,
  TBillsInfo,
  TLoanKycDoc,
  TTraderKycDoc,
} from "../../../../types";
import {
  setApproveComment,
  setDisburseComment,
  setRejectComment,
  setReviewComment,
  setVerifyComment,
  updateApplicationDetails,
  updateDisRecordPayoutData,
  updateLoanSuccess,
} from "../../../../reducers/loansSlice";
import moment from "moment";
import Compressor from "compressorjs";

const useApplicationViewById = ({
  applicationId,
  disbModalClose,
}: {
  applicationId?: number;
  disbModalClose?: any;
}) => {
  const { handleGetCall, handlePostCall } = useAxiosClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loanModal, setLoanModal] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [PaymentDoc, setPaymentDoc] = useState<
    Array<{ docType: "image" | "pdf"; url: string; type: string }>
  >([]);
  const {
    adminId,
    role,
    InitApplicationDetail,
    adminInfo,
    disRecordPayoutData,
    loanModalSuccessStatus,
  } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
    role: state.auth.userInfo?.roleType,
    InitApplicationDetail: state.loans.applicationViewDetails,
    adminInfo: state.auth.userInfo,
    disRecordPayoutData: state.loans.disRecordPayoutData,
    loanModalSuccessStatus: state.loans.loanModalSuccessStatus,
  }));

  const {
    reviewComments,
    verifyComments,
    approveComments,
    disburseComments,
    rejectComments,
  } = useSelector((state: TRootState) => ({
    reviewComments: state.loans.reviewedComment,
    verifyComments: state.loans.verifyComment,
    approveComments: state.loans.approveComment,
    disburseComments: state.loans.disburseComment,
    rejectComments: state.loans.rejectComment,
  }));

  const [applicationViewDetails, setApplicationViewDetails] =
    useState<TApplicationViewDetails | null>(InitApplicationDetail);

  const [recordDisbursalData, setRecordDisbursalData] = useState<{
    disbAmt: number | "";
    disbDate: string | "";
    pmtMode: string | number;
    comment: string;
    tenure: number | "";
    utr: string | "";
  }>({
    disbAmt: applicationViewDetails?.tradeAdv || 0,
    disbDate: moment().format("YYYY-MM-DD"),
    pmtMode: "",
    comment: "",
    tenure:
      applicationViewDetails?.loanType == "TRADE_ADV"
        ? applicationViewDetails.tenure
        : "",
    utr: "",
  });
  const [calenderOpen, setCalenderOpen] = useState(false);
  const handleDateSelection = (_date: string) => {
    let date = moment(_date).format("YYYY-MM-DD");
    setCalenderOpen(false);
    setRecordDisbursalData({
      ...recordDisbursalData,
      disbDate: date,
    });
    dispatch(
      updateDisRecordPayoutData({
        ...disRecordPayoutData,
        disbDate: date,
      })
    );
  };
  useEffect(() => {
    dispatch(
      updateDisRecordPayoutData({
        ...disRecordPayoutData,
        disbDate: applicationViewDetails?.disbDate,
        disbAmt: applicationViewDetails?.tradeAdv,
      })
    );
  }, [applicationViewDetails]);
  const getApplicationDetail = () => {
    handleGetCall<TApplicationViewDetails>(
      `admin/cash/smart-board/disbursements/adminId/${adminId}/disbId/${applicationId}`
    ).then(({ data }) => {
      if (data) {
        let invoices: TLoanKycDoc[] = [];
        if (data.kycDocs != null && data.kycDocs.length > 0) {
          for (let image of data.kycDocs) {
            let type: "pdf" | "image" = image.docUrl?.includes(".pdf")
              ? "pdf"
              : "image";
            invoices.push({ ...image, type: type });
          }
        }
        let partyScore = data.partyScore;
        if (data.loanType == 'WORKING_CAPITAL') {
          partyScore = data.bwrScore;
        }
        setApplicationViewDetails({
          ...data,
          kycDocs: invoices,
          payoutRes: data.payoutRes || null,
          partyScore: partyScore,
        });
        dispatch(
          updateApplicationDetails({
            applicationViewDetails: {
              ...data,
              partyScore: partyScore,
              remarks: "",
              payoutRes: data.payoutRes || null,
            },
          })
        );
        var reviewed = data?.disbPrgs?.filter(
          (i) => i.progStatus === "REVIEWED"
        );
        if (reviewed!?.length > 0) {
          dispatch(setReviewComment(reviewed![0].remarks));
          setActiveTab("Risk & Fraud Assessment");
        }

        var verified = data?.disbPrgs?.filter(
          (i) => i.progStatus === "VERIFIED"
        );
        if (verified!?.length > 0) {
          dispatch(setVerifyComment(verified![0].remarks));
          setActiveTab("CASH History");
        }

        var approved = data?.disbPrgs?.filter(
          (i) => i.progStatus === "APPROVED"
        );
        if (approved!?.length > 0) {
          dispatch(setApproveComment(approved![0].remarks));
          setActiveTab("Final Review and Approvals");
        }
        var disbursed = data?.disbPrgs?.filter(
          (i) => i.progStatus === "DISBURSED"
        );
        if (disbursed!?.length > 0) {
          dispatch(setDisburseComment(disbursed![0].remarks));
        }
        setRejectStep(data);
      } else {
        setApplicationViewDetails(null);
      }
    });
  };

  const setRejectStep = (data: TApplicationViewDetails) => {
    if (data?.disbStatus == "REJECTED") {
      var progress = data?.disbPrgs[data?.disbPrgs.length - 2];
      var rejected = data?.disbPrgs?.filter((i) => i.progStatus === "REJECTED");

      switch (progress?.progStatus) {
        case "PENDING":
          setActiveStep(1);
          dispatch(setReviewComment(rejected![0].remarks));
          break;
        case "REVIEWED":
          setActiveStep(2);
          dispatch(setVerifyComment(rejected![0].remarks));
          break;
        case "VERIFIED":
          setActiveStep(3);
          dispatch(setApproveComment(rejected![0].remarks));
          break;
        case "APPROVED":
          setActiveStep(4);
          dispatch(setDisburseComment(rejected![0].remarks));
          break;
      }
    }
  };

  const onUploadReceiptImage = async (
    type: "PAYMENT_RECEIPT",
    files: FileList | File[] // Accept multiple files
  ) => {
    const uploadPromises = Array.from(files).map(async (file) => {
      let fileType = "";
      if (file.type === "application/pdf") {
        fileType = "pdf";
      } else if (file.type.includes("image/")) {
        fileType = "image";
      }

      let formdata = new FormData();
      formdata.append("name", type);
      formdata.append("category", "PERSONAL");
      formdata.append("tpId", `${adminId}`);

      if (fileType === "pdf") {
        formdata.append("file", file, file.name.replace(" ", "-"));
        let imageUploadResponse = await handlePostCall<string[]>({
          URL: "/mandi/files/kyc-doc/upload",
          apiParams: formdata,
          config: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        });
        let url = imageUploadResponse.data[0];
        setPaymentDoc((prevDocs) => [
          { docType: "pdf", url: url, type: type },
          ...prevDocs,
        ]);
      } else {
        new Compressor(file, {
          quality: 0.8,
          success: async (compressedResult) => {
            formdata.append(
              "file",
              compressedResult,
              file.name.replace(" ", "-")
            );
            let imageUploadResponse = await handlePostCall<string[]>({
              URL: "/mandi/files/kyc-doc/upload",
              apiParams: formdata,
              config: {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              },
            });
            let url = imageUploadResponse.data[0];
            setPaymentDoc((prevDocs) => [
              { docType: "image", url: url, type: type },
              ...prevDocs,
            ]);
          },
        });
      }
    });

    // Await all upload promises for multiple files
    await Promise.all(uploadPromises);
  };

  // pdf / image remove cheyadaniki
  const onRemoveReceiptImage = (
    image: string,
    isExist: boolean = false,
    index: number = 0
  ) => {
    if (isExist === true && applicationViewDetails?.kycDocs !== null) {
      let invoices = [...applicationViewDetails?.kycDocs!];
      let i = invoices.findIndex((img) => img.id === index);
      invoices[i] = { ...invoices[i], status: 0 };
      setApplicationViewDetails({
        ...applicationViewDetails!,
        kycDocs: invoices,
      });
    } else {
      let newtapalInvoice = [...PaymentDoc];
      let index = newtapalInvoice.findIndex((img) => img.url === image);
      newtapalInvoice.splice(index, 1);
      setPaymentDoc(newtapalInvoice);
    }
  };

  //selected bills
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedBills, setSelectedBills] = useState<TBillsInfo[]>([]);

  const [activeStep, setActiveStep] = useState(1);

  const goToNextStepEvent = () => {
    var activeStatus = "";
    var remarks = "";
    if (activeStep == 1) {
      if (!reviewComments) {
        toast.error("Please enter Review Comments");
        return;
      }
      remarks = reviewComments;
      activeStatus = "REVIEWED";
      setActiveTab("Risk & Fraud Assessment");
    } else if (activeStep == 2) {
      if (!verifyComments) {
        toast.error("Please enter Review Comments");
        return;
      }
      remarks = verifyComments;
      activeStatus = "VERIFIED";
      setActiveTab("CASH History");
    } else if (activeStep == 3) {
      if (!approveComments) {
        toast.error("Please enter Review Comments");
        return;
      }
      remarks = approveComments;
      activeStatus = "APPROVED";
      setActiveTab("Final Review and Approvals");
    }
    handlePostCall({
      URL: `admin/cash/smart-board/disbursements`,
      apiParams: {
        remarks: remarks,
        disbDate: applicationViewDetails?.disbDate,
        disbId: applicationViewDetails?.disbId,
        progStatus: activeStatus,
        adminId: adminId,
        cpId: applicationViewDetails?.cpId,
      },
    })
      .then(({ data, status }) => {
        toast.success("Disbursement Request Updated");
        getApplicationDetail();
        setApplicationViewDetails((prevDetails) => ({
          ...prevDetails!,
          remarks: "",
        }));
        navigate(`/application-view/${applicationViewDetails?.disbId}`);
        setActiveStep(activeStep + 1);
      });
  };

  const rejectStepEvent = () => {
    handlePostCall({
      URL: `admin/cash/smart-board/disbursements`,
      apiParams: {
        remarks: rejectComments,
        disbDate: applicationViewDetails?.disbDate,
        disbId: applicationViewDetails?.disbId,
        progStatus: "REJECTED",
        adminId: adminId,
        cpId: applicationViewDetails?.cpId,
        pfId: applicationViewDetails?.pfId,
      },
    })
      .then(({ data, status }) => {
        toast.success("Disbursement Request Rejected");
        getApplicationDetail();
        window.location.reload();
        setApplicationViewDetails((prevDetails) => ({
          ...prevDetails!,
          remarks: "",
        }));
      })
      .catch(() => { });
  };
  useEffect(() => {
    setSelectedBills(selectedBills);
  }, [selectedBills]);
  const [loader, setLoader] = useState(false);
  const submitDisbursement = () => {
    const images = [];
    for (let file of PaymentDoc) {
      images.push({
        id: 0,
        reqId: 0,
        addedBy: adminId || 0,
        type: file.type,
        url: file.url,
        category: "PERSONAL",
        platform: "CASH",
        reviewStatus: "",
      });
    }
    if (
      applicationViewDetails?.loanType == 'TRADE_ADV' &&
      (!disRecordPayoutData?.selectedBills ||
        disRecordPayoutData?.selectedBills.length === 0)
    ) {
      toast.error("Please select at least one bill");
      return;
    }

    // const { disbAmt, totalBillAmt, eligibleLimit } = applicationViewDetails || {};

    // if (!disbAmt || !totalBillAmt || !eligibleLimit) {
    //   toast.error("Missing necessary data.");
    //   return;
    // }

    // if (disbAmt > totalBillAmt) {
    //   toast.error("Disburse amount is always less than total bill amount.");
    //   return;
    // }

    // if (disbAmt > eligibleLimit) {
    //   toast.error("Disburse amount is always less than cash limit.");
    //   return;
    // }

    if (!recordDisbursalData?.disbAmt) {
      toast.error("Please enter disbursal amount");
      return;
    }

    if (
      applicationViewDetails?.loanType == "WORKING_CAPITAL" &&
      !recordDisbursalData.tenure
    ) {
      toast.error("Please select tenure");
      return;
    }

    if (!recordDisbursalData?.pmtMode) {
      toast.error("Please select a payment mode");
      return;
    }

    if (images.length === 0) {
      toast.error("Please upload payment receipt");
      return;
    }

    setLoader(true);
    handlePostCall({
      URL: `admin/cash/smart-board/disbursements`,
      apiParams: {
        remarks: disburseComments,
        disbDate: recordDisbursalData?.disbDate,
        disbId: applicationViewDetails?.disbId,
        disbAmt: recordDisbursalData?.disbAmt,
        progStatus: "DISBURSED",
        adminId: adminId,
        cpId: applicationViewDetails?.cpId,
        comment: recordDisbursalData?.comment,
        imgs: images,
        pmtMode: recordDisbursalData?.pmtMode,
        farmerId: applicationViewDetails?.partyId,
        intrestRate: applicationViewDetails?.interestRate,
        tenure: recordDisbursalData?.tenure,
        pfId: applicationViewDetails?.pfId,
        billIds: disRecordPayoutData?.selectedBills?.map((i) => i.billId),
        utr: recordDisbursalData?.utr || "",
      },
    })
      .then(({ data, status }) => {
        // toast.success("Loan Disbursed");
        if (data) {
          toast.success(status?.description);
        } else {
          toast.success("Loan Disbursed");
        }
        disbModalClose(true);
        getApplicationDetail();
        setLoanModal(true);
        dispatch(updateLoanSuccess(true));
        setTimeout(() => {
          navigate(`/application-view/${applicationViewDetails?.disbId}`);
          dispatch(updateLoanSuccess(false));
        }, 2000);
      })
      .finally(() => {
        setTimeout(() => {
          setLoader(false);
        }, 3000);
      });
  };

  const closeLoan = () => {
    handlePostCall({
      URL: `admin/cash/smart-board/disbursements`,
      apiParams: {
        remarks: applicationViewDetails?.remarks,
        disbDate: applicationViewDetails?.disbDate,
        disbId: applicationViewDetails?.disbId,
        progStatus: "CLOSED",
        adminId: adminId,
        cpId: applicationViewDetails?.cpId,
        pfId: applicationViewDetails?.pfId,
      },
    })
      .then(({ data, status }) => {
        toast.success("Loan Is Closed");
        getApplicationDetail();
        navigate("/loans");
      });
  };

  return {
    applicationViewDetails,
    getApplicationDetail,
    role,
    goToNextStepEvent,
    adminInfo,
    setApplicationViewDetails,
    activeStep,
    setActiveStep,
    recordDisbursalData,
    setRecordDisbursalData,
    calenderOpen,
    setCalenderOpen,
    handleDateSelection,
    submitDisbursement,
    onUploadReceiptImage,
    onRemoveReceiptImage,
    PaymentDoc,
    inputValue,
    setInputValue,
    rejectStepEvent,
    loanModal,
    setLoanModal,
    selectedBills,
    setSelectedBills,
    activeTab,
    setActiveTab,
    closeLoan,
    loader,
    setLoader,
    disRecordPayoutData,
    loanModalSuccessStatus,
  };
};
export default useApplicationViewById;
