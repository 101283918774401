import { FC, useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import useTrades from "../../../hooks/useTrades";
import PartnerInfo from "../partner";
import { getTradeStatus } from "../../../helpers/getText";
import { FaAngleDown, FaChevronRight } from "react-icons/fa6";
import Calender from "../../commons/calender";
import Modal from "../../ui/modal";
import ModalHeader from "../../ui/modal/modal-header";
import Popover from "../../ui/popover";
import Checkbox from "@mui/material/Checkbox";
import NodataAvailable from "../../../assets/images/NodataAvailable.svg";
import Loading from "../../../assets/images/loading.gif";
import {
  UpdateTradePaymentActiveTab,
  UpdateTradeTypeActiveTab,
} from "../../../reducers/tradeSlice";
import { useDispatch } from "react-redux";
import { Button } from "../../../components/ui";
// import badGate from '../../../assets/images/badGateWay.svg'
const tradeStatusList = [
  {
    label: "Acceptance Pending",
    value: "PENDING",
  },
  {
    label: "Trade Accepted",
    value: "ACCEPTED",
  },
  {
    label: "Arrival Confirmed",
    value: "ARL_CONFIRMED",
  },
  {
    label: "Patti Finalized",
    value: "PATTI_FINALIZED",
  },
  {
    label: "Payment Pending",
    value: "PMT_PENDING",
  },
  {
    label: "Payment Completed",
    value: "PMT_COMPLETED",
  },
  {
    label: "Trade Cancel",
    value: "CANCEL",
  },
];
const Trades: FC<{
  status: string;
  searchText: string;
  displayMode: "table" | "list";
  //  openStatus: boolean;
}> = ({ status, searchText, displayMode }) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  var storedStatus = JSON.parse(
    localStorage.getItem("tradeStatusValue") || JSON.stringify([])
  ); // array ki and json ki

  const [isTradeStatus, setIsTradeStatus] = useState(false); //popup values
  const [tradeStatusValue, setTradeStatusValue] =
    useState<string[]>(storedStatus); //filter
  const [selectStatus, setselectStatus] = useState<string[]>(storedStatus); // select cheskodaniki indulo "storedStatus" enduku ichanu antey check chesimdi kuda tabs change appudu chupiyali kadaa thats y.
  const label = { inputProps: { "aria-label": "Checkbox demo" } }; //checkbox kosam

  // this is what i written for dates

  var storedDateChange = localStorage.getItem("dateChange") || "false";
  var storedCalenderDate = JSON.parse(
    localStorage.getItem("calenderDate") ||
      JSON.stringify({
        fromDate: moment().format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
      })
  );

  const [isCalender, setIsCalender] = useState(false); //show or hide the calender
  const [dateChange, setDateChange] = useState(storedDateChange === "true"); //manage state when the caender date is changed
  const [calenderDate, setCalenderDate] = useState(
    //calender date for storing the data which we are entering fromDate toDate in calender
    storedCalenderDate
  );

  // tabs switch inapudu open nundi close ki status-filter clear avvali
  useEffect(() => {
    //status filter
    if (status == "CLOSED") {
      setTradeStatusValue([]);
      setselectStatus([]);
    } else if (status == "OPEN") {
      //status filter
      storedStatus =
        localStorage.getItem("tradeStatusValue") || JSON.stringify([]);
      setTradeStatusValue(JSON.parse(storedStatus));
      setselectStatus(JSON.parse(storedStatus));
    }
  }, [status]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const {
    trades,
    isLoading,
    //   hasError,
    //  setHasError
  } = useTrades({
    status: status,
    fromDate: dateChange ? calenderDate.fromDate : "",
    toDate: dateChange ? calenderDate.toDate : "",
    //openStatus : openStatus,
  });

  if (displayMode === "list") {
    return (
      <div className="hidden md:block">
        <div className="bg-light-primary flex h-10 mt-4">
          <div>
            <button
              className="color-blue flex ml-3 mt-2"
              onClick={() => {
                setIsCalender(true);
              }}
            >
              Date{" "}
              <span>
                <FaAngleDown className="mt-[5px]" />
              </span>
            </button>
          </div>

          <div>
            <button
              onClick={() => {
                setIsTradeStatus(true);
              }}
              className="color-blue grow-[2] basis-0 px-2 flex cursor-pointer ml-[140px] mt-2"
            >
              Status
              <span>
                <FaAngleDown className="mt-[5px] ml-[5px]" />
              </span>
            </button>
          </div>
        </div>

        {/* status pop-over at Open Trades */}
        <div>
          <Popover
            open={isTradeStatus}
            onClose={() => {
              setIsTradeStatus(false);
            }}
            fullWidth={true}
          >
            <div>
              <div>
                {tradeStatusList.map((tradeStatus) => (
                  <div
                    style={{ backgroundColor: "white", width: "250px" }}
                    className="flex items-center px-3 py-1 gap-2 cursor-pointer hv-primary"
                    onClick={() => {
                      //onClick for status
                      const newValue = selectStatus.includes(tradeStatus.value)
                        ? selectStatus.filter(
                            (item) => item !== tradeStatus.value
                          )
                        : [...selectStatus, tradeStatus.value];
                      setselectStatus(newValue);
                    }}
                  >
                    <div>
                      {/* checkbox for status */}
                      <span>
                        {" "}
                        <Checkbox
                          {...label}
                          checked={selectStatus.includes(tradeStatus.value)}
                        />
                      </span>
                      <span className="font-semibold text-sm">
                        {tradeStatus.label}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="flex p-3 gap-2 bg-white border-b"
                style={{ width: "220px" }}
              >
                <Button
                  text="Clear All"
                  variant="primaryLight"
                  onClick={() => {
                    setTradeStatusValue([]);
                    setIsTradeStatus(false);
                    setselectStatus([]); // CLEAR APPUDU CHECK BOX MEEDA TICK POVADANIKI
                    localStorage.setItem(
                      "tradeStatusValue",
                      JSON.stringify([])
                    );
                  }}
                />

                <Button
                  text="Apply"
                  onClick={() => {
                    setIsTradeStatus(false);
                    setTradeStatusValue(selectStatus);
                    localStorage.setItem(
                      "tradeStatusValue",
                      JSON.stringify(selectStatus)
                    );
                  }}
                />
              </div>
            </div>
          </Popover>
        </div>

        <div>
          <Modal
            open={isCalender}
            onClose={() => {
              setIsCalender(false);
            }}
            width="30vw"
          >
            <>
              {/* calender Modal */}

              <ModalHeader title={"Date Filter"} content={<></>} />
              <div className="py-3 px-6">
                <Calender
                  mode={"range"}
                  onChange={(data: { fromdate: string; todate: string }) => {
                    if (data.fromdate !== "" && data.todate !== "") {
                      setCalenderDate({
                        ...calenderDate,
                        fromDate: moment(data.fromdate, "YYYY-MMM-DD").format(
                          "YYYY-MM-DD"
                        ),
                        toDate: moment(data.todate, "YYYY-MMM-DD").format(
                          "YYYY-MM-DD"
                        ),
                      });
                      if (status === "OPEN") {
                        localStorage.setItem(
                          "calenderDate",
                          JSON.stringify({
                            fromDate: moment(
                              data.fromdate,
                              "YYYY-MMM-DD"
                            ).format("YYYY-MM-DD"),
                            toDate: moment(data.todate, "YYYY-MMM-DD").format(
                              "YYYY-MM-DD"
                            ),
                          })
                        );
                        localStorage.setItem("dateChange", "true");
                      } else {
                        localStorage.setItem(
                          "closedCalenderDate",
                          JSON.stringify({
                            fromDate: moment().format("YYYY-MM-DD"),
                            toDate: moment().format("YYYY-MM-DD"),
                          })
                        );
                        localStorage.setItem("closedDateChange", "true");
                      }
                    } else if (data.fromdate !== "" && data.todate === "") {
                      setCalenderDate({
                        ...calenderDate,
                        fromDate: moment(data.fromdate, "YYYY-MMM-DD").format(
                          "YYYY-MM-DD"
                        ),
                        toDate: moment(data.fromdate, "YYYY-MMM-DD").format(
                          "YYYY-MM-DD"
                        ),
                      });
                      if (status === "OPEN") {
                        localStorage.setItem(
                          "calenderDate",
                          JSON.stringify({
                            fromDate: moment(
                              data.fromdate,
                              "YYYY-MMM-DD"
                            ).format("YYYY-MM-DD"),
                            toDate: moment(data.fromdate, "YYYY-MMM-DD").format(
                              "YYYY-MM-DD"
                            ),
                          })
                        );
                        localStorage.setItem("dateChange", "true");
                      } else {
                        localStorage.setItem(
                          "closedCalenderDate",
                          JSON.stringify({
                            fromDate: moment(
                              data.fromdate,
                              "YYYY-MMM-DD"
                            ).format("YYYY-MM-DD"),
                            toDate: moment(data.fromdate, "YYYY-MMM-DD").format(
                              "YYYY-MM-DD"
                            ),
                          })
                        );
                        localStorage.setItem("closedDateChange", "true");
                      }
                    }
                    setIsCalender(false); //idhi false cheyakapotey calender open loney untadi
                    setDateChange(true); // filtering data
                  }}
                  onClear={() => {
                    if (status === "OPEN") {
                      localStorage.setItem("dateChange", "false");
                      localStorage.setItem("calenderDate", "");
                    } else {
                      localStorage.setItem("closedDateChange", "false");
                      localStorage.setItem("closedCalenderDate", "");
                    }
                    setCalenderDate({
                      ...calenderDate,
                      fromDate: moment().format("YYYY-MM-DD"),
                      toDate: moment().format("YYYY-MM-DD"),
                    });
                    setDateChange(false);
                    setIsCalender(false);
                  }}
                  initialDateRange={{
                    fromdate: calenderDate.fromDate,
                    todate: calenderDate.toDate,
                  }}
                  maxDate={moment().format("YYYY-MM-DD")}
                  style={{}}
                />
              </div>
            </>
          </Modal>
        </div>
        <div
          className="overflow-auto bg-white"
          style={{ height: "calc(100vh - 15.5rem)" }}
        >
          {(searchText !== ""
            ? trades.filter((trade) => {
                return trade.tradeId.toString().includes(searchText);
              }) // status filter
            : tradeStatusValue.length !== 0
            ? trades.filter((trade) => {
                // if(tradeStatusValue.includes('ALL')){
                //   return trades;
                // }
                return tradeStatusValue.includes(trade.destTradeStatus);
              })
            : trades
          ).map((trade) => {
            let isActive = (params.tradeId || "") === trade.tradeId.toString();
            return (
              <div
                className={`border-b py-3 px-2 flex ${
                  isActive ? " bg-primary text-white" : ""
                }`}
                onClick={() => {
                  navigate(
                    `/trade-detail/${trade.srcPartyId}/${trade.tradeId}?tab=${status}`
                  );
                  // dispatch(UpdateTradeTypeActiveTab("trade_details"));
                  // dispatch(UpdateTradePaymentActiveTab("outward_payment"));
                }}
              >
                <div className="flex flex-col">
                  <span
                    className={`text-xs font-semibold ${
                      isActive ? "text-white" : "color-blue"
                    }`}
                  >
                    Global ID: {trade.tradeId}
                  </span>
                  <span className="text-sm font-semibold">
                    From : {trade.srcTradeName}
                  </span>
                  <span className="text-xs">
                    {moment(trade.date).format("DD MMM YYYY")}
                  </span>
                </div>
                <div
                  className={`${
                    isActive ? "color-white" : "color-primary"
                  } ml-auto flex items-center font-semibold text-sm`}
                >
                  {/* {trade.tradeStatus + trade.srcTradeStatus} */}
                  {
                    trade.destTradeStatus == "PMT_COMPLETED" &&
                    trade.srcTradeStatus == "PMT_COMPLETED"
                      ? "PMT_COMPLETED"
                      : trade.destTradeStatus == "PMT_PENDING" ||
                        trade.srcTradeStatus == "PMT_PENDING"
                      ? "PMT_PENDING"
                      : trade.destTradeStatus == "PMT_COMPLETED" ||
                        trade.srcTradeStatus == "PMT_COMPLETED"
                      ? "PMT_PENDING"
                      : trade.destTradeStatus
                    /* {getTradeStatus(
                trade.tradeStatus,
                0, //trade.paidAmt
                trade.arlTime ? trade?.arlTime : "",
                trade.pattiAmt,
                trade.totalPayable
              )} */
                  }
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className=" ">
      {isLoading ? (
        <div
          style={{
            height: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={Loading} alt="loading" className="w-16 h-16" />
        </div>
      ) : (
        <>
          <div
            className="overflow-auto bg-white"
            style={{
              height: isMobile ? "calc(100vh - 14rem)" : "calc(100vh - 10rem)",
              // width: "500px",
              overflowX: "auto",
            }}
          >
            <table className="w-full border-collapse">
              <thead className="bg-light-primary text-left sticky top-0 z-10">
                <tr className="text-sm font-semibold">
                  <th
                    className="px-2 md:px-4 py-2 border text-center w-[2%]"
                    style={{
                      width: isMobile ? "50px" : "",
                      minWidth: isMobile ? "50px" : "",
                      position: isMobile ? "sticky" : "relative",
                      top: 0,
                      left: 0,
                      zIndex: 999,
                      backgroundColor: "#d7f3dd",
                    }}
                  >
                    #
                  </th>
                  <th
                    className="px-2 md:px-4 py-2 border w-[7%]"
                    style={{
                      width: isMobile ? "80px" : "",
                      minWidth: isMobile ? "80px" : "",
                      position: isMobile ? "sticky" : "relative",
                      top: isMobile ? 0 : 0,
                      left: isMobile ? 50 : 0,
                      zIndex: 999,
                      backgroundColor: "#d7f3dd",
                    }}
                  >
                    Global ID
                  </th>
                  <th
                    className="px-2 md:px-4 py-2  border  w-[12%]"
                    style={{ minWidth: isMobile ? "160px" : "" }}
                  >
                    <div className="flex items-center">
                      Trade ID
                      <span
                        className="color-blue flex ml-1 cursor-pointer"
                        onClick={() => setIsCalender(true)}
                      >
                        Date
                        <FaAngleDown className="ml-1 mt-[5px]" />
                      </span>
                    </div>
                  </th>
                  <th
                    className="px-2 md:px-4 py-2 border w-[16%]"
                    style={{ minWidth: isMobile ? "250px" : "" }}
                  >
                    Source Party
                  </th>
                  <th
                    className="px-2 md:px-4 py-2 border w-[15%]"
                    style={{ minWidth: isMobile ? "350px" : "" }}
                  >
                    From
                  </th>
                  <th
                    className="px-2 md:px-4 py-2 border w-[16%]"
                    style={{ minWidth: isMobile ? "250px" : "" }}
                  >
                    Destination Party
                  </th>
                  <th
                    className="px-2 md:px-4 py-2 border w-[16%]"
                    style={{ minWidth: isMobile ? "300px" : "" }}
                  >
                    To
                  </th>
                  {status === "OPEN" ? (
                    <th className="px-4 py-2 border cursor-pointer color-blue">
                      <div
                        onClick={() => setIsTradeStatus(true)}
                        className="flex items-center"
                      >
                        Status
                        <FaAngleDown className="ml-1 mt-[5px]" />
                      </div>
                      <Popover
                        open={isTradeStatus}
                        onClose={() => setIsTradeStatus(false)}
                      >
                        <div>
                          {tradeStatusList.map((tradeStatus) => (
                            <div
                              key={tradeStatus.value}
                              className="flex items-center px-3 py-1 gap-2 cursor-pointer bg-white"
                              onClick={() => {
                                const newValue = selectStatus.includes(
                                  tradeStatus.value
                                )
                                  ? selectStatus.filter(
                                      (item) => item !== tradeStatus.value
                                    )
                                  : [...selectStatus, tradeStatus.value];
                                setselectStatus(newValue);
                              }}
                            >
                              <Checkbox
                                {...label}
                                checked={selectStatus.includes(
                                  tradeStatus.value
                                )}
                              />
                              <span className="font-semibold text-sm">
                                {tradeStatus.label}
                              </span>
                            </div>
                          ))}
                          <div className="flex p-3 gap-2">
                            <Button
                              text="Clear All"
                              variant="primaryLight"
                              onClick={() => {
                                setTradeStatusValue([]);
                                setIsTradeStatus(false);
                                setselectStatus([]);
                                localStorage.setItem(
                                  "tradeStatusValue",
                                  JSON.stringify([])
                                );
                              }}
                            />
                            <Button
                              text="Apply"
                              onClick={() => {
                                setIsTradeStatus(false);
                                setTradeStatusValue(selectStatus);
                                localStorage.setItem(
                                  "tradeStatusValue",
                                  JSON.stringify(selectStatus)
                                );
                              }}
                            />
                          </div>
                        </div>
                      </Popover>
                    </th>
                  ) : (
                    <th
                      className="px-4 py-2 border"
                      style={{ minWidth: isMobile ? "200px" : "" }}
                    >
                      Status
                    </th>
                  )}
                </tr>
              </thead>
              {trades.length > 0 ? (
                <tbody>
                  {(searchText !== ""
                    ? trades.filter((trade) =>
                        trade.tradeId.toString().includes(searchText)
                      )
                    : tradeStatusValue.length !== 0
                    ? trades.filter((trade) =>
                        tradeStatusValue.includes(trade.destTradeStatus)
                      )
                    : trades
                  ).map((trade, index) => (
                    <tr
                      key={trade.tradeId}
                      className="cursor-pointer "
                      onClick={() =>
                        navigate(
                          `/trade-detail/${trade.srcPartyId}/${trade.tradeId}?tab=${status}`
                        )
                      }
                    >
                      <td
                        className="px-2 md:px-4 py-2 text-center border"
                        style={{
                          // width: isMobile ? "50px" : "",
                          // minWidth: isMobile ? "50px" : "",
                          position: isMobile ? "sticky" : "relative",
                          top: isMobile ? 0 : 0,
                          left: isMobile ? 0 : 0,
                          zIndex: 2,
                          backgroundColor: "#fff",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="px-2 md:px-4 py-2 border"
                        style={{
                          position: isMobile ? "sticky" : "relative",
                          top: isMobile ? 0 : 0,
                          left: isMobile ? 50 : 0,
                          zIndex: 2,
                          backgroundColor: "#fff",
                        }}
                      >
                        {trade.tradeId}
                      </td>
                      <td className="px-2 md:px-4 py-2 border">
                        <div className="flex flex-col">
                          <span className="font-semibold">
                            {trade.traderSeq}
                          </span>
                          <span className="text-xs">
                            {trade.date
                              ? moment(trade.date).format("DD-MMM-YYYY")
                              : "-"}
                            {trade.timeStamp
                              ? moment(trade.timeStamp).format(" | hh:mm a")
                              : ""}
                          </span>
                        </div>
                      </td>
                      <td className="px-2 md:px-4 py-2 border">
                        <PartnerInfo
                          partyType="From"
                          name={trade.srcName || ""}
                          tradeName={trade.srcTradeName || ""}
                          profilePic=""
                        />
                      </td>
                      <td className="px-2 md:px-4 py-2 border text-sm">
                        {trade.srcAddrLine ||
                        trade.srcMarketName ||
                        trade.srcDist ? (
                          <span>
                            {trade.srcAddrLine} {trade.srcMarketName},{" "}
                            {trade.srcDist}, {trade.srcState} {trade.srcPincode}
                          </span>
                        ) : (
                          "--"
                        )}
                      </td>
                      <td className="px-2 md:px-4 py-2 border">
                        <PartnerInfo
                          partyType="To"
                          name={trade.destName}
                          tradeName={trade.destTradeName}
                          profilePic=""
                        />
                      </td>
                      <td className="px-2 md:px-4 py-2 border text-sm">
                        {trade.destAddrLine ||
                        trade.destMarketName ||
                        trade.destCity ? (
                          <span>
                            {trade.destAddrLine}, {trade.destMarketName},{" "}
                            {trade.destDist}, {trade.destState}{" "}
                            {trade.destPincode}
                          </span>
                        ) : (
                          "--"
                        )}
                      </td>
                      <td className="px-2 md:px-4 py-2 border text-sm">
                        <div className="flex items-center justify-between">
                          <div>
                            {trade.destTradeStatus === "PMT_COMPLETED" &&
                            trade.srcTradeStatus === "PMT_COMPLETED"
                              ? "PMT_COMPLETED"
                              : trade.destTradeStatus === "PMT_PENDING" ||
                                trade.srcTradeStatus === "PMT_PENDING"
                              ? "PMT_PENDING"
                              : trade.destTradeStatus}
                            <br />
                            <span className="text-xs">
                              {trade.destTradeStatus === "CLOSED"
                                ? trade.closedDate
                                  ? moment(trade.closedDate).format(
                                      "DD-MMM-YYYY "
                                    )
                                  : "-"
                                : ""}
                            </span>
                          </div>
                          <FaChevronRight className="ml-2" />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={100} className="md:text-center py-10">
                      <div className="bg-white">
                        <img
                          src={NodataAvailable}
                          alt="No Data"
                          className="pl-12 md:pl-0 md:mx-auto"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </>
      )}

      <div>
        <Modal
          open={isCalender}
          onClose={() => {
            setIsCalender(false);
          }}
          width={isMobile ? "100vw" : "30vw"}
        >
          <>
            <ModalHeader title={"Date Filter"} content={<></>} />
            <div className="py-3 px-2 md:px-6">
              <Calender
                mode={"range"}
                onChange={(data: { fromdate: string; todate: string }) => {
                  if (data.fromdate !== "" && data.todate !== "") {
                    setCalenderDate({
                      ...calenderDate,
                      fromDate: moment(data.fromdate, "YYYY-MMM-DD").format(
                        "YYYY-MM-DD"
                      ),
                      toDate: moment(data.todate, "YYYY-MMM-DD").format(
                        "YYYY-MM-DD"
                      ),
                    }); // dates apply ipotunai
                    if (status === "OPEN") {
                      localStorage.setItem(
                        "calenderDate",
                        JSON.stringify({
                          fromDate: moment(data.fromdate, "YYYY-MMM-DD").format(
                            "YYYY-MM-DD"
                          ),
                          toDate: moment(data.todate, "YYYY-MMM-DD").format(
                            "YYYY-MM-DD"
                          ),
                        })
                      ); // calender date ki from date to date set chestuna
                      localStorage.setItem("dateChange", "true"); // date change true avutundi api loki dates veltunai
                    } else {
                      //closed dates all vachestaai
                      localStorage.setItem(
                        "closedCalenderDate",
                        JSON.stringify({
                          fromDate: moment().format("YYYY-MM-DD"),
                          toDate: moment().format("YYYY-MM-DD"),
                        })
                      );
                      localStorage.setItem("closedDateChange", "true");
                    }
                  } else if (data.fromdate !== "" && data.todate === "") {
                    // single date selection
                    setCalenderDate({
                      ...calenderDate,
                      fromDate: moment(data.fromdate, "YYYY-MMM-DD").format(
                        "YYYY-MM-DD"
                      ),
                      toDate: moment(data.fromdate, "YYYY-MMM-DD").format(
                        "YYYY-MM-DD"
                      ),
                    });
                    if (status === "OPEN") {
                      localStorage.setItem(
                        "calenderDate",
                        JSON.stringify({
                          fromDate: moment(data.fromdate, "YYYY-MMM-DD").format(
                            "YYYY-MM-DD"
                          ),
                          toDate: moment(data.fromdate, "YYYY-MMM-DD").format(
                            "YYYY-MM-DD"
                          ),
                        })
                      );
                      localStorage.setItem("dateChange", "true");
                    } else {
                      localStorage.setItem(
                        "closedCalenderDate",
                        JSON.stringify({
                          fromDate: moment(data.fromdate, "YYYY-MMM-DD").format(
                            "YYYY-MM-DD"
                          ),
                          toDate: moment(data.fromdate, "YYYY-MMM-DD").format(
                            "YYYY-MM-DD"
                          ),
                        })
                      );
                      localStorage.setItem("closedDateChange", "true");
                    }
                  }
                  setIsCalender(false); //idhi false cheyakapotey calender open loney untadi
                  setDateChange(true); // filtering data
                }}
                onClear={() => {
                  if (status === "OPEN") {
                    localStorage.setItem("dateChange", "false");
                    localStorage.setItem("calenderDate", "");
                  } else {
                    localStorage.setItem("closedDateChange", "false");
                    localStorage.setItem("closedCalenderDate", "");
                  }
                  setCalenderDate({
                    ...calenderDate,
                    fromDate: moment().format("YYYY-MM-DD"),
                    toDate: moment().format("YYYY-MM-DD"),
                  });
                  setDateChange(false);
                  setIsCalender(false);
                }}
                initialDateRange={{
                  fromdate: calenderDate.fromDate,
                  todate: calenderDate.toDate,
                }}
                maxDate={moment().format("YYYY-MM-DD")}
                style={{}}
              />
            </div>
          </>
        </Modal>
      </div>
    </div>
  );
};
export default Trades;
