import { FC, useState, useRef, useEffect, memo, useLayoutEffect } from "react";
import { BsChevronDown, BsCheckLg } from "react-icons/bs";
import { createPortal } from "react-dom";
import classes from "./style.module.scss";
import { CSSProperties } from "styled-components";

type TOnoSelect = {
  options: Array<{ label: string; value: string; disabled?: boolean; image?: string; textColor?: string }>;
  value?: string | number;
  lable?: string;
  styles?: CSSProperties;
  onChange?: (selectedOption: { label: string; value: string }) => void;
  classNames?: string;
  starMark?: boolean;
  fromSupplyDemand?: boolean;
};
const gap = 0;
export const OnoSelect: FC<TOnoSelect> = ({
  options,
  value = "",
  lable = "",
  onChange,
  classNames,
  starMark,
  fromSupplyDemand,
  styles = {},
}) => {
  const ref = useRef<HTMLElement>(null);
  const optionsRef = useRef<HTMLUListElement>(null);

  const [display, setDisplay] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    label: "",
    value: "",
    disabled: false,
    image: '',
    textColor: '',
  });
  const [position, setPosition] = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    bottom: 0,
  });
  const onSelect = (option: {
    label: string;
    value: string;
    disabled?: boolean;
  }) => {
    if (!option.disabled) {
      onChange && onChange(option);
      setDisplay(false);
    }
  };
  useEffect(() => {
    if (value) {
      let sel_option = { label: "", value: "", disabled: false, image: '', textColor: '', };
      for (let option of options) {
        if (option.value === value && !option.disabled) {
          sel_option = {
            label: option.label,
            value: option.value,
            disabled: option.disabled!,
            image: option.image!,
            textColor: option.textColor!,
          };
          break;
        }
      }
      setSelectedOption(sel_option);
    } else {
      setSelectedOption({
        label: "",
        value: "",
        disabled: false,
        image: '',
        textColor: '',
      });
    }
  }, [value, options]);
  useEffect(() => {
    if (
      ref.current !== null &&
      optionsRef.current !== null &&
      display === true
    ) {
      let windowsize = [window.innerWidth, window.innerHeight];
      // const _ = optionsRef.current.offsetHeight;
      const optionsPosition = optionsRef.current.getBoundingClientRect();
      const selectBoxPosition = ref.current.getBoundingClientRect();
      let bottomSpace = windowsize[1] - selectBoxPosition.bottom;

      if (optionsPosition.height > bottomSpace + 10) {
        setPosition({
          ...position,
          left: selectBoxPosition.left,
          top:
            selectBoxPosition.top > optionsPosition.height
              ? selectBoxPosition.top - optionsPosition.height
              : selectBoxPosition.top + selectBoxPosition.height + gap,
          width: selectBoxPosition.width,
        });
      } else {
        setPosition({
          ...position,
          left: selectBoxPosition.left,
          top: selectBoxPosition.top + selectBoxPosition.height + gap,
          width: selectBoxPosition.width,
        });
      }
    }
  }, [display]);
  return (
    <>
      <div
        className={classNames}
        style={{ height: fromSupplyDemand ? "100%" : "auto" }}
      >
        <label className="font-semibold text-sm">
          {lable}
          {/* <span className="color-red">{starMark ? "*" : ""}</span> */}
        </label>
        <button
          className="w-full h-full rounded-md "
          disabled={selectedOption?.disabled}
        >
          <span
            className={`flex w-full h-full items-center cursor-pointer border br-10 py-2 ${classes.selectbox}`}
            ref={ref}
            onClick={() => {
              setDisplay(!display);
            }}
            style={styles}
          >
            <span className="flex items-center pr-1">
              {selectedOption.image && <img
                src={selectedOption.image}
                className="w-5 h-5 mr-1"
              />}
              <span className={`${selectedOption.textColor}`}>{selectedOption.label || " -- Select --"}</span>
            </span>
            <BsChevronDown className={`ml-auto color-gray`} />
          </span>
        </button>
      </div>
      {display === true &&
        createPortal(
          <>
            <div
              onClick={() => {
                setDisplay(false);
              }}
              className="fixed h-full w-full top-0 left-0"
              style={{ zIndex: "110" }}
            ></div>
            <ul
              ref={optionsRef}
              className={`shadow-md ${classes.selectUl}`}
              style={{
                top: position.top,
                left: position.left,
                width: `${position.width}px`,
              }}
            >
              {options.map((option) => (
                <li
                  className={`flex px-2 py-3 text-sm items-center `}
                  data-value={option.value}
                  data-selected={
                    option.value === value && !option.disabled ? true : false
                  }
                  data-disabled={option.disabled}
                  onClick={() => {
                    onSelect(option);
                  }}
                >
                  {option.image && <img
                    src={option.image}
                    className="w-5 h-5 mr-1"
                  />}
                  <span className={`${option.textColor}`}>{option.label}</span>
                  {option.value === value && <BsCheckLg className="ml-auto" />}
                </li>
              ))}
            </ul>
          </>,
          document.body
        )}
    </>
  );
};

export default memo(OnoSelect);
