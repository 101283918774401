import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAxiosClient from "./useAxiosClient";
import { TRootState } from "../store";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Compressor from "compressorjs";
import {
  TCrop,
  TDestinationPartner,
  TSupplyDemandReq,
  TTradeDocument,
} from "../types";
import {
  updateActiveTradeTab,
  updateCropData,
  updatePartyData,
} from "../reducers/tradeSlice";
import { timeStamp } from "console";
const useCreateTradeSupplyDEmand = ({
  id,
  modalClose,
  type,
  fromPropsal,
}: {
  id?: number;
  modalClose?: any;
  type?: string;
  fromPropsal?: boolean;
}) => {
  const { handleGetCall, handlePutCall, handlePostCall } = useAxiosClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [kycDocs, setKycDocs] = useState<
    Array<{ docType: "image" | "pdf"; url: string; type: string }>
  >([]);
  const {
    supplyDEtailsObj,
    selectedCrop,
    selectedTrader,
    partyData,
    deletedCrop,
    selectedSrcParty,
    role,
    adminId,
    traderContactInfo,
    tententType,
  } = useSelector((state: TRootState) => ({
    supplyDEtailsObj:
      type == "SUPPLY"
        ? state.trades.supplyDetailsById
        : state.trades.demandDetailsById,
    adminId: state.auth.adminId,
    selectedCrop: state.trades.selectedCrop,
    selectedTrader: state.trades.selectedParty,
    partyData: state.trades.partyData,
    deletedCrop: state.trades.deletedCrop,
    selectedSrcParty: state.trades.selectedSrcParty,
    role: state.auth.userInfo?.roleType,
    traderContactInfo: state.trades.traderContactInfo,
    tententType: state.auth.userInfo?.type,
  }));
  const [details, setDetails] = useState<TSupplyDemandReq | null>(
    supplyDEtailsObj
  );
  const [traderData, setTraderData] = useState<TDestinationPartner[]>([]);
  const [tradeType, setTradeType] = useState(
    type == "SUPPLY" ? "DEST" : "SOURCE"
  );
  const [selectedDate, setSelectedDate] = useState(
    moment(
      type == "SUPPLY"
        ? new Date()
        : details?.expDate
        ? details?.expDate
        : new Date()
    ).format("DD-MMM-YYYY")
  );
  let traderId = details?.traderId || 0;
  const handleDateSelection = (_date: string) => {
    let date = moment(_date).format("YYYY-MM-DD");
    //  setCalenderOpen(false);
    setSelectedDate(date);
    setDetails({
      ...details!,
      pickupDate: date,
    });
  };
  const [selectedPartner, setSeletedPartner] =
    useState<TDestinationPartner | null>(null);
  const [selectedSrc, setSeletedSrc] = useState<TDestinationPartner | null>(
    null
  );

  useEffect(() => {
    setDetails({
      ...details!,
      freightAmt: details?.freightAmt || "",
      freightAdv: details?.freightAdv || "",
      balFreight: details?.balFreight || 0,
      cmdityCost: details?.cmdityCost || "",
      invAmt: details?.invAmt || "",
      srcVehNum: details?.srcVehNum || "",
      tradeMode: details?.tradeMode || "ORDER",
    });
  }, [details]);
  useEffect(() => {
    // if (selectedCrop == null) {
    let obj = {
      qtyUnit: "BAGS",
      weightUnit: "RATE_PER_KG",
      qty: 0,
      weight: details?.qty || 0,
      freightAmt: 0,
      status: 1,
      id: 0,
      cropName: details?.cropName || "",
      cropId: details?.cropId || 0,
      baseName: "",
      imageUrl: details?.cropUrl || "",
      type: "",
      units: "",
      varietyName: "",
      rateType: details?.unitType ? details?.unitType.toString() : "",
      tradeId: 0,
      addWastage: 0,
      destWeight: 0,
      destRate: 0,
      rate: details?.expRate || 0,
      unitType: details?.unitType ? details?.unitType.toString() : "",
    };
    dispatch(updateCropData({ selectedCrop: obj }));
    // }
  }, []);
  useEffect(() => {
    getPartyData(tradeType);
  }, [tradeType]);
  const getPartyData = (tradeType: string) => {
    handleGetCall<TDestinationPartner[]>(
      `/admin/trade-platform/parties/adminId/${adminId}/type/${tradeType}?tenant=${tententType}`
    ).then(({ data }) => {
      setTraderData(data);
      dispatch(updatePartyData({ partyData: data }));
    });
  };
  const [searchText, setSearchText] = useState("");
  const handelSearch = (e: any) => {
    let searchtext = e.target.value;
    setSearchText(searchtext);
    if (searchText) {
      let searchTextLower = searchtext.toLowerCase();
      let filteredBuyers = partyData.filter((partner) => {
        if (
          (partner.tradeName &&
            partner.tradeName.toLowerCase().includes(searchTextLower)) ||
          (partner.addrLine &&
            partner.addrLine.toLowerCase().includes(searchText)) ||
          (partner.traderId &&
            partner.traderId.toString().includes(searchTextLower))
        ) {
          return true;
        } else {
          return false;
        }
      });
      setTraderData(filteredBuyers);
    } else {
      setTraderData(partyData);
    }
  };
  useEffect(() => {
    setDetails({
      ...details!,
      invAmt: Number(details?.cmdityCost) + Number(details?.freightAmt),
    });
  }, [details?.cmdityCost, details?.freightAmt]);
  useEffect(() => {
    if (details?.freightAmt != 0) {
      setDetails({
        ...details!,
        balFreight: Number(details?.freightAmt) - Number(details?.freightAdv),
      });
    }
  }, [details?.freightAdv]);
  // clear serch
  const clearSearch = (type: string) => {
    if (type == "DEST") {
      setSearchText("");
      setTraderData(partyData);
      setSeletedPartner(null);
    } else {
      setSearchText("");
      setTraderData(partyData);
      setSeletedSrc(null);
    }
  };
  useEffect(() => {
    var weight = selectedCrop?.weight;
    if (
      selectedCrop?.weightUnit == "RATE_PER_KG" &&
      selectedCrop != null &&
      selectedCrop?.weight
    ) {
      if (selectedCrop?.unitType == "QUINTAL") {
        weight = selectedCrop?.weight * 100;
      } else if (selectedCrop?.unitType == "MT/TON") {
        weight = selectedCrop?.weight * 1000;
      } else {
        weight = selectedCrop?.weight;
      }
    }

    if (
      selectedCrop != null &&
      selectedCrop?.weight &&
      selectedCrop?.weightUnit == "RATE_PER_KG"
    ) {
      setDetails({
        ...details!,
        cmdityCost: parseFloat(
          (Number(selectedCrop?.rate) * Number(weight)).toFixed(2)
        ),
      });
    } else if (selectedCrop?.weightUnit == "RATE_PER_UNIT") {
      setDetails({
        ...details!,
        cmdityCost: parseFloat(
          (Number(selectedCrop?.rate) * Number(selectedCrop?.qty)).toFixed(2)
        ),
      });
    }
  }, [
    selectedCrop?.weightUnit,
    selectedCrop?.weight,
    selectedCrop?.qty,
    selectedCrop?.unitType,
    selectedCrop?.rate,
  ]);
  const [tapalInvoice, setTapalInvoice] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const [receipts, setReceipts] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const [otherDocuments, setOtherDocuments] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);

  const onUploadImage = async (
    type: "tapal" | "receipt" | "other",
    file: any
  ) => {
    let fileType = "";
    if (file.type === "application/pdf") {
      fileType = "pdf";
    } else if (file.type.includes("image/")) {
      fileType = "image";
    }
    let formdata = new FormData();
    formdata.append("traderId", traderId.toString());
    if (fileType === "pdf") {
      formdata.append("file", file, file.name.replace(" ", "-"));
      let imageUploadResponse = await handlePostCall<string[]>({
        URL: "/mandi/files/trade-portal/imgs/upload",
        apiParams: formdata,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      });
      let url = imageUploadResponse.data[0];
      if (type === "tapal") {
        setTapalInvoice([{ docType: "pdf", url: url }, ...tapalInvoice]);
      } else if (type === "receipt") {
        setReceipts([{ docType: "pdf", url: url }, ...receipts]);
      } else if (type === "other") {
        setOtherDocuments([{ docType: "pdf", url: url }, ...otherDocuments]);
      }
    } else {
      new Compressor(file, {
        quality: 0.8,
        success: async (compressedResult) => {
          formdata.append(
            "file",
            compressedResult,
            file.name.replace(" ", "-")
          );
          let imageUploadResponse = await handlePostCall<string[]>({
            URL: "/mandi/files/trade-portal/imgs/upload",
            apiParams: formdata,
            config: {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          });
          let url = imageUploadResponse.data[0];
          if (type === "tapal") {
            setTapalInvoice([{ docType: "image", url: url }, ...tapalInvoice]);
          } else if (type === "receipt") {
            setReceipts([{ docType: "image", url: url }, ...receipts]);
          } else if (type === "other") {
            setOtherDocuments([
              { docType: "image", url: url },
              ...otherDocuments,
            ]);
          }
        },
      });
    }
  };
  const onRemoveImage = (
    type: "tapal" | "receipt" | "other",
    index: number,
    isExist: boolean = false
  ) => {
    if (type === "tapal") {
      let newtapalInvoice = [...tapalInvoice];
      newtapalInvoice.splice(index, 1);
      setTapalInvoice(newtapalInvoice);
    } else if (type === "receipt") {
      let newtapalInvoice = [...receipts];
      newtapalInvoice.splice(index, 1);
      setReceipts(newtapalInvoice);
    } else if (type === "other") {
      let newtapalInvoice = [...otherDocuments];
      newtapalInvoice.splice(index, 1);
      setOtherDocuments(newtapalInvoice);
    }
  };
  const submit = async () => {
    let images = tapalInvoice.concat(receipts, otherDocuments);
    let imgs: Array<{
      id: number;
      imgFrom: string;
      reqId: number;
      status: number;
      // tradeId: number;
      type: "tapal" | "receipt" | "other";
      url: string;
      addedBy: number;
      typeId: number;
    }> = [];
    let crops: Array<TCrop> = [];
    if (selectedCrop != null) {
      crops.push(selectedCrop);
    }
    if (details?.tradeMode == "") {
      toast.error("Please select Trade mode");
      return;
    }
    if (selectedPartner == null) {
      toast.error("Please select Destination Trader");
      return;
    }
    if (selectedCrop != null && selectedCrop?.cropName == "") {
      toast.error("Please add Crop");
      return;
    }
    if (selectedDate == "") {
      toast.error("Please select Pickup Date");
      return;
    }

    // if (selectedCrop != null && selectedCrop?.qty == 0) {
    //   toast.error("Please enter Quantity");
    //   return;
    // }
    if (selectedCrop != null && selectedCrop?.weight == 0) {
      toast.error("Please enter Weight");
      return;
    }
    if (
      selectedCrop != null &&
      details?.tradeMode == "ORDER" &&
      selectedCrop?.rate == 0
    ) {
      toast.error("Please enter Rate");
      return;
    }
    // if (details?.freightAmt == "") {
    //   toast.error("Please enter Freight Amount");
    //   return;
    // }
    if (details?.freightAmt! != "") {
      if (Number(details?.freightAdv) > Number(details?.freightAmt)) {
        toast.error("Freight Advance should be less than the Freight Amount");
        return;
      }
    }
    if (
      (details?.cmdityCost == "" || details?.cmdityCost == 0) &&
      details?.tradeMode == "ORDER"
    ) {
      toast.error("Please enter Commodity Cost ");
      return;
    }
    if (
      (details?.invAmt == "" || details?.invAmt == 0) &&
      details?.tradeMode == "ORDER"
    ) {
      toast.error("Please enter Invoice Amount");
      return;
    }
    if (tapalInvoice.length === 0) {
      toast.error("Please upload atleast one image for Tapal/Invoice");
      // setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    // if (details?.srcVehNum == "") {
    //   toast.error("Please enter Truck number");
    //   return;
    // }
    // if (
    //   selectedCrop != null &&
    //   selectedCrop?.rate == 0 &&
    //   tradeTypeMode == "ORDER"
    // ) {
    //   toast.error("Please enter Rate");
    //   return;
    // }
    // if (details?.unitType == "") {
    //   toast.error("Please select Unit Type ");
    //   return;
    // }
    // if (details?.qty == 0) {
    //   toast.error("Please enter Quantity");
    //   return;
    // }
    if (images.length > 0) {
      if (tapalInvoice.length > 0) {
        for (let file of tapalInvoice) {
          imgs.push({
            id: 0,
            imgFrom: "",
            reqId: 0,
            status: 1,
            // tradeId: 0,
            type: "tapal",
            url: file.url,
            addedBy: adminId,
            typeId: 0,
          });
        }
      }
      if (receipts.length > 0) {
        for (let file of receipts) {
          imgs.push({
            id: 0,
            imgFrom: "",
            reqId: 0,
            status: 1,
            // tradeId: 0,
            type: "receipt",
            url: file.url,
            addedBy: adminId,
            typeId: 0,
          });
        }
      }
      if (otherDocuments.length > 0) {
        for (let file of otherDocuments) {
          imgs.push({
            id: 0,
            imgFrom: "",
            reqId: 0,
            status: 1,
            // tradeId: 0,
            type: "other",
            url: file.url,
            addedBy: adminId,
            typeId: 0,
          });
        }
      }
    }
    try {
      let postBody = {
        adminId: adminId,
        cmdityCost: details?.tradeMode == "ORDER" ? details?.cmdityCost : "",
        comments: details?.comments || "",
        date: moment(selectedDate).format("YYYY-MM-DD"),
        destBsnId: 0,
        destName:
          type == "SUPPLY" ? selectedPartner?.tradeName : details?.traderName,
        destPartyId:
          type == "SUPPLY" ? selectedPartner?.traderId : details?.traderId,
        freightAdv: details?.freightAdv,
        freightAmt: details?.freightAmt,
        invAmt: details?.invAmt,
        lineItems: crops,
        srcBsnId: 0,
        srcName:
          type == "SUPPLY" ? details?.traderName : selectedPartner?.tradeName,
        srcPartyId:
          type == "SUPPLY" ? details?.traderId : selectedPartner?.traderId,
        srcVehNum: details?.srcVehNum,
        supDmdId: details?.id,
        tradeMode: details?.tradeMode,
        traderId:
          type == "SUPPLY" ? details?.traderId : selectedPartner?.traderId,
        type: type,
        srcTradeStatus: "PENDING",
        images: imgs,
        proposalId: fromPropsal ? traderContactInfo?.id : 0,
        timeStamp: moment().format("YYYY-MM-DDTHH:mm:ss.SSSz"),
      };

      await handlePostCall<any>({
        URL: `mandi/trade-portal/trades?type=ADMIN`,
        apiParams: postBody,
      })
        .then(({ data, status }) => {
          toast.success(status.message);
          navigate(`/trade-detail/${details?.traderId}/${data}`);
          modalClose(false);
          dispatch(updateActiveTradeTab("OPEN"));
        });
    } catch (ex) {}
  };
  return {
    details,
    handleDateSelection,
    setDetails,
    submit,
    selectedDate,
    setSeletedPartner,
    setSeletedSrc,
    selectedCrop,
    selectedTrader,
    partyData,
    handelSearch,
    clearSearch,
    searchText,
    traderData,
    selectedPartner,
    selectedSrc,
    setTraderData,
    traderContactInfo,
    tapalInvoice,
    receipts,
    otherDocuments,
    onUploadImage,
    onRemoveImage,
    setTapalInvoice,
    setReceipts,
    setOtherDocuments,
  };
};
export default useCreateTradeSupplyDEmand;
