export type TQuantityUnit =
  | "CRATES"
  | "BAGS"
  | "SACS"
  | "BOXES"
  | "KGS"
  | "LOADS"
  | "PIECES";
export type TRateType =
  | TQuantityUnit
  | "RATE_PER_KG"
  | "RATE_PER_UNIT"
  | "KGS"
  | "RATE_PER_PACKAGE";
export type TPartnerInfo = {
  profilePic: string;
  name: string;
  shortName?: string;
  partnerType?: string | "";
  partnerId?: string | number;
  partnerMobile?: string;
  trader?: string | boolean;
  partyId?: number;
  partnetAddress?: string;
  date?: string;
  seqId?: number;
  partyName?: string;
  fromLoansParty?: boolean;
  distanceFrom?: boolean;
  distance?: string;
};
export type TDestinationPartner = {
  addrLine: string;
  altMobile: string;
  city: string;
  traderId: number;
  marketName: string;
  mobile: string;
  name: string;
  relMgr: string;
  rmMobile: string;
  shopNum: string;
  status: string;
  tradeName: string;
  type?: "DEST" | "SOURCE";
};
export type TTradeDetails = {
  tradeId: number;
  srcPartyId: number;
  destPartyId: number;
  date: string;
  timeStamp: string;
  tradeStatus: string;
  images: [];
  destName: string;
  destTradeName: string;
  destMobile: string;
  destAltMobile: string;
  destShopNum: string;
  destMarketName: string;
  destCity: string;
  destAddrLine: string;
  traderSeq?: number;
  srcTradeName: string;
  srcName: string;
  srcMobile: string;
  paidAmt: number;
  arlTime: string;
  pattiAmt: number | "";
  totalPayable: number | "";
  tradeMode: string;
  invAmt: number | "";
};
export type TTrade = {
  account: null | string;
  arlDate: null | string;
  arlTime: null | string;
  comm: number;
  date: null | string;
  destAddrLine: string;
  destAltMobile: null | string;
  destCity: string;
  destMarketName: string;
  destMobile: string;
  destName: string;
  destPartyId: number;
  destShopNum: string;
  destTradeName: string;
  discount: number;
  destDisc: number | "";
  srcDisc: number | "";
  freightPaid: number;
  frieghtAmt: number;
  ifsc: null | string;
  images: Array<{
    id: number;
    reqId: number;
    tradeId: number;
    imgFrom: string;
    status: 1;
    type: string;
    url: string;
  }>;
  invAmt: number;
  inwardPmt: number;
  lbrCharges: number;
  misc: number;
  outwardPmt: number;
  pattiAmt: number;
  payableTo: unknown;
  srcMobile: string;
  srcName: string | null;
  srcPartyId: number;
  srcTradeName: string | null;
  timeStamp: string;
  totalPayable: number;
  tradeId: number;
  tradeMode: "ORDER" | "COMMISSION";
  tradeStatus: string;
  traderSeq: number;
  srcAddrLine: string;
  srcCity: string;
  srcMarketName: string;
  srcTradeStatus: string;
  srcDist: string;
  srcPincode: string;
  destDist: string;
  destPincode: string;
  srcState: string;
  destState: string;
  updatedOn: string;
  destTradeStatus: string;
  closedDate: string;
};
export type TTradeDocument = {
  id: number;
  reqId: number;
  tradeId: number;
  imgFrom: string;
  url: string;
  type: "tapal" | "receipt" | "other";
  status: number;
  docType: "image" | "pdf";
  typeId: number;
};
export type TTradeStatus =
  | "PENDING"
  | "ACCEPTED"
  | "ARL_CONFIRMED"
  | "PATTI_FINALIZED"
  | "PMT_COMPLETED"
  | "PMT_PENDING"
  | "PAYMENT"
  | "PMT_COMPLETED"
  | "CANCEL"
  | "CLOSED";
export type TTradeCompleteDetai = {
  date: string;
  destAddrLine: string;
  destAltMobile: string;
  destEsign: string;
  srcEsign: string;
  gstAmountSrc: number;
  acceptTradeDate: string;
  destCity: string;
  destMarketName: string;
  destMobile: string;
  destName: string;
  destPartyId: number;
  destShopNum: string;
  destTradeName: string;
  images: Array<TTradeDocument>;
  invoices: TTradeDocument[];
  receipts: TTradeDocument[];
  otherDocumets: TTradeDocument[];
  srcAddrLine: string;
  srcAltMobile: string;
  srcCity: string;
  srcMarketName: string;
  srcMobile: string;
  srcName: string;
  srcPartyId: number;
  srcShopNum: string;
  srcTradeName: string;
  timeStamp: string;
  tradeId: number;
  traderId: number;
  tradeStatus: TTradeStatus;
  selectedTrader: any;
  destRmName: string;
  destRmMobile: string;
  srcRmName: string;
  srcRmMobile: string;
  traderSeq: number;
  selectedCrop: TCrop[];
  freightAmt: number | "";
  lineItems: TCrop[];
  totalPayable: number | "";
  arlDate: string;
  comm: number | "";
  pattiAmt: number | "";
  lbrCharges: number | "";
  discount: number | "";
  destDisc: number | "";
  srcDisc: number | "";
  misc: number | "";
  payment: number | "";
  paidDate: string;
  arlTime: string;
  paidAmt: number;
  balPayable: number;
  progress: TProgress[];
  arlConf: {
    date: string;
    freightAmt: number | "";
    images: Array<TTradeDocument>;
    time: TTime;
  };
  pattiFinal: {
    freightAmt: number | "";
    images: Array<TTradeDocument>;
    comm: number | "";
    totalPayable: number | "";
    pattiAmt: number | "";
    lbrCharges: number | "";
    discount: number | "";
    misc: number | "";
  };
  pmtDtls: {
    account: string;
    ifsc: string;
    images: Array<TTradeDocument>;
    payableTo: string;
    payment: number | "";
    totalPayable: number | "";
  };
  invoiceAmt: number | "";
  arrivalDocs: TTradeDocument[];
  arrivalWeightDocs: TTradeDocument[];
  pattiDocs: TTradeDocument[];
  paymentDocs: TTradeDocument[];
  tradeMode: string;
  invAmt: number | "";
  paymentType: string;
  tradeAcceptedTime: string;
  inwardPmt: number;
  outwardPmt: number;
  freightAdv: number | "";
  tradecomments: string;
  freightPaid: number | "";
  weightAtSrc: number | "";
  destWeight: number | "";
  destWastage: number | "";
  netWeight: number | "";
  arlComments: string;
  balFreightAmt: number | "";
  arrivalWeightbridgeDocs: TTradeDocument[];
  reqAdvanceDocuments: TTradeDocument[];
  pattiDate: string;
  advancePaid: number;
  advanceRcvd: number;
  pattiComments: string;
  commPerVal: number | "";
  finalPayable: number;
  srcPf: number | "";
  paymentMode: string | number;
  paymentComments: string;
  moistureLoss: number;
  avgRate: number;
  pmtHistory: TPaymentHistory[];
  totalReceivable: number;
  destDelayCharges: number;
  destPmtTerm: string;
  srcPmtTerm: string;
  dueDate: string;
  srcAccount: string;
  srcAccountHoler: string;
  srcBankName: string;
  srcIfsc: string;
  platformFeeVal: number | "";
  platformFeeTotal: number | "";
  delayedChargesVal: number | "";
  delayedChargesPer: number | "";
  paymentTerms: number | "";
  rate: number | "";
  finalRate: number | "";
  finalInvoice: number | "";
  destRate: number | "";
  destPf: number | "";
  srcDis: number | "";
  destDis: number | "";
  tradeType: string | "";
  destPfPer: number | "";
  srcPfPer: number | "";
  company: string | "";
  delayedDays: string;
  destPfDisc: number;
  srcPfDisc: number;
  srcPmtHistory: TPaymentHistory[];
  destPmtHistory: TPaymentHistory[];
  srcVehNum: string | "";
  destVehNum: string | "";
  advanceLimit: number | "";
  reqAdv: TReqAdv;
  commodityCost: number | "";
  cmdityCost: number | "";
  srcTradeStatus: string;
  finalReceivable: number;
  srcDist: string;
  srcPincode: string;
  destDist: string;
  destPincode: string;
  srcState: string;
  destState: string;
  updatedOn: string;
  unitPriceSrc: number | string;
  totalAmountSrc: number;
  payableAfterGstSrc: number;
  srcTax: string;
  destTax: string;
  destInvId: string;
  srcInvId: string;
  srcPfId: string;
  destPfId: string;
  gstAmountDest: number;
  unitPriceDest: number | string;
  totalAmountDest: number;
  payableAfterGstDest: number;
  destTradeStatus: TTradeStatus;
  payAcc: TPayoutDetails;
  driverName: string;
  driverMobile: string;
  containerId: string;
  closedDate: string;
};
export type TReqAdv = {
  id: number;
  tradeId: number;
  advLimit: number;
  requestAdv: number;
  advDate: string;
  comments: string;
  advDisb: number;
  advApproved: number;
  disbDate: string;
  status: number;
  srcPartyId: number;
  mobile: string;
  srcName: string;
  images: TTradeDocument[];
  verified: number;
  imgs: TTradeDocument[];
  timeStamp: string;
};
export type TCrop = {
  baseName: string;
  cropId: number;
  cropName: string;
  imageUrl: string;
  type: string;
  units: string;
  varietyName: string;
  qtyUnit: string;
  rateType: string;
  qty: number | "";
  weight: number | "";
  freightAmt: number | "";
  status: number;
  tradeId: number;
  weightUnit: string;
  id: number;
  destWeight: number | "";
  addWastage: number | "";
  destRate: number | "";
  rate: number | "";
  unitType: string;
  destBayerWeight?: number | "";
  bayerWeight?: number | "";
};
export type TBillCrop = TCrop & {
  cropSufx: string;
  pkgUnit: string;
  qtyUnit: string;
  rateType: string;
  qty: number | "";
  weight: number | "";
  wastage: number | "";
  rate: number | "";
  total: number;
  status: number;
  id: number;
};

export type TTime = {
  hour: number;
  minute: number;
  nano: number;
  second: number;
};
export type TProgress = {
  date: string;
  id: number;
  progState: string;
  tradeId: number;
  comments: string;
};

export type TTradeUpdateDetais = {
  freightAmt: number | "";
  totalPayable: number | "";
  arlDate: string;
  otherDocumets: TTradeDocument[];
  arlConf: {
    date: string;
    freightAmt: number | "";
    images: Array<TTradeDocument>;
    time: TTime;
  };
  pattiFinal: {
    freightAmt: number | "";
    images: Array<TTradeDocument>;
    comm: number | "";
    totalPayable: number | "";
    pattiAmt: number | "";
    lbrCharges: number | "";
    discount: number | "";
    misc: number | "";
  };
  pmtDtls: {
    account: string;
    ifsc: string;
    images: Array<TTradeDocument>;
    payableTo: string;
    payment: number | "";
    totalPayable: number | "";
  };
};

export type TProgressStatusObj = {
  tradesProgres: TProgressStatus;
};
export type TProgressStatus = {
  tradeDetailsStatus: boolean;
  arrivalDetailsStatus: boolean;
  pattiDetailsStatus: boolean;
  paymentDetailsStatus: boolean;
  acceptedDetailsStatus: boolean;
};
export type TPaymentHistory = {
  id: number;
  reqId: number;
  pmtMode: string;
  date: string;
  amount: number;
  balance: number;
  comments: string;
  pmtType: string;
  source: string;
  tradeId: number;
  traderId: number;
  images: Array<TTradeDocument>;
  imgs: Array<TTradeDocument>;
  inOutWard: string;
  typeId: number;
  payoutId: string;
  refId: string;
  fundAccId: string;
  pmtStatus: string;
  pmtReceiptDate: string;
  desc: string;
  utr: string;
  createdOn: string;
  destTradeNAme: string;
  paymentType: string;
  pmtId: number;
  url: string;
  rejectedReason: string;
  note1: string;
  note2: string;
  fees: number;
  tax: number;
  addtComments: string;
};
export type TTrader = {
  tradeName: string;
  url: string;
  traderId: number;
  tradeType: string;
  tradeStatus: string;
  name: string;
  mobile: string;
  altMobile: string;
  status: string;
  traderName: string;
  tradeLimit: number;
  totalReceivable: number;
  srcPartyId: number;
  totalPayable: number;
  destPartyId: number;
  profilePic: string;
  marketName: string;
  pdcStatus: boolean;
  traderProgress: string;
  amtDetails: {
    paidReceived: number;
    traderId: number;
    paidAmount: number;
    tradeType: string;
  };
};
export type TTraderId = {
  traderName: string;
  traderId: number;
  mobile: string;
  tradeName: string;
  businessType: string | number;
  onBorded: string;
  profilePic: string;
  status: string;
  tradeType: string;
  tradeStatus: string;
  name: string;
  altMobile: string;
  relMgr: string;
  rmMobile: string;
  marketName: string;
  tradeScode: number | string;
  pincode: string;
  state: string;
  dist: string;
  addressLine: string;
  ors: number | string;
  comments: string;
  externelComments: string;
  pendingDocs: string;
  regType: string;
  shopNum: number | string;
  tradeLimit: number | string;
  relMgrId: number;
  marketId: number;
  tradeDesc: string;
  kycDocs: TTraderKycDoc[];
  rmProfilePic: string;
  accountNum: string | number;
  accountHolder: string;
  bankName: string;
  emailId: string;
  ifsc: number | string;
  renewalDate: string;
  traderProgress: number;
  trades: Array<{
    traderId: number;
    tradeId: number;
    totalReceivable: number;
    inwardPmt: number;
    totalPayable: number;
    url: string;
    tradeMode: string;
    timeStamp: string;
    tradeStatus: string;
    destPartyId: number;
    outwardPmt: number;
    srcPf: number;
    destPf: number;
    mobile: string;
    tradeName: string;
    name: string;
    traderSeq: number | string;
    srcTradeStatus: string;
    destTradeStatus: string;
  }>;
  bsnAcc: Array<{
    addrLine: string;
    city: string;
    createdOn: string;
    id: number;
    marketId: number;
    marketName: string;
    pincode: number;
    shopNum: string;
    status: number;
    tradeBusiStatus: string;
    tradeDesc: string;
    tradeName: string;
    traderId: number;
    state: string;
  }>;
  payAcc: Array<TPayoutDetails>;
  chequeAmt: number | "";
  chequeExpDate: string;
  chequeStatus: string | number;
};

export type TTraderKycDoc = {
  id: number;
  reqId: number;
  tradeId: number;
  imgFrom: string;
  url: string;
  type: string;
  status: number;
  docType: "image" | "pdf";
  typeId: number;
  appId: number;
  pfId: number;
};

export type TLoanKycDoc = {
  id: number;
  reqId: number;
  tradeId: number;
  imgFrom: string;
  docUrl: string;
  type: "image" | "pdf";
  status: number;
  docType: string;
  typeId: number;
  appId: number;
  pfId: number;
};
export type RMdetails = {
  id: number;
  name: string;
  mobile: number;
  empId: number;
  status: number;
  region: string;
  emailId: string | number;
  profilePic: string;
};

export type MarketDetails = {
  marketId: number;
  marketName: string;
};

export type TraderStatus = {
  id: number;
  status: string;
};
export type TTableHeader = Array<{
  label: string;
  colWidth: number;
  billcrreationMode?: string[];
  data?: Array<{ label: string; colWidth: number }>;
}>;

export type TBorrowers = {
  addressLine: string;
  businessName: string;
  availableLimit: number;
  bwrStatus: string;
  cpId: number;
  eligibleLimit: number;
  fullName: string;
  marketName: string;
  mobile: string;
  overDueAmt: number;
  pfId: number;
  platform: string;
  totalLoans: number;
  beneficiaries: Array<{
    mobile: string;
    benfiStatus: string;
    appId: number;
    address: string;
    partyId: number;
    partyName: string;
    partyType: string;
    totalLoans: number;
    trader: boolean;
    overDueAmt: number;
    benId: number;
  }>;
};
export type TBorrowerDetails = {
  activeLoans: number;
  addressLine: string;
  availableLimit: number;
  utilisedLimit: number;
  busAltMobile: string;
  busMobile: string;
  businessName: string;
  businessType: string;
  buyBillAmt: number;
  buyBills: number;
  comments: string;
  cpId: number;
  dist: string;
  eligibleLimit: number | "";
  fullName: string;
  intrestRate: number | "";
  kycDocs: TCashKycDoc[];
  panDocs: TCashKycDoc[];
  bankDocs: TCashKycDoc[];
  licenceDocs: TCashKycDoc[];
  itrDocs: TCashKycDoc[];
  marketName: string;
  mobile: string;
  onboardedOn: string;
  onoScore: number | "";
  overDueAmt: number;
  partnerDetails: PartnerDetails[];
  pfId: number;
  pincode: string;
  platform: string;
  rmId: number;
  rmMobile: string;
  rmName: string;
  rmProfile: string;
  sellBillAmt: number;
  sellBills: number;
  shopNum: string;
  state: string;
  status: string;
  tenure: number;
  totalLoans: number;
  totalBills: number;
  totalBillsAmount: number;
  totalPartners: number;
  partnerObjects: {
    seller: number;
    buyer: number;
    transporter: number;
    labor: number;
  };
  tenureString: string | "";
  platformFee: number | "";
  platformFeeTotal: number | "";
  renewDate: string;
  activeBenifs: number;
  aadhar: string;
  adhrName: string;
  adhrMobile: number;
  photo: string;
  payAcc: Array<TPayoutDetails>;
  benId: number;
};

export type PartnerDetails = {
  total: number;
  partyType: string;
};

export type TCashKycDoc = {
  id: number;
  reqId: number;
  tradeId: number;
  imgFrom: string;
  url: string;
  docType: string;
  status: number;
  type: "image" | "pdf";
  typeId: number;
  appId: number;
  pfId: number;
  docUrl: string;
  reportUrl: string;
  reportId: string;
};
export type TBeneficiaryDetails = {
  aadhar: string;
  activeLoans: number;
  addressLine: string;
  appId: number;
  appStatus: string;
  dist: string;
  farmerId: number;
  farmerName: string;
  intrestRate: number;
  kycDocs: TCashKycDoc[];
  mobile: string;
  openingBal: number;
  openingBalDate: string;
  partyType: string;
  pfFee: number;
  pincode: string;
  profilePic: string;
  renewDate: string;
  rmId: number;
  rmMobile: string;
  rmName: string;
  rmProfile: string;
  shortName: string;
  state: string;
  tenure: number;
  totalLoans: number;
  panDocs: TCashKycDoc[];
  bankDocs: TCashKycDoc[];
  comments: string;
  onoScore: number | "";
  accHolder: string;
  accNum: string;
  bankName: string;
  ifscCode: string;
  confirmAccNum: number | number;
  adhrName: string;
  adhrMobile: number;
  photo: string;
  payAcc: Array<TPayoutDetails>;
  benId: number;
};
export type TLoansSmartboard = {
  disbAmt: number;
  recoveredAmt: number;
  outstandingAmt: number;
  overDueAmt: number;
  borrowers: number;
  benifacaries: number;
  disbursements: number;
  delayDetails: TDelayDetails[];
  delayLoans: TDelayLoans[];
};

export type TDelayLoans = {
  partyName: string;
  shortCode: string;
  partyType: string;
  trader: boolean;
  mobile: string;
  loanApplnId: number;
  loanAmount: number;
  dueDate: string;
  cpId: number;
};
export type TDelayDetails = {
  pmtDays: string;
  loans: number;
  amount: number;
};

export type TLoanApplications = {
  bwrMobile: string;
  bwrName: string;
  bwrStatus: string;
  cpId: number;
  disbAmt: number;
  outstandingAmt: number;
  overDueAmt: number;
  parties: TApplicationParties[];
  recoveredAmt: number;
  addressLine: string;
  availableLimit: number;
  eligibleLimit: number;
  onoScore: number;
  utilisedLimit: number;
};
export type TApplicationParties = {
  addressLine: string;
  cpId: number;
  disbDate: string;
  disbId: number;
  disbAmt: number;
  disbStatus: string;
  invAmt: number;
  mobile: string;
  partyId: number;
  partyName: string;
  partyType: string;
  profilePic: string;
  repayAmt: number;
  shortCode: string;
  tradeAdv: number;
  trader: boolean;
  dueDate: string;
};
export type TApplicationViewDetails = {
  cpId: number;
  bwrName: string;
  bwrMobile: string;
  bwrStatus: string;
  bwrScore: number;
  partyScore: number;
  type: string | number;
  totalLoans: number;
  activeLoans: number;
  onboardedOn: string;
  interestRate: string;
  firstBillCreateDate: string;
  disbAmt: number;
  recoveredAmt: number;
  overDueAmt: number;
  dueDate: string;
  eligibleLimit: number;
  availableLimit: number;
  loanAmount: number;
  utilisedLimit: number;
  outStanding: number;
  disbId: number;
  vintage: number;
  otherPartners: number;
  disbStatus: string;
  disbDate: string;
  invAmt: number;
  tradeAdv: number;
  repayAmt: number;
  partyId: number;
  partyName: string;
  shortCode: string;
  profilePic: string;
  partyType: string;
  partyMobile: string;
  partyOb: number;
  partyCreatedOn: string;
  partyObdate: string;
  partyCreatedDate: string;
  trader: boolean;
  addressLine: string;
  partyStatus: string;
  firstBillDate: string;
  totalBills: number;
  paidRcvd: number;
  tobePaidRcvd: number;
  delayDetails: TDelayDetails[];
  bills: TApplicationBills[];
  disbPrgs: TDisbProgress[];
  cashHistory: TCashHistory[];
  monthlyData: TMonthlyLoansData[];
  accHolder: string;
  accNum: string;
  bankName: string;
  ifscCode: string;
  intrestRate: number;
  pfFee: number;
  tenure: number;
  remarks: string;
  pfId: number;
  totalBillAmt: number;
  kycDocs: TLoanKycDoc[];
  loanSmry: {
    loanId: number;
    loanSeq: number;
    disbAmt: number;
    date: string;
    intrestRate: number;
    tenure: number;
    dueOn: string;
    repayAmt: number;
    repayHist: TRePayHist[];
    type: string;
    createdOn: string;
  };
  loanType: string;
  payoutAcc: TPayoutDetails;
  payoutRes: TPayoutResponse;
};
export type TPayoutResponse = {
  id: number;
  disbId: number;
  status: string;
  disbAmt: number;
  utr: string | "";
};
export type TRePayHist = {
  id: number;
  date: string;
  amount: number;
  balance: number;
  mode: string;
  createdOn: string | number;
};
export type TMonthlyLoansData = {
  monthYear: string;
  totalBills: string;
};
export type TApplicationBills = {
  billId: number;
  totalPayble: number;
  cashPaid: number;
  caBSeq: number;
  caId: number;
  transporterId: number;
  pmtStatus: number;
  billStatus: string;
  billDate: string;
};
export type TDisbProgress = {
  adminId: number;
  pfId: number;
  cpId: number;
  farmerId: number;
  disbId: number;
  progStatus: string;
  date: string;
  createdOn: string;
  disbAmt: number;
  tenure: number;
  intrestRate: number;
  remarks: string;
};

export type TCashHistory = {
  loanId: number;
  disbAmt: number;
  repaidAmt: number;
  overDueAmt: number;
  dueDate: string;
  loanStatus: string;
  loanSeq: string;
  date: string;
};

export type TBillsInfo = {
  actCreatedOn: string;
  billDate: string;
  timeStamp: string;
  billId: number;
  farmerId: number;
  partyType: string;
  farmerName: string;
  farmerMobile: string;
  farmerAddress: string;
  farmerProfilePic: string;
  shortName: string;
  billStatus: string;
  grossTotal: number;
  totalPayables: number;
  actualPaybles: number;
  comm: number;
  rtComm: number;
  lotCode: null;
  less: boolean;
  labourCharges: number;
  transportation: number;
  govtLevies: number;
  advance: number;
  misc: number;
  rent: number;
  cashPaid: number;
  cashPaidCmnt: string;
  transporterId: number;
  transporterName: string;
  transporterAddress: string;
  transporterMobile: string;
  mandiFee: number;
  outStBal: number;
  finalLedgerBal: number;
  finalOutStBal: number;
  comments: string;
  caBSeq: number;
  commIncluded: boolean;
  rtCommIncluded: boolean;
  commShown: boolean;
  lotId: string;
  mnLotId: string;
  viaSmartChart: boolean;
  addBill: number;
  billStage: number;
  wastage: number;
  groupId: number;
  trader: boolean;
  profilePic: string;
  paid: boolean;
  transportationPaid: boolean;
  advBal: number;
  billAmt: number;
  lgBillAmt: number;
  paidAmt: number;
  pmtStatus: number;
  formSettings: string;
  customFields: TBillCustomFields[];
  version: number;
  lineItems: TBillCropItem[];
  vehNum: string;
  containerId: string;
  driverName: string;
  driverMobile: string;
};

export type TBillCropItem = {
  id: number;
  buyerName: string;
  cropId: number;
  cropName: string;
  imageUrl: string;
  qty: number;
  rate: number;
  rateType: TRateType;
  qtyUnit: TQuantityUnit;
  weight: number;
  wastage: number;
  total: number;
  subLotId: number;
  mnLotId: string;
  mnSubLotId: string;
  pkgUnit: string;
  cropSufx: string;
  totalNetWt: number;
  dfltTrnsRate: number;
  dfltLbrRate: number;
  dfltRentRate: number;
  serNo: number;
  bags: TBillBag[];
};
export type TBillCustomFields = {
  field: string;
  fieldName: string;
  less: true;
  fee: number;
  comments: string;
  fieldType: string;
  qty: number;
};

export type TBillBag = {
  id: number;
  status: number;
  total: number;
  totalWeight: number;
  wastage: number;
  weight: number;
  netWeight: number;
};
export type TStoreDisbData = {
  billIds: [0];
  comment: string;
  cpId: number;
  createdOn: string;
  date: string;
  disbAmt: number;
  disbDate: string;
  disbId: number;
  farmerId: number;
  imgs: [
    {
      addedBy: number;
      appId: number;
      category: string;
      id: number;
      imgFrom: string;
      pfId: number;
      platform: string;
      reqId: number;
      reviewStatus: string;
      status: number;
      type: string;
      typeId: number;
      url: string;
    }
  ];
  intrestRate: number;
  pfId: number;
  pmtMode: string;
  progStatus: string;
  remarks: string;
  tenure: number;
  updatedOn: string;
};

export type TSmartBoard = {
  benifacaries: number;
  borrowers: number;
  beneficiaries: number;
  delayDetails: [
    {
      amount: number;
      loans: number;
      pmtDays: string;
    }
  ];
  delayLoans: [
    {
      cpId: number;
      dueDate: string;
      loanAmount: number;
      loanApplnId: string;
      mobile: string;
      partyName: string;
      partyType: string;
      shortCode: string;
      trader: boolean;
      loanSeq: number | string;
      partyId: number;
      disbId: number;
    }
  ];
  disbAmt: number;
  disbursements: number;
  outstandingAmt: number;
  overDueAmt: number;
  recoveredAmt: number;
};

export type TBillView = {
  ownerName: string;
  contactNum: number;
  businessId: number;
  businessName: string;
  shortCode: string;
  businessType: string;
  shopNum: string | number;
  contactName: string;
  mobile: number;
  altMobile: number;
  imageUrl: string;
  marketId: number;
  marketName: string;
  addressLine: string;
  dist: string;
  state: string;
  pincode: number;
  city: string;
  other: boolean;
  buyPdfDetails: {
    caId: number;
    colorTheme: string | number;
    logoUrl: string;
    signatureUrl: string;
    mandiName: string;
    userLabel: string;
    billTypeLabel: string;
    type: string;
  };
};
export type TPdfSettings = {
  colorTheme: string;
  drawnSign: boolean;
  header: {
    billTypeLabel: string;
    userLabel: string;
  };
  localizationEnabled: boolean;
  logoUrl: string;
  mandiName: string;
  signatureUrl: string;
  type: "BUY_BILL";
};

export type TSupplyDemandReq = {
  addressLine: string;
  altMobile: string;
  avlDate: string;
  pickupDate: string;
  city: string;
  createdOn: string;
  cropId: number;
  mlcMobile: string;
  cropName: string;
  cropUrl: string;
  expDate: string;
  expRate: number | "";
  expTotal: number;
  id: number;
  imgs: TSupplyDemandCropImages[];
  latitude: number;
  location: string;
  longitude: number;
  mobile: string;
  offerValidity: string;
  validity: string;
  pincode: string;
  profilePic: string;
  proposals: TProposals[];
  propsCount: number;
  qty: number | "";
  quality: string | number;
  state: string;
  status: string;
  timeAgo: string;
  traderId: number;
  traderName: string;
  unitType: string | number | "";
  cmdityCost: number | "" | 0;
  freightAdv: number | "" | 0;
  freightAmt: number | "" | 0;
  balFreight: number | "" | 0;
  invAmt: number | "" | 0;
  cancelDate: string;
  cancelReason: string;
  comments: string;
  date: string;
  destBsnId: number;
  destName: string;
  destPartyId: number;
  destPf: number;
  destTradeStatus: string;

  lineItems: [
    {
      add_wastage?: number;
      cropId: number;
      cropName: string;
      destRate: number;
      destWeight: number;
      id: number;
      imageUrl: string;
      qty: number | "";
      qtyUnit: string;
      rate: number | "";
      status: number;
      tradeId: number;
      weight: number | "";
      weightUnit: string;
    }
  ];
  srcBsnId: number;
  srcName: string;
  srcPartyId: number;
  srcPf: number;
  srcTradeStatus: string;
  srcVehNum: string;
  supDmdId: number;
  timeStamp: string;
  tradeId: number;
  tradeMode: string | number;
  type: string;
  tradeType: string;
  tradeDtls: {
    addrLine: string;
    city: string;
    date: string;
    mobile: string;
    pincode: string;
    rate: number;
    state: string;
    tradeId: number;
    traderId: number;
    traderName: string;
    traderSeq: number;
    profilePic: string;
  };
  traderNotifns: TTraderNotifications[];
};
export type TTraderNotifications = {
  addrLine: string;
  altMobile: string;
  city: string;
  createdOn: string;
  id: number;
  mobile: string;
  notifStatus: number;
  pincode: string;
  reqId: number;
  state: string;
  status: string;
  timeAgo: string;
  tradeType: string;
  traderId: number;
  traderName: string;
  type: string;
};
export type TProposals = {
  addrLine: string;
  addressLine: string;
  altMobile: string;
  avlDate: string;
  createdOn: string;
  distance: number;
  expDate: string;
  id: number;
  tradeDtls: {
    addrLine: string;
    city: string;
    date: string;
    mobile: string;
    pincode: string;
    rate: number;
    state: string;
    tradeId: number;
    traderId: number;
    traderName: string;
    traderSeq: number;
  };
  imgs: TSupplyDemandCropImages[];
  latitude: number;
  longitude: number;
  mobile: string;
  offerValidity: string;
  pincode: string;
  prgStatus: string;
  profilePic: string;
  rate: number | "";
  reqId: number;
  state: string;
  timeAgo: string;
  traderId: number;
  traderName: string;
  type: string;
  validity: string;
  tradeType: string;
};
export type TSupplyDemandCropImages = {
  addedBy: number;
  appId: number;
  category: string;
  id: number;
  imgFrom: string;
  pfId: number;
  platform: string;
  reqId: number;
  reviewStatus: string;
  status: number;
  type: string;
  typeId: number;
  url: string;
};
export type TCropSupplyDemand = {
  cropId: number;
  cropName: string;
  cropUrl: string;
};

export type TAddPayoutDetails = {
  traderId: number;
  accountHolder: string;
  accountNumber: string;
  bankName: string;
  ifscCode: string;
  status: string;
  accountType: string;
  traderType: string;
  contactNumber: string;
  emailAddress: string;
  note1: string;
  note2: string;
  isApiCall: boolean;
  type: string;
};

export enum TPayoutFields {
  accountHolder,
  accountNumber,
  bankName,
  ifscCode,
  status,
  accountType,
  traderType,
  contactNumber,
  emailAddress,
  note1,
  note2,
  type,
}

// export type TPayoutDetails = {
//   title: string,
//   accountHolder: string,
//   accountNumber: string,
//   bankName: string,
//   ifscCode: string,
//   status: string,
//   accountType: string,
//   traderType: string,
//   contactNumber: string,
//   emailAddress: string,
//   customerId: string,
//   referenceId: string,
//   fundId: string,
//   createdOn: string,
// }

export type TPayoutDetails = {
  id: number;
  traderId: number;
  actHolder: string;
  actNum: string;
  ifsc: string;
  bank: string;
  rzpFa: string;
  rzpCont: string;
  mobile: string;
  email: string;
  status: number;
  createdOn: string;
  traderType: string;
  contactNumber: string;
  emailAddress: string;
  note1: string;
  note2: string;
  actType: string;
  type: string;
};

export type TPaymentNotifications = {
  amount: number;
  createdOn: string;
  destTradeNAme: string;
  paymentType: string;
  pmtId: number;
  pmtMode: string;
  tradeId: number;
  url: string;
};

export type TRecordPayoutData = {
  partyId: number | string;
  paymentDate: string;
  paidAmount: number | "";
  paymentType: string | "";
  paymentMode: string;
  comments: string;
  paymentTypeVal: string | "";
  requestAdv: number | "";
  pmtReceiptDate: string;
  desc: string;
  utr: string;
  inOutWard: string;
  fundAccId: string;
  accountNumber: string;
  otp: string;
  otpValidated: boolean;
  refId: string;
  id: number;
  availableBalance: number;
};

export type TDisbRecordPayoutData = {
  partyId: number | string;
  disbDate: string;
  paymentType: string | "";
  pmtMode: string;
  comments: string;
  paymentTypeVal: string | "";
  requestAdv: number | "";
  pmtReceiptDate: string;
  desc: string;
  utr: string;
  inOutWard: string;
  fundAccId: string;
  accountNumber: string;
  otp: string;
  otpValidated: boolean;
  refId: string;
  id: number;
  availableBalance: number;
  disbAmt: number | "";
  selectedBills: TBillsInfo[];
  tenure: number | "";
};

export type TCashReportsData = {
  amount: number;
  txDate: string;
  pmtDate: string;
  desc: string;
  payoutId: number;
  tradeId: number;
  utr: string;
  pmtMode: string;
  pmtType: string;
  creditDebit: string;
  status: string;
};

export type TOpenTradeReportsData = {
  tradeId: number;
  dispatchDate: string;
  arlDate: string;
  srcPmtTerm: number;
  destPmtTerm: number;
  srcDueDate: string;
  destDueDate: string;
  srcDelay: number;
  destDelay: number;
  netReceivable: number;
  netPayable: number;
  rcvdAmt: number;
  paidAmt: number;
  dueAmt: number;
  toBePaid: number;
};
