import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import { FaBell } from "react-icons/fa6";
import { deviceInfoContext } from "../../../context";
import logo from "../../../assets/images/mandi-logo.svg";
import DefultLogo from "../../../assets/images/single_bill.svg";
import { useSelector } from "react-redux";
import { TRootState } from "../../../store";
import getTradeStatus from "../../../helpers/getTradeStatus";
import { PanelSlider } from "../../../components/ui";
import cancelIcon from "../../../assets/images/close.svg";
import Notifications from "./Notifications";

const PageHeader: FC<{
  title?: string;
  showBackButton?: boolean;
  showTitle?: boolean;
  showUserInfo?: boolean;
  topVal?: string;
  fromScreen?: string;
  subText?: string;
  imgsrc?: string;
}> = ({
  title,
  showBackButton,
  showTitle,
  showUserInfo,
  topVal,
  fromScreen,
  subText,
}) => {
  const navigate = useNavigate();
  const { width } = useContext(deviceInfoContext);
  const [notifications, setNotifications] = useState(false);

  const { name, traderId, tradeType, loggedin } = useSelector(
    (state: TRootState) => ({
      name: "",
      traderId: "",
      tradeType: "",
      loggedin: state.auth.loggedin,
    })
  );
  const { adminId } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
  }));
  const back = () => {
    if (showTitle === true && fromScreen == "View Trade") {
      navigate("/");
    } else {
      navigate(-1);
    }
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div
        className="bg-white md:shadow-md flex items-center pr-2 font-semibold w-full sticky z-[999] md:static md:z-auto"
        style={{ height: "3.8rem", top: topVal }}
      >
        {showBackButton === true && (
          <div
            className="pr-3 pl-2 cursor-pointer h-full flex"
            onClick={() => {
              back();
            }}
          >
            <FaArrowLeft className="mt-auto mb-auto" />
          </div>
        )}
        {showTitle === true && (
          <div className="px-2 flex flex-col w-full">
            <span>{title}</span>
          </div>
        )}
        {showUserInfo === true && (
          <div className="flex justify-end items-center w-full">
            <span className="px-2 border-r-2 cursor-pointer">
              <FaBell
                className="color-primary"
                onClick={() => {
                  setNotifications(true);
                }}
              />
            </span>
            <div
              className="flex gap-2 cursor-pointer items-center ml-4"
              onClick={() => {
                navigate("/my-profile");
              }}
            >
              <img src={DefultLogo} className="w-8 h-8" />
              <div className="flex flex-col text-xs">
                <span>{name}</span>
                <span className="hidden md:block">{`User Id: ${adminId}`}</span>
              </div>
            </div>
          </div>
        )}
        {loggedin === false && (
          <span className="flex ml-auto gap-2">
            {/* <span className="px-2 border-r-2">
            <FaBell className="color-primary" />
          </span> */}
            <img src={logo} className="ml-auto w-16 px-1" />
          </span>
        )}
      </div>

      <div>
        <PanelSlider
          direction={isMobile ? "bottom_to_top" : "right_to_left"}
          open={notifications}
          onClose={() => {
            setNotifications(false);
          }}
          style={{ width: isMobile ? "100%" : "40vw" }}
        >
          <div className="py-4 px-4 border-b flex items-center justify-between">
            <div>
              <h2 className="text-base font-semibold">Notifications</h2>
            </div>
            <div>
              <img
                src={cancelIcon}
                className="cursor-pointer"
                onClick={() => {
                  setNotifications(false);
                }}
              />
            </div>
          </div>
          <div
            className="px-4 py-4 overflow-auto"
            style={{ height: "calc(100vh - 6rem)" }}
          >
            <Notifications status={true} modalClose={setNotifications} />
          </div>
        </PanelSlider>
      </div>
    </>
  );
};
export default PageHeader;
