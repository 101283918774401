import React from "react";
import classes from "./style.module.scss";

const stepsArray = [
  {
    step: 1,
    name: "step1",
  },
  {
    activeIndex: 2,
    name: "bar",
  },
  {
    step: 2,
    name: "step2",
  },
  {
    activeIndex: 3,
    name: "bar",
  },
  {
    step: 3,
    name: "step3",
  },
  {
    activeIndex: 4,
    name: "bar",
  },
  {
    step: 4,
    name: "step4",
  },
  {
    activeIndex: 5,
    name: "bar",
  },
  {
    step: 5,
    name: "step5",
  },
];

const Steps = ({ stepCount, currentStep }) => {
  return (
    <div className="flex items-center justify-center">
      {stepsArray.map((element, index) => {
        if (element.name !== "bar" && element.step <= stepCount) {
          return (
            <div
              key={element.step + index}
              className={classes.stepCnt}
              data-selected={element.step <= currentStep}
            >
              {element.name}
            </div>
          );
        }

        if (element.name === "bar" && element.activeIndex <= stepCount) {
          return (
            <div
              className={classes.stepProgressBar}
              data-active={element.activeIndex <= currentStep}
            />
          );
        }

        return null;
      })}
    </div>
  );
};

export default Steps;
