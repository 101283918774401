import { useRef, FC, useState, ChangeEvent, useEffect } from "react";
import { FaAngleDown, FaUpload, FaXmark } from "react-icons/fa6";
import { Button, FileUploader, RadioButton } from "../../components/ui";
import EmptyProfile from "../../assets/images/profile.jpg";
import { Input } from "../../components/ui";
import Checkbox from "@mui/material/Checkbox";
import Popover from "../../components/ui/popover";
import timer from "../../assets/images/timer-img.png";
import hold from "../../assets/images/hold.svg";
import reject from "../../assets/images/reject.svg";
// import blocked from "../../assets/images/blocked.svg";
import Calender from "../../components/commons/calender";
import tickmark from "../../assets/images/tickmark.png";
import blocked from "../../assets/images/blocked.svg";
import useAxiosClient from "../../hooks/useAxiosClient";
import DocumentPreview from "../../components/commons/document-preview";
import useCustomerDocs from "../../hooks/useCustomerDocs";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../store";
import SelectDate from "../../components/ui/select-date";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as $ from "jquery";
import marketImgg from "../../assets/images/Group.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FaSearch } from "react-icons/fa";
import Modal from "../../components/ui/modal";
import ModalHeader from "../../components/ui/modal/modal-header";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { setPayoutDetails, setSelectedDate } from "../../reducers/tradeSlice";
import moment from "moment";
import RelationshipMngr from "./CustomerDetails/RelationshipMngr";
import AddPayoutAccount from "./CustomerDetails/PayoutDetails/AddPayoutAccount";
import Form from "react-bootstrap/esm/Form";
import SwicthButton from "../../components/ui/switch/switch";
const label = { inputProps: { "aria-label": "Checkbox demo" } }; //checkbox kosam

const AddTrader: FC<{
  onClose: () => void;
  onRefresh?: () => void;
  mode?: "EDIT";
}> = ({ onClose, mode, onRefresh }) => {
  const { adminId, traderId, tententType, payoutDetails } = useSelector(
    (state: TRootState) => ({
      adminId: state.auth.adminId,
      traderId: state.trades.traderId,
      tententType: state.auth.userInfo?.type,
      payoutDetails: state.trades.payoutDetails,
    })
  );
  const [payoutModal, openPayoutModal] = useState(false);

  const { handlePostCall, handlePutCall } = useAxiosClient();
  // toast messages for kyc docs
  const validateDocs = (type: string) => {
    let tDocs = traderView?.kycDocs
      ? traderView?.kycDocs?.filter(
          (doc) => doc.type.includes(type) && doc.status != 0
        ).length
      : 0;
    let kycCount = kycDocs.filter((doc) => doc.type == type).length;
    return tDocs <= 0 && kycCount <= 0;
  };

  const params = useParams();

  const {
    onUploadReceiptImage,
    onRemoveReceiptImage,
    kycDocs,
    traderView,
    setTraderView,
    getTraderDetails,
    openStatus,
    selectStatus,
    selectedOption,
    ref,
    files,
    profile,
    activeButton,
    relationshipManager,
    marketDetails,
    setMarketDetails,
    setRelationshipManager,
    searchTerm,
    searchMrkt,
    traderStatus,
    setTraderStatus,
    setSearchMrkt,
    setSearchTerm,
    setActiveButton,
    setSelectedOption,
    setSelectStatus,
    setOpenStatus,
    getRmDetails,
    getMarketDetails,
    getStatusDetails,
    setFiles,
    completionStatus,
    setCompletionStatus,
    selectedCompletion,
    setSelectedCompletion,
    selectedDate,
    setSelectedDate,
    confirmAccountNum,
    setConfirmAccountNum,
    selectExpiry,
    setSelectExpiry,
    selectCheque,
    setSelectCheque,
  } = useCustomerDocs({
    traderId: parseInt(params.traderId || "0"),
    // tradeId: 0,
    // mode: false,
    // type: "kycDocs",
  });

  const onSubmit = () => {
    const pendingDocsString = checked.join(", ");
    console.log(pendingDocsString);
    const images = [];
    for (let file of kycDocs) {
      images.push({
        id: 0,
        reqId: 0,
        status: 1,
        addedBy: adminId || 0,
        type: file.type,
        url: file.url,
        category: "PERSONAL",
        platform: "TRADE",
        reviewStatus: "",
      });
    }
    let payAcc = [];
    for (let acc of traderView?.payAcc!) {
      if (acc.id <= 0) {
        payAcc.push({
          adminId: adminId,
          traderId: acc.traderId,
          traderType: acc.traderType,
          actHolder: acc.actHolder,
          actNum: acc.actNum,
          bankName: acc.bank,
          ifsc: acc.ifsc,
          contactNum: acc.contactNumber,
          email: acc.emailAddress,
          actType: acc.actType,
          notes: {
            notes_key_1: acc.note1,
            notes_key_2: acc.note2,
          },
        });
      } else {
        payAcc.push({
          id: acc.id,
          status: acc.status,
        });
      }
    }
    //edit loki velli previous ga unna image ni delete chestam/means status==0 chestam malli new one upload chestaam adhi malli techukuntunam
    if (mode === "EDIT") {
      traderView?.kycDocs.forEach((doc) => {
        if (doc.status == 0) {
          images.push({
            id: doc.id,
            status: doc.status,
          });
        }
      });
      //edit mode lo untey put else post
      handlePutCall({
        URL: `admin/trade-platform/traders/update`,
        apiParams: {
          addrLine: traderView?.addressLine,
          city: traderView?.dist,
          pincode: traderView?.pincode,
          state: traderView?.state,
          adminId: adminId,
          altMobile: traderView?.altMobile,
          kycDocs: images,
          marketId: traderView?.marketId,
          marketName: traderView?.marketName,
          mobile: traderView?.mobile,
          traderName: traderView?.traderName,
          ors: traderView?.ors,
          profilePic: profile,
          regType: selectedOption,
          relMgrId: traderView?.relMgrId,
          shopNum: traderView?.shopNum,
          comments: traderView?.comments,
          tradeLimit: traderView?.tradeLimit,
          tradeName: traderView?.tradeName,
          tradeScode: traderView?.tradeScode,
          tradeType: traderView?.tradeType,
          traderId: traderView?.traderId,
          tradeDesc: traderView?.tradeDesc,
          accountNum: traderView?.accountNum,
          accountHolder: traderView?.accountHolder,
          bankName: traderView?.bankName,
          ifsc: traderView?.ifsc,
          renewalDate: selectedDate || null,
          traderProgress: selectedCompletion,
          status: traderView?.status,
          externelComments: traderView?.externelComments,
          pendingDocs: pendingDocsString,
          emailId: traderView?.emailId,
          id: traderView?.bsnAcc[0].id || 0,
          payAcc: payAcc,
          chequeAmt: traderView?.chequeAmt || 0,
          chequeExpDate: traderView?.chequeExpDate,
          chequeStatus: traderView?.chequeStatus,
        },
      })
        .then(({ data, status }) => {
          toast.success(status.message);
          onClose();
          onRefresh!();
          navigate(`/allDetails/${traderView?.traderId}`);
        });
    } else {
      handlePostCall({
        URL: `admin/trade-platform/traders/register`,
        apiParams: {
          addrLine: traderView?.addressLine,
          city: traderView?.dist,
          pincode: traderView?.pincode,
          state: traderView?.state,
          adminId: adminId,
          altMobile: traderView?.altMobile,
          kycDocs: images,
          marketId: traderView?.marketId,
          marketName: traderView?.marketName,
          mobile: traderView?.mobile,
          traderName: traderView?.traderName,
          ors: traderView?.ors,
          profilePic: profile,
          regType: selectedOption,
          relMgrId: traderView?.relMgrId,
          shopNum: traderView?.shopNum,
          comments: traderView?.comments,
          tradeLimit: traderView?.tradeLimit,
          tradeName: traderView?.tradeName,
          tradeScode: traderView?.tradeScode,
          tradeType: traderView?.tradeType,
          traderId: traderView?.traderId,
          tradeDesc: traderView?.tradeDesc,
          accountNum: traderView?.accountNum,
          accountHolder: traderView?.accountHolder,
          bankName: traderView?.bankName,
          ifsc: traderView?.ifsc,
          renewalDate: selectedDate || null,
          traderProgress: selectedCompletion,
          status: traderView?.status,
          externelComments: traderView?.externelComments,
          pendingDocs: pendingDocsString,
          emailId: traderView?.emailId,
          tenant: tententType,
          payAcc: payAcc,
          chequeAmt: traderView?.chequeAmt || 0,
          chequeExpDate: traderView?.chequeExpDate,
          chequeStatus: traderView?.chequeStatus,
        },
      })
        .then(({ data, status }) => {
          toast.success(status.message);
          onClose();
          onRefresh!();
          navigate("/customers");
        })
        .catch((err: any) => {
          toast.error(err.response.data.status.description);
        });
    }
  };

  // for radio buttons
  const handleChange = (value: string | number) => {
    setSelectedOption(value);
  };

  // for cheque radio buttons
  const handleCheque = (value: string | number) => {
    setSelectCheque(value);
  };

  // for image upload
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFiles(event.target.files[0]);
      onUploadReceiptImage("PROFILE_PIC", event.target.files);
    }
  };

  // Handler for button clicks
  const handleClick = (e: string) => {
    setActiveButton(e);
  };

  useEffect(() => {
    if (mode === "EDIT") {
      getTraderDetails(true);
    } else {
      getRmDetails();
      getMarketDetails();
      getStatusDetails();
    }
  }, []);

  useEffect(() => {
    if (!payoutModal) {
      setTraderView({
        ...traderView!,
        payAcc: payoutDetails,
      });
    }
  }, [payoutModal]);

  const navigate = useNavigate();

  const [pincodeLength, setPincodeLength] = useState(false);
  function geocodeResponseToDistrictState(geocodeJSON: any) {
    const parsedLocalities = [];
    if (geocodeJSON.results.length) {
      for (let i = 0; i < geocodeJSON.results.length; i++) {
        const result = geocodeJSON.results[i];
        const locality = { dist: "", state: "" };

        result.address_components.forEach((component: any) => {
          const types = component.types;

          if (types.includes("administrative_area_level_2")) {
            locality.dist = component.long_name; // District
          } else if (
            types.includes("locality") ||
            types.includes("sublocality_level_1")
          ) {
            if (!locality.dist) locality.dist = component.long_name; // Backup for district
          } else if (types.includes("administrative_area_level_1")) {
            locality.state = component.long_name; // State
          }
        });

        parsedLocalities.push(locality);
      }
    } else {
      console.error("No address components found");
    }
    return parsedLocalities;
  }

  function fillCityAndStateFields(
    localities: any,
    pincode: any,
    addressLine: string
  ) {
    const locality = localities[0];
    const district = locality?.dist || ""; // Safely handle if locality.dist is undefined
    const state = locality?.state || "";
    setTraderView({
      ...traderView!,
      dist: district,
      state: state,
      pincode: pincode,
      addressLine: addressLine, // Make sure address is updated
    });
  }

  const onZip = (event: any) => {
    let number = event;
    setTraderView({
      ...traderView!,
      pincode: number,
    });

    var api_key = "AIzaSyBw-hcIThiKSrWzF5Y9EzUSkfyD8T1DT4A";

    if (event.length === 0) {
      setTraderView({
        ...traderView!,
        dist: "",
        state: "",
        pincode: "",
        addressLine: "", // Reset the address as well
      });
      setPincodeLength(false);
    } else if (event.length == 6) {
      $.get(
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
          event +
          "&key=" +
          api_key
        // ).then(function (response:any) {
        // var possibleLocalities = geocodeResponseToCityState(response);
      ).then(function (response: any) {
        var possibleLocalities = geocodeResponseToDistrictState(response);

        // Get the full formatted address from the API response
        const fullAddress = response.results[0]?.formatted_address || "";

        // Update city, state, and the addressLine (auto-filled by Google)
        fillCityAndStateFields(possibleLocalities, number, fullAddress);
      });
      setPincodeLength(false);
    } else {
      setPincodeLength(true);
    }
  };

  // for status
  const handleStatusClick = (s: any) => {
    setTraderView({
      ...traderView!,
      // traderStatusId: s.id,
      status: s.status,
      externelComments: "",
    });
    setOpenStatus(false);
    setSelectStatus(s.status);
  }; // Display the name of the selected option

  const documentNames = [
    { name: "Aadhar Card", key: "AADHAR" },
    { name: "Bank Statements", key: "BANK_STATEMENT" },
    { name: "Trade License", key: "TRADE_LICENSE" },
    { name: "PAN Card", key: "PAN" },
    { name: "ITR Document", key: "ITR" },
    { name: "Empty Invoice / Business Card", key: "INVOICE" },
  ];

  const [checked, setChecked] = useState<string[]>([]);
  const handleChecked = (docKey: any) => {
    setChecked(
      (prevChecked) =>
        prevChecked.includes(docKey)
          ? prevChecked.filter((item) => item !== docKey) // Remove if already selected
          : [...prevChecked, docKey] // Add if not selected
    );
  };

  //market model
  const [market, setMarket] = useState(false);
  const [selectedOpti, setSelectedOpti] = useState<number | null>(null);
  const clear = () => {
    setSelectedOpti(0);
    setSearchMrkt("");
    setTraderView({
      ...traderView!,
      marketId: 0,
      marketName: "",
    });
    // setMarket(false);
  };
  const handleMaretClick = (market: any) => {
    setSearchMrkt(market.marketName);
    setTraderView({
      ...traderView!,
      marketId: market?.marketId,
      marketName: market.marketName,
    });
    setMarket(false);
    let index = marketDetails.findIndex((m) => m.marketId == market.marketId);
    marketDetails.splice(index, 1);
    marketDetails.splice(0, 0, market);
    setMarketDetails(marketDetails);
  };

  const filteredMarkets = marketDetails.filter((option) =>
    option.marketName.toLowerCase().includes(searchMrkt.toLowerCase())
  );
  // RM drop-down

  const percentage = [
    { value: "20%" },
    { value: "40%" },
    { value: "60%" },
    { value: "80%" },
    { value: "100%" },
  ];

  const handleCompletionStatus = (value: any) => {
    setSelectedCompletion(value);
    setCompletionStatus(false);
  };

  const dispatch = useDispatch();

  const handleDateSelection = (_date: any) => {
    let date = moment(_date).format("YYYY-MM-DD");
    setSelectedDate(date);
    // setCalenderOpen(false);
    setTraderView({
      ...traderView!,
      renewalDate: date,
    });
  };

  const handleExpireDate = (_date: any) => {
    let date = moment(_date).format("YYYY-MM-DD");
    setSelectedDate(date);
    // setCalenderOpen(false);
    setTraderView({
      ...traderView!,
      chequeExpDate: date,
    });
  };
  // const [confirmAccountNum, setConfirmAccountNum] = useState("");
  const [error, setError] = useState("");

  const handleAccountNumChange = (e: any) => {
    const value = e.target.value;
    setTraderView((prev) => ({ ...traderView!, accountNum: value }));

    // Only validate if confirmAccountNum has been entered
    if (confirmAccountNum) {
      validateAccountNums(value, confirmAccountNum);
    } else {
      setError(""); // Clear error if confirmAccountNum is empty
    }
  };

  const handleConfirmAccountNumChange = (e: any) => {
    const value = e.target.value;
    setConfirmAccountNum(value);

    // Only validate if traderView.accountNum has been entered
    if (traderView?.accountNum) {
      validateAccountNums(traderView.accountNum, value);
    } else {
      setError(""); // Clear error if accountNum is empty
    }
  };

  const validateAccountNums = (accountNum: any, confirmAccountNum: any) => {
    if (accountNum !== confirmAccountNum) {
      setError("Account number do not match");
    } else {
      setError("");
    }
  };

  const [rejectModal, setRejectModal] = useState(false);
  const [holdModal, setHoldModal] = useState(false);
  const [rmOpen, setRmOpen] = useState(false);
  const [blockModal, setBlockModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Or use any breakpoint you prefer
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="m-2">
      <div className="modal-header m-4 border-b flex justify-between items-center">
        {mode !== "EDIT" ? (
          <span className="font-semibold m-2 text-[20px]">Add Trader</span>
        ) : (
          <span className="font-semibold m-2 text-[20px]">
            Edit -{" "}
            <span className="font-semibold text-lg">
              {traderView?.tradeName} -{" "}
            </span>
            <span className="font-semibold text-lg">
              {traderView?.traderId}
            </span>
          </span>
        )}
      </div>
      <div
        className="flex overflow-y-auto"
        style={{ height: "calc(100vh - 15rem)" }}
      >
        <div className="ml-[20px] mr-[10px] w-auto h-auto text-sm">
          <div className="modal-body flex ">
            <div>
              <span className="font-semibold">Trader Type</span>
              <div className="mt-[10px] flex">
                <button
                  className={`border-2 rounded w-[200px] h-[30px] ${
                    activeButton === "DEST" ? "bg-primary text-white" : ""
                  }`}
                  onClick={() => {
                    handleClick("DEST");
                    setTraderView({
                      ...traderView!,
                      tradeType: "DEST",
                    });
                  }}
                >
                  Buy (Destination Trader)
                </button>
                <button
                  className={`border-2 rounded w-[200px] h-[30px] ml-3 ${
                    activeButton === "SOURCE" ? "bg-primary text-white" : ""
                  }`}
                  onClick={() => {
                    handleClick("SOURCE");
                    setTraderView({
                      ...traderView!,
                      tradeType: "SOURCE",
                    });
                  }}
                >
                  Sell (Source Trader)
                </button>
              </div>
            </div>

            <div className="ml-8">
              <label className="text-sm font-semibold">Business Type</label>
              <RadioButton
                displayType="horizontal"
                data={[
                  { label: "INDIVIDUAL", value: "INDIVIDUAL" },
                  { label: "PROPRIETOR", value: "PROPRIETOR" },
                  { label: "PARTNERSHIP", value: "PARTNERSHIP" },
                  { label: "LLP", value: "LLP" },
                  { label: "PRIVATE LIMITED", value: "PRIVATE LIMITED" },
                ]}
                value={selectedOption}
                pointer={{ label: "label", value: "value" }}
                onChange={(value) => {
                  handleChange(value);
                  setTraderView({
                    ...traderView!,
                    businessType: value,
                  });
                }}
                styles={{ display: "flex", gap: "1rem", paddingTop: "0.5rem" }}
              />
            </div>
          </div>

          <div className="mb-[10px] mt-[15px] font-semibold">
            <span className="text-[17px]">Trader Status</span>
            <div className="border-b mt-2"></div>
          </div>
          <div className="flex">
            <div>
              <div className="mb-1">
                <span className="text-sm font-semibold">
                  Comments(internal)
                </span>
              </div>

              <div className="w-[845px] mr-[20px]">
                <textarea
                  className="text-sm font-semibold border-gray br-10 h-[100px] p-2 w-full resize-none"
                  value={traderView?.comments}
                  onChange={(e) => {
                    setTraderView({
                      ...traderView!,
                      comments: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div>
              <span className="text-sm font-semibold">
                Profile Completion Status
              </span>
              <div
                className="w-[230px] h-[43px] relative border border-gray-300 rounded cursor-pointer mt-1.5"
                onClick={() => setCompletionStatus(true)}
              >
                <div className="flex justify-between h-full py-2 px-2">
                  <div className="">{selectedCompletion}</div>
                  <ArrowDropDownIcon className="mr-2 cursor-pointer" />
                </div>
              </div>

              <Popover
                open={completionStatus}
                onClose={() => {
                  setCompletionStatus(false);
                }}
                className="w-[230px] ml-[115px] h-auto"
              >
                <div className="border-b p-1">
                  {percentage.map((percent) => (
                    <div
                      key={percent.value}
                      className="font-semibold cursor-pointer py-2 px-2"
                      onClick={() => {
                        handleCompletionStatus(percent.value);
                      }}
                    >
                      {percent.value}
                    </div>
                  ))}
                </div>
              </Popover>
            </div>

            <div>
              <div className="ml-[20px]">
                <span className="text-sm font-semibold">Status</span>

                <div
                  className="w-[230px] h-[43px] border-gray br-5 cursor-pointer mt-1.5"
                  onClick={() => {
                    setOpenStatus(true);
                  }}
                >
                  <div className="relative flex justify-between ">
                    <div className="ml-1 mt-0.5 text-base">
                      {traderView?.status === "ACTIVE" && (
                        <div className="ml-2 mt-2 flex cursor-pointer color-primary font-semibold">
                          <div>
                            {" "}
                            <img src={tickmark} className="w-5 h-5 mt-1" />{" "}
                          </div>
                          <div className="ml-1">{traderView?.status}</div>
                        </div>
                      )}

                      {traderView?.status === "UNDER REVIEW" && (
                        <div className="ml-2 mt-2 flex cursor-pointer color-orange font-semibold">
                          <div>
                            {" "}
                            <img src={timer} className="w-5 h-5 mt-1" />{" "}
                          </div>
                          <div className="ml-1">{traderView?.status}</div>
                        </div>
                      )}

                      {traderView?.status === "HOLD" && (
                        <div className="ml-2 mt-2 flex cursor-pointer color-orange font-semibold">
                          <div>
                            {" "}
                            <img src={hold} className="w-5 h-5 mt-1" />{" "}
                          </div>
                          <div className="ml-1">{traderView?.status}</div>
                        </div>
                      )}

                      {traderView?.status === "REJECTED" && (
                        <div className="ml-2 mt-2 flex cursor-pointer color-red font-semibold">
                          <div>
                            {" "}
                            <img src={reject} className="w-5 h-5 mt-1" />{" "}
                          </div>
                          <div className="ml-1">{traderView?.status}</div>
                        </div>
                      )}

                      {traderView?.status === "BLOCKED" && (
                        <div className="ml-2 mt-2 flex cursor-pointer color-red font-semibold">
                          <div>
                            {" "}
                            <img src={blocked} className="w-5 h-5 mt-1" />{" "}
                          </div>
                          <div className="ml-1">{traderView?.status}</div>
                        </div>
                      )}
                    </div>
                    <div>
                      <ArrowDropDownIcon className=" mt-[10px] mr-2 cursor-pointer absolute right-0" />
                    </div>
                  </div>
                </div>
              </div>

              {/* status popover */}
              <div>
                <Popover
                  open={openStatus}
                  onClose={() => {
                    setOpenStatus(false);
                  }}
                  className="w-[230px] ml-[135px] h-auto"
                >
                  <div>
                    {traderStatus.map((status) => (
                      <div
                        className="p-1"
                        onClick={() => handleStatusClick(status)}
                      >
                        {status.status === "ACTIVE" && (
                          <div className="ml-2 flex cursor-pointer color-primary font-semibold">
                            <div>
                              {" "}
                              <img
                                src={tickmark}
                                className="w-5 h-5 mt-3"
                              />{" "}
                            </div>
                            <div className="ml-1 mt-2">{status.status}</div>
                          </div>
                        )}

                        {status.status === "UNDER REVIEW" && (
                          <div className="ml-2 flex cursor-pointer color-orange font-semibold">
                            <div>
                              {" "}
                              <img src={timer} className="w-5 h-5 mt-3" />{" "}
                            </div>
                            <div className="ml-1 mt-2">{status.status}</div>
                          </div>
                        )}

                        {status.status === "HOLD" && (
                          <div className="ml-2 flex cursor-pointer flex color-orange font-semibold">
                            <div>
                              {" "}
                              <img src={hold} className="w-5 h-5 mt-3" />{" "}
                            </div>
                            <div className="ml-1 mt-2">{status.status}</div>
                          </div>
                        )}

                        {status.status === "REJECTED" && (
                          <div className="ml-2 flex cursor-pointer color-red font-semibold">
                            <div>
                              {" "}
                              <img src={reject} className="w-5 h-5 mt-3" />{" "}
                            </div>
                            <div className="ml-1 mt-2">{status.status}</div>
                          </div>
                        )}

                        {status.status === "BLOCKED" && (
                          <div className="ml-2 flex cursor-pointer color-red font-semibold">
                            <div>
                              {" "}
                              <img
                                src={blocked}
                                className="w-5 h-5 mt-3"
                              />{" "}
                            </div>
                            <div className="ml-1 mt-2">{status.status}</div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </Popover>
              </div>
            </div>
          </div>
          <div className="mb-[10px] mt-[15px] font-semibold">
            <span className="text-[17px]">Relationship Manager</span>
            <div className="border-b mt-2"></div>
          </div>
          <div className="flex mt-[10px]">
            <div>
              <div className="mb-1">
                <span className="text-sm font-semibold">
                  Relationship Manager
                </span>
              </div>

              <div
                className="w-[270px] h-[45px] mr-[20px] border-gray cursor-pointer br-5"
                onClick={() => {
                  setRmOpen(true);
                  setSearchTerm("");
                }}
              >
                <div className="relative flex items-center h-full">
                  <span className="text-center ml-2 text-sm font-semibold">
                    {traderView?.relMgr}
                  </span>

                  <span className="absolute top-2 right-0">
                    <ArrowDropDownIcon />
                  </span>
                </div>
              </div>

              {/* relationship manager dropdown */}
              <div className="ml-[135px]">
                <Popover
                  open={rmOpen}
                  onClose={() => {
                    setRmOpen(false);
                  }}
                  className="w-[270px] h-auto"
                >
                  <div>
                    <RelationshipMngr
                      rmId={traderView?.relMgrId!}
                      onChange={(rm) => {
                        setTraderView({
                          ...traderView!,
                          rmMobile: rm.mobile,
                          relMgrId: rm.id,
                          relMgr: rm.name,
                        });
                        setRmOpen(false);
                      }}
                      onClear={() => {
                        setTraderView({
                          ...traderView!,
                          relMgrId: 0,
                          rmMobile: "",
                          relMgr: "",
                        });
                      }}
                    />
                  </div>
                </Popover>
              </div>
            </div>

            <div>
              <div className=" mb-1">
                <span className="text-sm font-semibold">RM Mobile Number</span>
              </div>
              <div className="w-[270px] h-[45px] mr-[20px] mb-4 border border-gray br-5">
                <div className="flex items-center h-full">
                  <span className="text-center text-sm font-semibold ml-2">
                    {traderView?.rmMobile}
                  </span>
                </div>
              </div>
            </div>

            <div>
              <div className="mb-1">
                <span className="text-sm font-semibold">Trade Limit</span>
              </div>

              <div className="w-[270px] h-[40px] mr-[20px] mb-4">
                <Input
                  className="text-sm font-semibold"
                  type="number"
                  value={traderView?.tradeLimit}
                  onChange={(e) => {
                    setTraderView({
                      ...traderView!,
                      tradeLimit: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div>
              <div className="mb-1">
                {" "}
                <span className="text-sm font-semibold">
                  ORS-ONO RISK SCORE
                </span>
              </div>
              <div className="w-[230px] h-[40px] mb-4">
                <Input
                  className="text-sm font-semibold"
                  type="number"
                  value={traderView?.ors}
                  onChange={(e) => {
                    console.log(e);
                    setTraderView({
                      ...traderView!,
                      ors: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div>
              <div className="w-[230px] h-[40px] mb-4 ml-[20px]">
                <span className="text-sm font-semibold mb-1">Renewal Date</span>
                <SelectDate
                  onChange={handleDateSelection}
                  maxDate={moment().add(2, "years").format("YYYY-MM-DD")}
                  selectedDate={
                    traderView?.renewalDate! ||
                    moment()
                      .add(1, "year")
                      .subtract(1, "day")
                      .format("YYYY-MM-DD")
                  }
                  styles={{ padding: "13px" }}
                />
              </div>
            </div>
          </div>

          <div className="">
            <div className="mb-2">
              <span className="text-[17px] font-semibold">
                Business Details<span className="color-red ml-1">{"*"}</span>
              </span>
            </div>
            <div className="border-b"></div>

            <div className="flex">
              <div>
                <div className="flex mt-[20px]">
                  <div>
                    <div className="mb-1">
                      <span className="text-sm font-semibold">
                        Trade Name<span className="color-red ml-1">{"*"}</span>
                      </span>
                    </div>

                    <div className="w-[270px] h-[40px] mr-[20px]">
                      <Input
                        className="text-sm font-semibold"
                        type="text"
                        value={traderView?.tradeName}
                        onChange={(e) => {
                          setTraderView({
                            ...traderView!,
                            tradeName: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <div className="mb-1">
                      <span className="text-sm font-semibold">
                        Owner Name<span className="color-red ml-1">{"*"}</span>
                      </span>
                    </div>

                    <div className="w-[270px] h-[40px] mr-[20px]">
                      <Input
                        className="text-sm font-semibold"
                        type="text"
                        value={traderView?.traderName}
                        onChange={(e) => {
                          setTraderView({
                            ...traderView!,
                            traderName: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mb-1">
                      <span className="text-sm font-semibold">
                        Trade Short Code
                      </span>
                    </div>

                    <div className="w-[270px] h-[40px] mr-[20px]">
                      <Input
                        className="text-sm font-semibold"
                        type="text"
                        value={traderView?.tradeScode}
                        onChange={(e) => {
                          const value = e.target.value;

                          // No validation here, so special characters are allowed
                          setTraderView({
                            ...traderView!,
                            tradeScode: value, // Directly set the value, allowing special characters
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <div className="mb-1">
                      <span className="text-sm font-semibold">
                        Trade Type<span className="color-red ml-1">{"*"}</span>
                      </span>
                    </div>

                    <div className="w-[270px] h-[45px] mr-[20px] mb-4 border border-gray br-5">
                      <div className="flex items-center h-full">
                        <span className="text-center text-sm font-semibold ml-2">
                          {traderView?.tradeType}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex mt-[10px]">
                  <div>
                    <div className="mb-1">
                      <span className="text-sm font-semibold">
                        Market Name<span className="color-red ml-1">{"*"}</span>
                      </span>
                    </div>
                    <div
                      className="w-[270px] h-[45px] mr-[20px] border-gray br-5 cursor-pointer"
                      onClick={() => {
                        setMarket(true);
                        setSearchMrkt("");
                      }}
                    >
                      <div className="relative flex items-center h-full">
                        {/* <Input
                          type="text"
                          value={traderView?.marketName}
                          // onChange={(e) => {
                          //   setTraderView({
                          //     ...traderView!,
                          //     marketName: e.target.value,
                          //   });
                          // }}
                          // onChange={(e) => setSearchMrkt(e.target.value)}
                        /> */}
                        <span className="text-center ml-2 font-medium text-sm">
                          {traderView?.marketName}
                        </span>

                        <span className="absolute top-2 right-0">
                          <ArrowDropDownIcon />
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* market popover */}
                  <div>
                    <Modal
                      open={market}
                      onClose={() => {
                        setMarket(false);
                      }}
                      width={isMobile ? "100%" : "30vw"}
                      height="70vh"
                    >
                      <>
                        <ModalHeader title={"Select Market"} content={<></>} />
                        <div className="">
                          <div className="flex gap-4 m-3 relative">
                            <FaSearch
                              className="absolute color-primary"
                              size={15}
                              style={{ top: "14px", left: "6px" }}
                            />
                            <Input
                              type="text"
                              palceholder="Search by Market Name"
                              value={searchMrkt}
                              onChange={(e) => {
                                setSearchMrkt(e.target.value);
                              }}
                              inputStyle={{
                                textIndent: "22px",
                                padding: "8px 5px",
                              }}
                              style={{
                                width: "99%",
                              }}
                            />

                            <IoMdCloseCircleOutline
                              onClick={() => {
                                clear();
                              }}
                              className="absolute color-primary cursor-pointer w-10"
                              style={{
                                top: "32%",
                                right: "1.5rem",
                                fontSize: "1.2rem",
                                color: "gray",
                                cursor: "pointer",
                              }}
                            />
                          </div>

                          <div
                            className="flex overflow-y-auto border-gray m-3 br-10"
                            style={{ height: "calc(90vh - 15rem)" }}
                          >
                            <div className="w-[100%]">
                              {filteredMarkets.length > 0 ? (
                                filteredMarkets.map((option) => (
                                  <div
                                    className={`flex border-b p-2 cursor-pointer hv-primary cursor-pointer ${
                                      traderView?.marketId === option.marketId
                                        ? "bg-light-primary w-full p-2 border-primary br-10"
                                        : "bg-white"
                                    }`}
                                    onClick={() => handleMaretClick(option)}
                                  >
                                    <div
                                      className={`flex items-center br-5 mb-1 p-0.5`}
                                    >
                                      <img
                                        src={marketImgg}
                                        alt=""
                                        className="h-7 w-7"
                                      />
                                      <span className="ml-3 font-semibold">
                                        {option.marketName}
                                      </span>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="flex items-center justify-center h-full">
                                  <span className="font-semibold">
                                    No Market Found
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    </Modal>
                  </div>

                  <div>
                    <div className="mb-1">
                      <span className="text-sm font-semibold">Shop Number</span>
                    </div>
                    <div className="w-[270px] h-[40px] mr-[20px]">
                      <Input
                        className="text-sm font-semibold"
                        type="text"
                        value={traderView?.shopNum}
                        onChange={(e) => {
                          const value = e.target.value;

                          // No validation here, so special characters are allowed
                          setTraderView({
                            ...traderView!,
                            shopNum: value, // Directly set the value, allowing special characters
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mb-1">
                      <span className="text-sm font-semibold">
                        Mobile Number
                      </span>
                    </div>

                    <div className="w-[270px] h-[40px] mr-[20px]">
                      <Input
                        className="text-sm font-semibold"
                        type="number"
                        value={traderView?.mobile}
                        onChange={(e) => {
                          setTraderView({
                            ...traderView!,
                            mobile: e.target.value,
                          });
                        }}
                      />

                      {traderView?.mobile && traderView.mobile.length > 0 && (
                        <>
                          {traderView.mobile.length < 10 ? (
                            <span className="text-xs color-red">
                              Please enter 10 digit mobile number.
                            </span>
                          ) : traderView.mobile.length === 10 ? (
                            ""
                          ) : (
                            <span className="text-xs color-red">
                              Mobile number can't be more than 10 digits.
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="mb-1">
                      <span className="text-sm font-semibold">
                        Alternate Mobile
                      </span>
                    </div>

                    <div className="w-[270px] h-[40px]">
                      <Input
                        className="text-sm font-semibold"
                        type="number"
                        value={traderView?.altMobile}
                        onChange={(e) => {
                          setTraderView({
                            ...traderView!,
                            altMobile: e.target.value,
                          });
                        }}
                      />
                      {traderView?.altMobile &&
                        traderView.altMobile.length > 0 && (
                          <>
                            {traderView.altMobile.length < 10 ? (
                              <span className="text-xs color-red">
                                Please enter 10 digit mobile number.
                              </span>
                            ) : traderView.altMobile.length === 10 ? (
                              ""
                            ) : (
                              <span className="text-xs color-red">
                                Mobile number can't be more than 10 digits.
                              </span>
                            )}
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Profile pic upload and preview */}
              <div className="relative w-[160px] h-[155px] ml-[40px] mt-[30px] border-gray br-5">
                <div
                  className="h-[100px] w-[80px] ml-[40px] mt-[20px]"
                  style={{
                    width: "90px",
                    height: "90px",
                  }}
                >
                  {(profile === "" || profile === null) && (
                    <img
                      src={EmptyProfile}
                      alt=""
                      className="w-[70px] h-[70px] mt-1 mr-2"
                    />
                  )}
                  {(profile !== "" || profile === null) && (
                    <img
                      src={profile}
                      className="h-[80px] w-[80px]"
                      style={{
                        borderRadius: "100%",
                      }}
                    />
                  )}
                </div>
                <input
                  type="file"
                  ref={ref}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <button
                  onClick={() => {
                    ref.current?.click();
                  }}
                  className="absolute bottom-0 color-blue ml-3.5 border-gray br-5 fit-content"
                >
                  Choose From Library
                </button>
              </div>
            </div>
          </div>

          <div className="flex">
            <div className="mt-2">
              <div className="mb-1">
                <span className="text-sm font-semibold">Trade Description</span>
              </div>
              <div className="w-[270px] h-[40px] mr-[20px]">
                <Input
                  className="text-sm font-semibold"
                  type="text"
                  value={traderView?.tradeDesc}
                  onChange={(e) => {
                    setTraderView({
                      ...traderView!,
                      tradeDesc: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="mt-2">
              <div className="mb-1">
                <span className="text-sm font-semibold">Email Address</span>
              </div>
              <div className="w-[270px] h-[40px] mr-[20px]">
                <Input
                  className="text-sm font-semibold"
                  type="text"
                  value={traderView?.emailId}
                  onChange={(e) => {
                    setTraderView({
                      ...traderView!,
                      emailId: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="font-semibold mt-[20px] mb-[10px]">
            <span className="text-[17px]">
              Business Address<span className="color-red ml-1">{"*"}</span>
            </span>
          </div>
          <div className="border-b"></div>
          <div className="flex mt-[12px]">
            <div>
              <div className="mb-1">
                <span className="text-sm font-semibold">Pincode</span>
              </div>

              <div className="w-[270px] h-[40px] mr-[20px]">
                <Input
                  className="text-sm font-semibold"
                  type="text"
                  value={traderView?.pincode}
                  onChange={(e) => {
                    // setTraderView({
                    //   ...traderView!,
                    //   pincode: e.target.value,
                    // });
                    onZip(e.target.value);
                    console.log(e.target.value);
                  }}
                />
                {pincodeLength ? (
                  <span className="color-red">
                    Minimum pincode length should be 6
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div>
              <div className="mb-1">
                <span className="text-sm font-semibold">State</span>
              </div>

              <div className="w-[270px] h-[40px] mr-[20px]">
                <Input
                  className="text-sm font-semibold"
                  type="text"
                  value={traderView?.state}
                  onChange={(e) => {
                    setTraderView({
                      ...traderView!,
                      state: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div>
              <div className="mb-1">
                <span className="text-sm font-semibold">District</span>
              </div>

              <div className="w-[270px] h-[40px] mr-[20px]">
                <Input
                  className="text-sm font-semibold"
                  type="text"
                  value={traderView?.dist}
                  onChange={(e) => {
                    setTraderView({
                      ...traderView!,
                      dist: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div>
              <div className="mb-1">
                <span className="text-sm font-semibold">Street & Village</span>
              </div>

              <div className="w-[456px] h-[40px]">
                <Input
                  className="text-sm font-semibold"
                  type="text"
                  value={traderView?.addressLine}
                  onChange={(e) => {
                    setTraderView({
                      ...traderView!,
                      addressLine: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          {traderView?.tradeType == "SOURCE" && (
            <div>
              <div className="flex font-semibold mt-[20px] mb-[10px]">
                <span className="text-[17px] mr-[40px]">
                  Bank Details<span className="color-red ml-1">{"*"}</span>
                </span>
                <Button
                  onClick={() => {
                    openPayoutModal(true);
                    dispatch(
                      setPayoutDetails({
                        traderId: traderView?.traderId!,
                        traderType: traderView?.tradeType!,
                        contactNumber: traderView?.mobile!,
                        emailNumber: traderView?.emailId!,
                        isApiCall: false,
                        type: "TRADE",
                      })
                    );
                  }}
                >
                  Add Account
                </Button>
              </div>
              <div className="border-b"></div>
              {traderView?.payAcc?.map((account, accIndex) => (
                <div className="flex mt-[12px] mb-[10px]">
                  <div className="w-[20%] mr-[20px]">
                    <Input
                      className="text-sm font-semibold"
                      label="Account Number"
                      type="text"
                      value={account.actNum}
                      disabled={true}
                    />
                  </div>
                  <div className="w-[20%] mr-[20px]">
                    <Input
                      className="text-sm font-semibold"
                      label="Fund Account ID"
                      type="text"
                      value={account.rzpFa}
                      disabled={true}
                    />
                  </div>
                  <div className="w-[20%] mr-[20px]">
                    <Input
                      className="text-sm font-semibold"
                      label="IFSC Code"
                      type="text"
                      value={account.ifsc}
                      disabled={true}
                    />
                  </div>
                  <div className="w-[20%] mr-[20px]">
                    <Input
                      className="text-sm font-semibold"
                      label="Created On"
                      type="text"
                      value={moment(account.createdOn).format("DD-MMM-YYYY")}
                      disabled={true}
                    />
                  </div>
                  <div className="w-[20%] mr-[20px]">
                    <div className="text-sm font-semibold">Status</div>
                    <div
                      className={`flex items-center ${
                        account.status == 1
                          ? "bg-light-primary border-primary"
                          : "bg-gray border-gray"
                      } rounded-lg h-[45px] px-2 `}
                    >
                      <div
                        onClick={() => {
                          setTraderView({
                            ...traderView,
                            payAcc: traderView.payAcc.map((acc, index) => {
                              if (index == accIndex) {
                                return {
                                  ...acc,
                                  status: acc.status == 1 ? 0 : 1,
                                };
                              }
                              return acc;
                            }),
                          });
                        }}
                      >
                        <SwicthButton value={account.status == 1} text={""} />
                      </div>
                      <span
                        className={`ml-1 text-sm font-semibold ${
                          account.status == 1 ? "color-primary" : "color-gray"
                        }`}
                      >
                        {account.status == 1 ? "Active" : "InActive"}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="border-b"></div>

          {/* <div className="flex mt-[12px]">
            <div>
              <div className="mb-1">
                <span className="text-sm font-semibold">Account Holder</span>
              </div>

              <div className="w-[270px] h-[40px] mr-[20px]">
                <Input
                  className="text-sm font-semibold"
                  type="text"
                  value={traderView?.accountHolder}
                  onChange={(e) => {
                    setTraderView({
                      ...traderView!,
                      accountHolder: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div>
              <div className="mb-1">
                <span className="text-sm font-semibold">Account Number</span>
              </div>

              <div className="w-[270px] h-[40px] mr-[20px]">
                <Input
                  className="text-sm font-semibold"
                  type="password"
                  value={traderView?.accountNum}
                  onChange={handleAccountNumChange}
                  autoComplete="new-password"
                />
              </div>
            </div>

            <div>
              <div className="mb-1">
                <span className="text-sm font-semibold">
                  Confirm Account Number
                </span>
              </div>

              <div className="w-[270px] h-[40px] mr-[20px]">
                <Input
                  className="text-sm font-semibold"
                  type="number"
                  onChange={handleConfirmAccountNumChange}
                  value={confirmAccountNum}
                />
              </div>
              {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
            </div>

            <div>
              <div className="mb-1">
                <span className="text-sm font-semibold">Bank Name</span>
              </div>

              <div className=" w-[240px] h-[40px] mr-[20px]">
                <Input
                  className="text-sm font-semibold"
                  type="text"
                  value={traderView?.bankName}
                  onChange={(e) => {
                    setTraderView({
                      ...traderView!,
                      bankName: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div>
              <div className="mb-1">
                <span className="text-sm font-semibold">IFSC Code</span>
              </div>

              <div className=" w-[240px] h-[40px]">
                <Input
                  className="text-sm font-semibold"
                  type="text"
                  value={traderView?.ifsc}
                  onChange={(e) => {
                    setTraderView({
                      ...traderView!,
                      ifsc: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div> */}

          <div className="flex mt-[20px] gap-7">
            <div>
              <label className="text-sm font-semibold">Cheque Status</label>
              <RadioButton
                displayType="horizontal"
                data={[
                  { label: "PDC", value: "PDC" },
                  { label: "UDC", value: "UDC" },
                  { label: "None", value: "None" },
                ]}
                value={selectCheque}
                pointer={{ label: "label", value: "value" }}
                onChange={(value) => {
                  handleCheque(value);
                  setTraderView({
                    ...traderView!,
                    chequeStatus: value,
                  });
                }}
                styles={{ display: "flex", gap: "3rem", paddingTop: "0.5rem" }}
              />
            </div>
            {selectCheque == "PDC" && (
              <div className="">
                <div className="mb-1">
                  <span className="text-sm font-semibold">Expiry Date</span>
                </div>
                <div className="w-[270px] h-[40px]">
                  <SelectDate
                    onChange={handleExpireDate}
                    selectedDate={
                      traderView?.chequeExpDate! ||
                      moment().format("YYYY-MM-DD")
                    }
                    styles={{ padding: "13px" }}
                    maxDate={moment().add(1, "years").format("YYYY-MM-DD")}
                  />
                </div>
              </div>
            )}
            {(selectCheque == "PDC" || selectCheque == "UDC") && (
              <div>
                <div className="mb-1">
                  <span className="text-sm font-semibold">Amount</span>
                </div>

                <div className="w-[270px] h-[40px] ">
                  <Input
                    className="text-sm font-semibold"
                    type="text"
                    value={traderView?.chequeAmt}
                    onChange={(e) => {
                      setTraderView({
                        ...traderView!,
                        chequeAmt: parseFloat(e.target.value),
                      });
                    }}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        setTraderView({
                          ...traderView!,
                          chequeAmt: "",
                        });
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="mt-[20px] font-semibold">
            <span className="text-[17px] mb-2">
              Documents<span className="color-red ml-1">{"*"}</span>
            </span>
            <div className="border-b mt-2"></div>
          </div>
          <div className="">
            <div className="flex mt-[16px] mb-[16px]">
              <div>
                <div className="mb-1">
                  <span className="font-semibold">
                    Aadhar Card<span className="color-red ml-1">{"*"}</span>
                  </span>
                </div>

                <div className="flex w-[600px] h-[170px] bg-white p-4 border border-gray-300 rounded-lg  overflow-auto">
                  <div className="shrink-0 overflow-hidden p-2">
                    <FileUploader
                      onChange={(e) => {
                        if (e.target.files) {
                          onUploadReceiptImage("AADHAR", e.target.files);
                        }
                      }}
                    />
                  </div>
                  {traderView?.kycDocs != null &&
                    traderView?.kycDocs
                      .filter((doc) => doc.type == "AADHAR" && doc.status != 0)
                      .map((image, i) => (
                        <div
                          className="shrink-0 overflow-hidden p-2 rounded relative"
                          style={{
                            width: "40%",
                            height: "8rem",
                          }}
                          key={`AADHAR-${i}`}
                        >
                          <a href={image.url} target="_blank">
                            <DocumentPreview
                              url={image.url}
                              docType={
                                image.url.includes(".pdf") ? "pdf" : "image"
                              }
                            />
                          </a>
                          <span
                            className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                            style={{
                              background: "#ff0000de",
                            }}
                            onClick={() => {
                              onRemoveReceiptImage(image.url, true, image.id);
                            }}
                          >
                            <FaXmark style={{ color: "white" }} />
                          </span>
                        </div>
                      ))}
                  {kycDocs
                    .filter((doc) => doc.type == "AADHAR")
                    .map((image, i) => (
                      <div
                        className="shrink-0 overflow-hidden p-2 rounded relative"
                        style={{
                          width: "40%",
                          height: "8rem",
                        }}
                        key={`AADHAR-${i}`}
                      >
                        <a href={image.url} target="_blank">
                          <DocumentPreview
                            url={image.url}
                            docType={
                              image.url.includes(".pdf") ? "pdf" : "image"
                            }
                          />
                        </a>
                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{
                            background: "#ff0000de",
                          }}
                          onClick={() => {
                            onRemoveReceiptImage(image.url);
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      </div>
                    ))}
                </div>
              </div>

              <div className="ml-[20px]">
                <div className="mb-1">
                  <span className="font-semibold">
                    Permanent Account Number(PAN)
                    <span className="color-red ml-1">{"*"}</span>
                  </span>
                </div>

                <div className="flex w-[746px] h-[170px] bg-white p-4 border border-gray-300 rounded-lg overflow-auto">
                  <div className="shrink-0 overflow-hidden p-2">
                    <FileUploader
                      onChange={(e) => {
                        if (e.target.files) {
                          onUploadReceiptImage("PAN", e.target.files);
                        }
                      }}
                    />
                  </div>

                  {traderView?.kycDocs != null &&
                    traderView?.kycDocs
                      .filter((doc) => doc.type == "PAN" && doc.status != 0)
                      .map((image, i) => (
                        <div
                          className="shrink-0 overflow-hidden p-2 rounded relative"
                          style={{
                            width: "40%",
                            height: "8rem",
                          }}
                          key={`PAN-${i}`}
                        >
                          <a href={image.url} target="_blank">
                            <DocumentPreview
                              url={image.url}
                              docType={image.docType}
                            />
                          </a>
                          <span
                            className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                            style={{
                              background: "#ff0000de",
                            }}
                            onClick={() => {
                              onRemoveReceiptImage(image.url, true, image.id);
                            }}
                          >
                            <FaXmark style={{ color: "white" }} />
                          </span>
                        </div>
                      ))}
                  {kycDocs
                    .filter((doc) => doc.type == "PAN")
                    .map((image, i) => (
                      <div
                        className="shrink-0 overflow-hidden p-2 rounded relative"
                        style={{
                          width: "40%",
                          height: "8rem",
                        }}
                        key={`PAN-${i}`}
                      >
                        <a href={image.url} target="_blank">
                          <DocumentPreview
                            url={image.url}
                            docType={image.docType}
                          />
                        </a>
                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{
                            background: "#ff0000de",
                          }}
                          onClick={() => {
                            onRemoveReceiptImage(image.url);
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="flex mt-[16px]">
              <div className="mt-2">
                <div className="mb-1">
                  <span className="font-semibold">
                    Bank Statement<span className="color-red ml-1">{"*"}</span>
                  </span>
                </div>

                <div className="flex w-[600px] h-[170px] bg-white p-4 border border-gray-300 rounded-lg overflow-auto">
                  <div className="shrink-0 overflow-hidden p-2">
                    <FileUploader
                      onChange={(e) => {
                        if (e.target.files) {
                          onUploadReceiptImage(
                            "BANK_STATEMENT",
                            e.target.files
                          );
                        }
                      }}
                    />
                  </div>
                  {traderView?.kycDocs != null &&
                    traderView?.kycDocs
                      .filter(
                        (doc) => doc.type == "BANK_STATEMENT" && doc.status != 0
                      )
                      .map((image, i) => (
                        <div
                          className="shrink-0 overflow-hidden p-2 rounded relative"
                          style={{
                            width: "40%",
                            height: "8rem",
                          }}
                          key={`BANK_STATEMENT-${i}`}
                        >
                          <a href={image.url} target="_blank">
                            <DocumentPreview
                              url={image.url}
                              docType={image.docType}
                            />
                          </a>
                          <span
                            className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                            style={{
                              background: "#ff0000de",
                            }}
                            onClick={() => {
                              onRemoveReceiptImage(image.url, true, image.id);
                            }}
                          >
                            <FaXmark style={{ color: "white" }} />
                          </span>
                        </div>
                      ))}
                  {kycDocs
                    .filter((doc) => doc.type == "BANK_STATEMENT")
                    .map((image, i) => (
                      <div
                        className="shrink-0 overflow-hidden p-2 rounded relative"
                        style={{
                          width: "40%",
                          height: "8rem",
                        }}
                        key={`BANK_STATEMENT-${i}`}
                      >
                        <a href={image.url} target="_blank">
                          <DocumentPreview
                            url={image.url}
                            docType={image.docType}
                          />
                        </a>
                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{
                            background: "#ff0000de",
                          }}
                          onClick={() => {
                            onRemoveReceiptImage(image.url);
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      </div>
                    ))}
                </div>
              </div>

              <div className="ml-[20px] mt-2">
                <div className="mb-1">
                  <span className="font-semibold">ITR Document</span>
                </div>

                <div className="flex w-[746px] h-[170px] bg-white p-4 border border-gray-300 rounded-lg overflow-auto">
                  <div className="shrink-0 overflow-hidden p-2">
                    <FileUploader
                      onChange={(e) => {
                        if (e.target.files) {
                          onUploadReceiptImage("ITR", e.target.files);
                        }
                      }}
                    />
                  </div>

                  {traderView?.kycDocs != null &&
                    traderView?.kycDocs
                      .filter((doc) => doc.type == "ITR" && doc.status != 0)
                      .map((image, i) => (
                        <div
                          className="shrink-0 overflow-hidden p-2 rounded relative"
                          style={{
                            width: "40%",
                            height: "8rem",
                          }}
                          key={`ITR-${i}`}
                        >
                          <a href={image.url} target="_blank">
                            <DocumentPreview
                              url={image.url}
                              docType={image.docType}
                            />
                          </a>
                          <span
                            className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                            style={{
                              background: "#ff0000de",
                            }}
                            onClick={() => {
                              onRemoveReceiptImage(image.url, true, image.id);
                            }}
                          >
                            <FaXmark style={{ color: "white" }} />
                          </span>
                        </div>
                      ))}
                  {kycDocs
                    .filter((doc) => doc.type == "ITR")
                    .map((image, i) => (
                      <div
                        className="shrink-0 overflow-hidden p-2 rounded relative"
                        style={{
                          width: "40%",
                          height: "8rem",
                        }}
                        key={`ITR-${i}`}
                      >
                        <a href={image.url} target="_blank">
                          <DocumentPreview
                            url={image.url}
                            docType={image.docType}
                          />
                        </a>
                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{
                            background: "#ff0000de",
                          }}
                          onClick={() => {
                            onRemoveReceiptImage(image.url);
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="flex mt-[16px]">
              <div className="mt-2">
                <div className="mb-1">
                  <span className="font-semibold">
                    Trade/Mandi License
                    <span className="color-red ml-1">{"*"}</span>
                  </span>
                </div>

                <div className="flex w-[600px] h-[170px] bg-white p-4 border border-gray-300 rounded-lg overflow-auto">
                  <div className="shrink-0 overflow-hidden p-2">
                    <FileUploader
                      onChange={(e) => {
                        if (e.target.files) {
                          onUploadReceiptImage("TRADE_LICENSE", e.target.files);
                        }
                      }}
                    />
                  </div>

                  {traderView?.kycDocs != null &&
                    traderView?.kycDocs
                      .filter(
                        (doc) => doc.type == "TRADE_LICENSE" && doc.status != 0
                      )
                      .map((image, i) => (
                        <div
                          className="shrink-0 overflow-hidden p-2 rounded relative"
                          style={{
                            width: "40%",
                            height: "8rem",
                          }}
                          key={`TRADE_LICENSE-${i}`}
                        >
                          <a href={image.url} target="_blank">
                            <DocumentPreview
                              url={image.url}
                              docType={image.docType}
                            />
                          </a>
                          <span
                            className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                            style={{
                              background: "#ff0000de",
                            }}
                            onClick={() => {
                              onRemoveReceiptImage(image.url, true, image.id);
                            }}
                          >
                            <FaXmark style={{ color: "white" }} />
                          </span>
                        </div>
                      ))}
                  {kycDocs
                    .filter((doc) => doc.type == "TRADE_LICENSE")
                    .map((image, i) => (
                      <div
                        className="shrink-0 overflow-hidden p-2 rounded relative"
                        style={{
                          width: "40%",
                          height: "8rem",
                        }}
                        key={`TRADE_LICENSE-${i}`}
                      >
                        <a href={image.url} target="_blank">
                          <DocumentPreview
                            url={image.url}
                            docType={image.docType}
                          />
                        </a>
                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{
                            background: "#ff0000de",
                          }}
                          onClick={() => {
                            onRemoveReceiptImage(image.url);
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      </div>
                    ))}
                </div>
              </div>

              <div className="ml-[20px] mt-2">
                <div className="mb-1">
                  <span className="font-semibold">
                    Empty Invoice/Business Card
                    <span className="color-red ml-1">{"*"}</span>
                  </span>
                </div>

                <div className="flex w-[746px] h-[170px] bg-white p-4 border border-gray-300 rounded-lg overflow-auto">
                  <div className="shrink-0 overflow-hidden p-2">
                    <FileUploader
                      onChange={(e) => {
                        if (e.target.files) {
                          onUploadReceiptImage("INVOICE", e.target.files);
                        }
                      }}
                    />
                  </div>
                  {traderView?.kycDocs &&
                    traderView?.kycDocs
                      .filter((doc) => doc.type == "INVOICE" && doc.status != 0)
                      .map((image, i) => (
                        <div
                          className="shrink-0 overflow-hidden p-2 rounded relative"
                          style={{
                            width: "40%",
                            height: "8rem",
                          }}
                          key={`INVOICE-${i}`}
                        >
                          <a href={image.url} target="_blank">
                            <DocumentPreview
                              url={image.url}
                              docType={image.docType}
                            />
                          </a>
                          <span
                            className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                            style={{
                              background: "#ff0000de",
                            }}
                            onClick={() => {
                              onRemoveReceiptImage(image.url, true, image.id);
                            }}
                          >
                            <FaXmark style={{ color: "white" }} />
                          </span>
                        </div>
                      ))}
                  {kycDocs
                    .filter((doc) => doc.type == "INVOICE")
                    .map((image, i) => (
                      <div
                        className="shrink-0 overflow-hidden p-2 rounded relative"
                        style={{
                          width: "40%",
                          height: "8rem",
                        }}
                        key={`INVOICE-${i}`}
                      >
                        <a href={image.url} target="_blank">
                          <DocumentPreview
                            url={image.url}
                            docType={image.docType}
                          />
                        </a>
                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{
                            background: "#ff0000de",
                          }}
                          onClick={() => {
                            onRemoveReceiptImage(image.url);
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="flex mt-[16px]">
              <div className="mt-2">
                <div className="mb-1">
                  <span className="font-semibold">
                    Cheque Statement
                    <span className="color-red ml-1">{"*"}</span>
                  </span>
                </div>

                <div className="flex w-[600px] h-[170px] bg-white p-4 border border-gray-300 rounded-lg overflow-auto">
                  <div className="shrink-0 overflow-hidden p-2">
                    <FileUploader
                      onChange={(e) => {
                        if (e.target.files) {
                          onUploadReceiptImage("CHEQUE", e.target.files);
                        }
                      }}
                    />
                  </div>

                  {traderView?.kycDocs != null &&
                    traderView?.kycDocs
                      .filter((doc) => doc.type == "CHEQUE" && doc.status != 0)
                      .map((image, i) => (
                        <div
                          className="shrink-0 overflow-hidden p-2 rounded relative"
                          style={{
                            width: "40%",
                            height: "8rem",
                          }}
                          key={`CHEQUE-${i}`}
                        >
                          <a href={image.url} target="_blank">
                            <DocumentPreview
                              url={image.url}
                              docType={image.docType}
                            />
                          </a>
                          <span
                            className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                            style={{
                              background: "#ff0000de",
                            }}
                            onClick={() => {
                              onRemoveReceiptImage(image.url, true, image.id);
                            }}
                          >
                            <FaXmark style={{ color: "white" }} />
                          </span>
                        </div>
                      ))}
                  {kycDocs
                    .filter((doc) => doc.type == "CHEQUE")
                    .map((image, i) => (
                      <div
                        className="shrink-0 overflow-hidden p-2 rounded relative"
                        style={{
                          width: "40%",
                          height: "8rem",
                        }}
                        key={`CHEQUE-${i}`}
                      >
                        <a href={image.url} target="_blank">
                          <DocumentPreview
                            url={image.url}
                            docType={image.docType}
                          />
                        </a>
                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{
                            background: "#ff0000de",
                          }}
                          onClick={() => {
                            onRemoveReceiptImage(image.url);
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      </div>
                    ))}
                </div>
              </div>

              <div className="ml-[20px] mt-2">
                <div className="mb-1">
                  <span className="font-semibold">
                    Other Documents
                    <span className="color-red ml-1">{"*"}</span>
                  </span>
                </div>

                <div className="flex w-[746px] h-[170px] bg-white p-4 border border-gray-300 rounded-lg overflow-auto">
                  <div className="shrink-0 overflow-hidden p-2">
                    <FileUploader
                      onChange={(e) => {
                        if (e.target.files) {
                          onUploadReceiptImage("OTHER", e.target.files);
                        }
                      }}
                    />
                  </div>
                  {traderView?.kycDocs &&
                    traderView?.kycDocs
                      .filter((doc) => doc.type == "OTHER" && doc.status != 0)
                      .map((image, i) => (
                        <div
                          className="shrink-0 overflow-hidden p-2 rounded relative"
                          style={{
                            width: "40%",
                            height: "8rem",
                          }}
                          key={`OTHER-${i}`}
                        >
                          <a href={image.url} target="_blank">
                            <DocumentPreview
                              url={image.url}
                              docType={image.docType}
                            />
                          </a>
                          <span
                            className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                            style={{
                              background: "#ff0000de",
                            }}
                            onClick={() => {
                              onRemoveReceiptImage(image.url, true, image.id);
                            }}
                          >
                            <FaXmark style={{ color: "white" }} />
                          </span>
                        </div>
                      ))}
                  {kycDocs
                    .filter((doc) => doc.type == "OTHER")
                    .map((image, i) => (
                      <div
                        className="shrink-0 overflow-hidden p-2 rounded relative"
                        style={{
                          width: "40%",
                          height: "8rem",
                        }}
                        key={`OTHER-${i}`}
                      >
                        <a href={image.url} target="_blank">
                          <DocumentPreview
                            url={image.url}
                            docType={image.docType}
                          />
                        </a>
                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{
                            background: "#ff0000de",
                          }}
                          onClick={() => {
                            onRemoveReceiptImage(image.url);
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-b mt-2"></div>
      <div className="modal-header p-2 flex justify-between">
        <div>
          <Button
            text="CANCEL"
            className="w-[100px] ml-[10px]"
            variant="primaryLight"
            onClick={() => onClose()}
          />
        </div>
        <div>
          <Button
            text="SUBMIT"
            className="w-[100px] mr-[10px]"
            onClick={() => {
              if (traderView?.status === "REJECTED") {
                setRejectModal(true);
              } else if (traderView?.status === "HOLD") {
                setHoldModal(true);
              } else if (traderView?.status === "BLOCKED") {
                setBlockModal(true);
              } else if (
                traderView?.status === "ACTIVE" ||
                traderView?.status === "UNDER REVIEW"
              ) {
                onSubmit();
              }
            }}
          />
        </div>
      </div>
      {rejectModal && (
        <div>
          <Modal
            open={rejectModal}
            onClose={() => {
              setRejectModal(false);
            }}
            width={isMobile ? "100%" : "40vw"}
          >
            <div>
              <ModalHeader title={"Trader Status: Rejected"} content={<></>} />
              <div>
                <div className=" pt-2">
                  <span className="text-sm px-6">
                    Please provide a reason: (for the customer)
                  </span>

                  <div className="px-6 pt-1">
                    <textarea
                      value={traderView?.externelComments}
                      onChange={(e) => {
                        setTraderView({
                          ...traderView!,
                          externelComments: e.target.value,
                        });
                      }}
                      className="h-[200px] w-[100%] p-2 border border-gray br-10"
                    />
                  </div>
                </div>

                <div className="border-b px-2 py-2"></div>
                <div className="modal-header p-4 flex justify-between items-center">
                  <div>
                    <Button
                      text="CANCEL"
                      className="w-[100px] ml-[10px]"
                      variant="primaryLight"
                      onClick={() => {
                        setRejectModal(false);
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      text="SUBMIT"
                      className="w-[100px] mr-[10px]"
                      onClick={() => {
                        onSubmit();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}

      {holdModal && (
        <div>
          <Modal
            open={holdModal}
            onClose={() => {
              setHoldModal(false);
            }}
            width={isMobile ? "100%" : "40vw"}
          >
            <>
              <ModalHeader title={"Trader Status: On Hold"} content={<></>} />
              <div>
                <div className="pt-2">
                  <span className="text-sm px-6">
                    Please provide a reason: (for the customer)
                  </span>

                  <div className="px-6 pt-1">
                    <textarea
                      value={traderView?.externelComments}
                      onChange={(e) => {
                        setTraderView({
                          ...traderView!,
                          externelComments: e.target.value,
                        });
                      }}
                      className="h-[100px] w-[100%] p-2 border border-gray br-10"
                    />
                  </div>

                  <span className="px-8 text-sm">
                    Select Documents for Resubmission
                  </span>

                  <div className="grid grid-cols-2 px-6">
                    {documentNames.map((doc) => (
                      <div key={doc.key} className="ml-2 flex cursor-pointer">
                        <div>
                          <Checkbox
                            checked={checked.includes(doc.key)}
                            onChange={() => handleChecked(doc.key)}
                          />
                        </div>
                        <div className="ml-1 mt-2">{doc.name}</div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="border-b px-2 py-2"></div>
                <div className="modal-header p-4 flex justify-between items-center">
                  <div>
                    <Button
                      text="CANCEL"
                      className="w-[100px] ml-[10px]"
                      variant="primaryLight"
                      onClick={() => {
                        setHoldModal(false);
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      text="SUBMIT"
                      className="w-[100px] mr-[10px]"
                      onClick={() => {
                        onSubmit();
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          </Modal>
        </div>
      )}

      {blockModal && (
        <div>
          <Modal
            open={blockModal}
            onClose={() => {
              setBlockModal(false);
            }}
            width={isMobile ? "100%" : "40vw"}
          >
            <>
              <ModalHeader title={"Trader Status: Blocked"} content={<></>} />
              <div>
                <div className="pt-2">
                  <span className="text-sm px-6">
                    Please provide a reason: (for the customer)
                  </span>

                  <div className="px-6 pt-1">
                    <textarea
                      value={traderView?.externelComments}
                      onChange={(e) => {
                        setTraderView({
                          ...traderView!,
                          externelComments: e.target.value,
                        });
                      }}
                      className="h-[200px] w-[100%] p-2 border border-gray br-10"
                    />
                  </div>
                </div>

                <div className="border-b px-2 py-2"></div>
                <div className="modal-header p-4 flex justify-between items-center">
                  <div>
                    <Button
                      text="CANCEL"
                      className="w-[100px] ml-[10px]"
                      variant="primaryLight"
                      onClick={() => {
                        setBlockModal(false);
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      text="SUBMIT"
                      className="w-[100px] mr-[10px]"
                      onClick={() => {
                        onSubmit();
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          </Modal>
        </div>
      )}

      <Modal
        open={payoutModal}
        onClose={() => {
          openPayoutModal(false);
        }}
        width="80vw"
      >
        <AddPayoutAccount
          onClose={() => {
            openPayoutModal(false);
          }}
        />
      </Modal>
    </div>
  );
};
export default AddTrader;
