import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TApplicationViewDetails,
  TBeneficiaryDetails,
  TBillsInfo,
  TBillView,
  TDisbRecordPayoutData,
  TPdfSettings,
  TRecordPayoutData,
} from "../types";
import moment from "moment";

type TinitialState = {
  applicationViewDetails: TApplicationViewDetails | null;
  pdfSettings: TPdfSettings[];
  billsView: TBillView | null;
  billsInfo: TBillsInfo | null;
  beneficiaryDetails: TBeneficiaryDetails | null;
  appliViewById: TApplicationViewDetails | null;
  reviewedComment: string;
  verifyComment: string;
  approveComment: string;
  disburseComment: string;
  rejectComment: string;
  payoutCurrentStep: number;
  disRecordPayoutData: TDisbRecordPayoutData;
  loanModalSuccessStatus: boolean;
};
const initialState: TinitialState = {
  applicationViewDetails: null,
  pdfSettings: [],
  billsView: null,
  billsInfo: null,
  beneficiaryDetails: null,
  appliViewById: null,
  reviewedComment: "",
  verifyComment: "",
  approveComment: "",
  disburseComment: "",
  rejectComment: "",
  payoutCurrentStep: 1,
  disRecordPayoutData: {
    partyId: "",
    disbDate: "",
    paymentType: "",
    pmtMode: "",
    comments: "",
    paymentTypeVal: "",
    requestAdv: 0,
    pmtReceiptDate: "",
    desc: "",
    utr: "",
    inOutWard: "",
    fundAccId: "",
    accountNumber: "",
    otpValidated: false,
    otp: "",
    refId: "",
    id: 0,
    availableBalance: 0,
    disbAmt: "",
    selectedBills: [],
    tenure: "",
  },
  loanModalSuccessStatus: false,
};
const tradeSlice = createSlice({
  name: "loans",
  initialState: initialState,
  reducers: {
    updateApplicationDetails: (
      state,
      action: PayloadAction<{
        applicationViewDetails: TApplicationViewDetails | null;
      }>
    ) => {
      state.applicationViewDetails = action.payload.applicationViewDetails;
    },
    setPdfSettings: (state, action: PayloadAction<TPdfSettings[]>) => {
      state.pdfSettings = action.payload;
    },

    updateBillsView: (
      state,
      action: PayloadAction<{
        billsView: TBillView | null;
      }>
    ) => {
      state.billsView = action.payload.billsView;
    },

    updateBillsInfo: (
      state,
      action: PayloadAction<{
        billsInfo: TBillsInfo | null;
      }>
    ) => {
      state.billsInfo = action.payload.billsInfo;
    },

    updateBeneficiaryDetai: (
      state,
      action: PayloadAction<{
        beneficiaryDetails: TBeneficiaryDetails | null;
      }>
    ) => {
      state.beneficiaryDetails = action.payload.beneficiaryDetails;
    },

    updateAppliViewById: (
      state,
      action: PayloadAction<{
        appliViewById: TApplicationViewDetails | null;
      }>
    ) => {
      state.appliViewById = action.payload.appliViewById;
    },
    setReviewComment: (state, action: PayloadAction<string>) => {
      state.reviewedComment = action.payload;
    },
    setVerifyComment: (state, action: PayloadAction<string>) => {
      state.verifyComment = action.payload;
    },
    setApproveComment: (state, action: PayloadAction<string>) => {
      state.approveComment = action.payload;
    },
    setDisburseComment: (state, action: PayloadAction<string>) => {
      state.disburseComment = action.payload;
    },
    setRejectComment: (state, action: PayloadAction<string>) => {
      state.rejectComment = action.payload;
    },
    clearComments: (state) => {
      state.reviewedComment = "";
      state.verifyComment = "";
      state.approveComment = "";
      state.disburseComment = "";
      state.rejectComment = "";
    },
    setDisRecordPayoutData: (
      state,
      action: PayloadAction<{
        partyId: number;
        inOutWard: string;
        accountNumber: string;
        fundAccId: string;
      }>
    ) => {
      state.disRecordPayoutData = {
        partyId: action.payload.partyId,
        disbDate: moment().format("YYYY-MM-DD"),
        pmtMode: "",
        paymentType: "",
        comments: "",
        paymentTypeVal: "",
        requestAdv: 0,
        pmtReceiptDate: "",
        desc: "",
        utr: "",
        inOutWard: action.payload.inOutWard,
        accountNumber: action.payload.accountNumber,
        fundAccId: action.payload.fundAccId,
        otpValidated: false,
        otp: "",
        refId: "",
        id: 0,
        availableBalance: 0,
        disbAmt: "",
        selectedBills: [],
        tenure: "",
      };
      state.payoutCurrentStep = 1;
    },
    updateDisRecordPayoutData: (state, action) => {
      state.disRecordPayoutData = action.payload;
    },
    updateLoanSuccess: (state, action) => {
      state.loanModalSuccessStatus = action.payload;
    },
  },
});

export const {
  updateApplicationDetails,
  setPdfSettings,
  updateBillsView,
  updateBillsInfo,
  updateBeneficiaryDetai,
  updateAppliViewById,
  setReviewComment,
  setVerifyComment,
  setApproveComment,
  setDisburseComment,
  setRejectComment,
  clearComments,
  setDisRecordPayoutData,
  updateDisRecordPayoutData,
  updateLoanSuccess,
} = tradeSlice.actions;
export default tradeSlice.reducer;
