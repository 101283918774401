import { FC, useEffect, useState } from "react";
import usePayoutStep2 from "./usePayoutStep2";
import { getCurrencyNumberWithSymbol, getMaskedMobileNumber } from "../../../../helpers/get-currency-number";
import moment from "moment";
import user from "../../../../assets/images/single_bill.svg";
import onoPayout from "../../../../assets/images/ono_payout.svg";
import { getPaymentTypeName, maskAccountNumber } from "../../../../helpers/getText";
import Button from "../../../../components/ui/button";
import Input from "../../../../components/ui/input";
import useRecordPayout from "../useRecordPayout";
import { onoArkAccount } from "../../../../constants";


const PayoutStep2: FC<{
}> = ({ }) => {

    const {
        tradeDetail,
        recordPayoutData,
        setOtp,
        onVerifyOtp,
    } = usePayoutStep2();


    const {
        sendOtp
    } = useRecordPayout();

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className="flex pb-2">
            <div
                className="grow-[4] overflow-y-auto"
                style={{
                    height: isMobile ? "calc(100vh - 22rem)" : "calc(100vh - 12rem)",
                }}
            >
                <div className="border-primary p-4 br-10 m-4">
                    <div className="block md:flex border-b pb-3 justify-between">
                        <div className="flex border-b md:border-none pb-2 md:pb-0 w-[50%]">
                            <img src={onoPayout} className="w-12 h-12 br-100" />
                            <div className="ml-2 flex flex-col">
                                <span className="font-semibold text-sm">
                                    {onoArkAccount.accountName}
                                </span>
                                <span className="text-sm font-light">
                                    {maskAccountNumber(onoArkAccount.accountNumber)}
                                </span>
                                <span className="text-sm font-light">
                                    {onoArkAccount.bankName}
                                </span>
                            </div>
                        </div>
                        <div className="flex border-b md:border-none pb-2 md:pb-0">
                            <img src={user} className="w-12 h-12 br-100" />
                            <div className="ml-2 flex flex-col">
                                <span className="font-semibold text-sm">
                                    To : {tradeDetail?.srcTradeName}
                                </span>
                                <span className="text-sm font-light">
                                    Trader-
                                    {tradeDetail?.srcPartyId}{" "}
                                    |{" "}
                                    {getMaskedMobileNumber(tradeDetail?.srcMobile || ""
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="text-sm border-b py-3">
                        <span className="text-sm font-light">Date</span>
                        <p className="font-semibold text-sm">
                            {moment(recordPayoutData?.paymentDate).format("DD-MMM-YYYY")}
                        </p>
                    </div>
                    <div className="flex text-sm border-b py-3 justify-between">
                        <div className="flex flex-col">
                            <span className="font-light">Account Number</span>
                            <p className="font-semibold">{maskAccountNumber(recordPayoutData?.accountNumber)}</p>
                        </div>
                        <div className="flex flex-col">
                            <span className="font-light">Fund Account ID</span>
                            <p className="font-semibold">
                                {recordPayoutData?.fundAccId}
                            </p>
                        </div>
                        <div className="flex flex-col"></div>
                    </div>
                    <div className="flex text-sm border-b py-3 justify-between">
                        <div className="flex flex-col">
                            <span className="font-light">Amount</span>
                            <p className="font-semibold color-red">{getCurrencyNumberWithSymbol(Number(recordPayoutData?.paidAmount))}</p>
                        </div>
                        <div className="flex flex-col"></div>
                    </div>
                    <div className="flex text-sm border-b justify-between py-3">
                        <div className="flex flex-col">
                            <span className="font-light">Payment Type</span>
                            <p className="font-semibold">{getPaymentTypeName(recordPayoutData?.paymentType)}</p>
                        </div>
                        <div className="flex flex-col">
                            <span className="font-light">Mode </span>
                            <p className="font-semibold">{recordPayoutData?.paymentMode}</p>
                        </div>
                    </div>
                    <div className="flex text-sm flex-col py-3">
                        <span className="font-light">Comments</span>
                        <p className="font-semibold">
                            {recordPayoutData?.comments || "-"}
                        </p>
                    </div>
                </div>
                <div className="text-md font-semibold border-b px-5 py-2">
                    OTP Verification
                </div>
                <div className="flex justify-between px-5 py-2 mt-2">
                    <div className="flex flex-col grow-[3] mr-5">
                        <div className="flex justify-between">
                            <div className="flex flex-col text-sm font-semibold">Enter OTP *</div>
                            {
                                recordPayoutData.refId.length == 0 &&
                                <div className="flex flex-col text-sm font-medium color-blue cursor-pointer"
                                    onClick={() => {
                                        sendOtp(true);
                                    }}>
                                    Resend OTP
                                </div>
                            }
                        </div>
                        <div>
                            <Input
                                type="number"
                                label={""}
                                value={recordPayoutData.otp}
                                disabled={recordPayoutData.refId.length > 0}
                                onChange={(e) => {
                                    setOtp(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col grow-[1]">
                        <span className="h-[25px]">{""}</span>
                        <Button
                            variant={`${recordPayoutData.refId.length > 0 ? 'contained' : 'outlined-primary'}`}
                            disabled={recordPayoutData.refId.length > 0}
                            onClick={() => {
                                onVerifyOtp();
                            }}
                        >
                            {recordPayoutData.refId.length > 0 ? 'VERIFIED' : 'VERIFY'}
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PayoutStep2;