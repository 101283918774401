import { FaBackspace, FaEdit } from "react-icons/fa";
import { FaBackward } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card } from "../../../components/ui";
import CardTitleBg from "../../../components/ui/cardTitleBg";
import rupee from "../../../assets/images/rupee.svg";
import user from "../../../assets/images/single_bill.svg";
import DocumentPreview from "../../../components/commons/document-preview";
import Modal from "../../../components/ui/modal";
import { useEffect, useState } from "react";
import ModalHeader from "../../../components/ui/modal/modal-header";
import EditBorrower from "./edit-borrower";
import { ArrowBack } from "@mui/icons-material";
import useBorrowerDetailsById from "../../../hooks/useBorrowerDetailsById";
import PartnerInfo from "../../../components/partner-info";
import {
  getCurrencyNumberWithOutSymbol,
  getCurrencyNumberWithSymbol,
  getMaskedMobileNumber,
} from "../../../helpers/get-currency-number";
import UserAccountStatusString from "../CustomerDetails/status-string";
import { getAccountStatusDisplayIcon } from "../../../helpers/getText";
import moment from "moment";
import DocumentViewer from "../../../components/commons/document-preview";
import PayoutDetails from "../CustomerDetails/PayoutDetails/PayoutDetails";
import { setPayoutDetails } from "../../../reducers/tradeSlice";
import { useDispatch } from "react-redux";
const BorrowerDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [editBorrowerModal, setEditBorrowerModal] = useState(false);
  const {
    borrowerDetails,
    getBorrowerDetail,
    role,
    analyseEvent,
    platformType,
  } = useBorrowerDetailsById({
    cpId: parseInt(params.cpId || "0"),
    type: params.type || "",
    fromBorrowerPAge: true,
  });
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    getBorrowerDetail();
  }, [params, refresh]);
  return (
    <>
      <div className="container mx-auto py-4">
        <div
          onClick={() => {
            navigate("/customers");
          }}
          className="flex gap-2 border-b pb-3 font-medium cursor-pointer"
          style={{ alignItems: "center" }}
        >
          <ArrowBack />
          <span>{borrowerDetails?.fullName}</span>
        </div>
        <div
          className="flex overflow-y-auto gap-4"
          style={{ height: "calc(100vh - 9rem)" }}
        >
          <div className="w-[40%]">
            {/* borrower details */}
            <div>
              <CardTitleBg
                titile={
                  <div className="flex items-center">
                    <span>Borrowers Details</span>
                    {role === "ADMIN" && (
                      <Button
                        variant="transparent"
                        className="ml-auto gap-1"
                        size="small"
                        onClick={() => {
                          setEditBorrowerModal(true);
                        }}
                      >
                        <FaEdit className="" />
                        Edit
                      </Button>
                    )}
                  </div>
                }
                titileClassName="px-4"
                className="bg-gray-50 mt-4"
              >
                <div className="px-4 py-2">
                  <div className="flex justify-between gap-2 py-3  border-b">
                    <div className="" style={{ width: "66%" }}>
                      <PartnerInfo
                        profilePic=""
                        name={borrowerDetails?.fullName || ""}
                        shortName={""}
                        partnerType={"CASH ID "}
                        partnerId={borrowerDetails?.cpId}
                        partnerMobile={borrowerDetails?.mobile || ""}
                        partyName={borrowerDetails?.addressLine}
                      />
                    </div>

                    <div className="flex-1">
                      <div className="text-sm color-sub-text">
                        ONO Risk Score (ORS)
                      </div>
                      <div className="font-semibold text-sm">
                        {borrowerDetails?.onoScore || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-2 py-3  border-b">
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">Cash Limit</div>
                      <div className="font-semibold text-sm">
                        {" "}
                        {getCurrencyNumberWithSymbol(
                          borrowerDetails?.eligibleLimit
                        ) || "-"}
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">
                        Available Limit
                      </div>
                      <div className="font-semibold text-sm">
                        {getCurrencyNumberWithSymbol(
                          borrowerDetails?.availableLimit
                        ) || "-"}
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">Renewal Date</div>
                      <div className="font-semibold text-sm">
                        {borrowerDetails?.renewDate
                          ? moment(borrowerDetails?.renewDate).format(
                              "DD-MMM-YYYY"
                            )
                          : "-"}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-2 py-3  border-b">
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">
                        Beneficiaries
                      </div>
                      <div className="font-semibold text-sm">
                        {borrowerDetails?.activeBenifs || "0"}
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">Total Loans</div>
                      <div className="font-semibold text-sm color-blue">
                        {borrowerDetails?.totalLoans}
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">Active Loans</div>
                      <div className="font-semibold text-sm color-blue">
                        {borrowerDetails?.activeLoans}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-2 py-3  border-b">
                    <div className="" style={{ width: "66%" }}>
                      <div className="text-sm color-sub-text">
                        Interest Rate (%)
                      </div>
                      <div className="font-semibold text-sm">
                        {borrowerDetails?.intrestRate || "-"}
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">
                        Tenure Period
                      </div>
                      <div className="font-semibold text-sm">
                        {borrowerDetails?.tenure + "Days" || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-2 py-3  ">
                    <div className="" style={{ width: "66%" }}>
                      <div className="text-sm color-sub-text">Comments</div>
                      <div className="font-semibold text-sm">
                        {borrowerDetails?.comments || "-"}
                      </div>
                    </div>
                    <div className="flex-1" style={{ width: "34%" }}>
                      <div className="text-sm color-sub-text">Status</div>
                      <div className="font-semibold text-sm mt-2">
                        <UserAccountStatusString
                          statusIcon={getAccountStatusDisplayIcon({
                            status: borrowerDetails?.status,
                          })}
                        />
                        {/* {borrowerDetails?.status || "-"} */}
                      </div>
                    </div>
                  </div>
                </div>
              </CardTitleBg>
              {params.type === "CLICK" && (
                <CardTitleBg
                  titile={
                    <div className="flex items-center">
                      <span>Borrower Risk Profile</span>
                    </div>
                  }
                  titileClassName="px-4"
                  className="bg-gray-50 mt-4"
                >
                  <div className="px-4 py-2">
                    <div className="flex justify-between gap-2 py-3  border-b">
                      <div className="flex-1 flex gap-3">
                        <img src={rupee} className="w-8 h-8" />
                        <div>
                          <div className="text-sm color-sub-text">
                            Total Bills
                          </div>
                          <div className="font-semibold text-sm">
                            {borrowerDetails?.totalBills || "-"}
                          </div>
                        </div>
                      </div>
                      <div className="flex-1">
                        <div className="text-sm color-sub-text">
                          Bill Amount
                        </div>
                        <div className="font-semibold text-sm color-primary">
                          {getCurrencyNumberWithSymbol(
                            borrowerDetails?.totalBillsAmount
                          ) || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between gap-2 py-3  border-b">
                      <div className="flex-1 flex gap-3">
                        <img src={rupee} className="w-8 h-8" />
                        <div>
                          <div className="text-sm color-sub-text">
                            Total Buy Bills
                          </div>
                          <div className="font-semibold text-sm">
                            {borrowerDetails?.buyBills || "-"}
                          </div>
                        </div>
                      </div>
                      <div className="flex-1">
                        <div className="text-sm color-sub-text">
                          Bill Amount
                        </div>
                        <div className="font-semibold text-sm color-red">
                          {getCurrencyNumberWithSymbol(
                            borrowerDetails?.buyBillAmt
                          ) || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between gap-2 py-3  border-b">
                      <div className="flex-1 flex gap-3">
                        <img src={rupee} className="w-8 h-8" />
                        <div>
                          <div className="text-sm color-sub-text">
                            Total Sell Bills
                          </div>
                          <div className="font-semibold text-sm">
                            {" "}
                            {borrowerDetails?.sellBills || "-"}
                          </div>
                        </div>
                      </div>
                      <div className="flex-1">
                        <div className="text-sm color-sub-text">
                          Bill Amount
                        </div>
                        <div className="font-semibold text-sm color-primary">
                          {getCurrencyNumberWithSymbol(
                            borrowerDetails?.sellBillAmt
                          ) || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="py-3">
                      <div className="text-sm pb-3">Connected Partners</div>
                      <div className="flex gap-2">
                        <div className="flex-1 borde-r">
                          <div className="text-sm color-sub-text">
                            Total Partners
                          </div>
                          <div className="font-semibold text-sm">
                            {borrowerDetails?.totalPartners || "-"}
                          </div>
                        </div>

                        <div className="flex-1">
                          <div className="text-sm color-sub-text">Sellers</div>
                          <div className="font-semibold text-sm">
                            {borrowerDetails?.partnerObjects?.seller || "-"}
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="text-sm color-sub-text">Buyers</div>
                          <div className="font-semibold text-sm">
                            {" "}
                            {borrowerDetails?.partnerObjects?.buyer || "-"}
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="text-sm color-sub-text">
                            Transporters
                          </div>
                          <div className="font-semibold text-sm">
                            {" "}
                            {borrowerDetails?.partnerObjects?.transporter ||
                              "-"}
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="text-sm color-sub-text">Coolies</div>
                          <div className="font-semibold text-sm">
                            {" "}
                            {borrowerDetails?.partnerObjects?.labor || "-"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardTitleBg>
              )}
              <CardTitleBg
                titile={
                  <div className="flex items-center">
                    <span>Mandi Details</span>
                  </div>
                }
                titileClassName="px-4"
                className="bg-gray-50 mt-4"
              >
                <div className="px-4 py-2">
                  <div className="flex justify-between gap-2 py-3">
                    <div className="flex-1 flex gap-3">
                      <img src={user} className="w-8" />
                      <div>
                        <div className="text-sm color-sub-text">Owner Name</div>
                        <div className="font-semibold text-sm">
                          {borrowerDetails?.fullName || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">
                        Mandi Short Code
                      </div>
                      <div className="font-semibold text-sm">TM</div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-2 py-3 ">
                    <div className="flex-1 flex gap-3">
                      <img src={user} className="w-8" />
                      <div>
                        <div className="text-sm color-sub-text">
                          Mandi Address
                        </div>
                        <div className="font-semibold text-sm">
                          {borrowerDetails?.addressLine}
                          {borrowerDetails?.dist},{borrowerDetails?.state}{" "}
                          {borrowerDetails?.pincode}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-2 py-3 ">
                    <div className="flex-1 flex gap-3">
                      <img src={user} className="w-8" />
                      <div>
                        <div className="text-sm color-sub-text">
                          Market Name
                        </div>
                        <div className="font-semibold text-sm">
                          {borrowerDetails?.marketName || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">Shop Number</div>
                      <div className="font-semibold text-sm">
                        {borrowerDetails?.shopNum || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-2 py-3 ">
                    <div className="flex-1 flex gap-3">
                      <div style={{ marginLeft: "43px" }}>
                        <div className="text-sm color-sub-text">Mandi Name</div>
                        <div className="font-semibold text-sm">
                          {borrowerDetails?.businessName || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">Mandi Type</div>
                      <div className="font-semibold text-sm">
                        {borrowerDetails?.businessType || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-2 py-3 border-b">
                    <div className="flex-1 flex gap-3">
                      <div style={{ marginLeft: "43px" }}>
                        <div className="text-sm color-sub-text">
                          Contact Number
                        </div>
                        <div className="font-semibold text-sm">
                          {borrowerDetails?.busMobile || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">
                        Alternative Mobile
                      </div>
                      <div className="font-semibold text-sm">
                        {" "}
                        {borrowerDetails?.busAltMobile || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-2 py-3 ">
                    <div className="flex-1 flex gap-3">
                      <img src={user} className="w-8" />
                      <div>
                        <div className="text-sm color-sub-text">
                          Relationship Manager
                        </div>
                        <div className="font-semibold text-sm">
                          {borrowerDetails?.rmName || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">
                        RM Mobile Number
                      </div>
                      <div className="font-semibold text-sm">
                        {" "}
                        {borrowerDetails?.rmMobile || "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </CardTitleBg>
              <PayoutDetails
                payouts={borrowerDetails?.payAcc || []}
                onAddAcccount={() => {
                  dispatch(
                    setPayoutDetails({
                      traderId: borrowerDetails?.cpId!,
                      traderType: "",
                      contactNumber: borrowerDetails?.mobile!,
                      emailNumber: "",
                      isApiCall: true,
                      type: "BORROWER",
                    })
                  );
                }}
                onSubmit={() => {
                  setRefresh(!refresh);
                  // onSubmit();
                }}
                fromBorrower={true}
              />
            </div>
          </div>
          <div className="w-[60%]">
            <CardTitleBg
              titile={
                <div className="flex items-center">
                  <span>Documents</span>
                </div>
              }
              titileClassName="px-4"
              className="bg-gray-50 mt-4"
            >
              <div className="px-4 py-2">
                <div className="flex flex-col justify-between gap-2 py-3 ">
                  <div className="pb-2">
                    <h2 className="color-text-primary pb-2 border-b text-sm">
                      Aadhar Card
                    </h2>
                    {borrowerDetails?.adhrName ? (
                      <div className="flex gap-4 py-4">
                        <div
                          className="shrink-0 overflow-hidden border p-2 rounded relative"
                          style={{
                            width: "20%",
                            height: "8rem",
                          }}
                          key={`arival-doc`}
                        >
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={borrowerDetails?.photo}
                          >
                            <DocumentViewer
                              docType="image"
                              url={borrowerDetails?.photo || ""}
                            />
                          </a>
                        </div>
                        <div
                          className="border p-2 flex gap-4 justify-center flex-col rounded relative"
                          style={{
                            width: "100%",
                            height: "8rem",
                          }}
                        >
                          <div className="flex">
                            <div className="flex-1">
                              <div className="text-sm color-sub-text">Name</div>
                              <div className="font-medium text-sm">
                                {borrowerDetails?.adhrName
                                  ? borrowerDetails?.adhrName
                                  : "-"}{" "}
                              </div>
                            </div>

                            <div className="flex-1">
                              <div className="text-sm color-sub-text">
                                Aadhaar Number
                              </div>
                              <div className="font-medium text-sm">
                                {borrowerDetails?.aadhar
                                  ? borrowerDetails?.aadhar
                                  : "-"}{" "}
                              </div>
                            </div>

                            <div className="flex-1">
                              <div className="text-sm color-sub-text">
                                Mobile Number
                              </div>
                              <div className="font-medium text-sm">
                                {borrowerDetails?.adhrMobile
                                  ? borrowerDetails?.adhrMobile
                                  : "-"}{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-wrap gap-4 py-4">
                        {/* Aadhar Front */}
                        {borrowerDetails?.kycDocs.filter(
                          (doc) => doc.docType === "AADHAR_FRONT"
                        ).length! > 0 ? (
                          borrowerDetails?.kycDocs
                            .filter((doc) => doc.docType === "AADHAR_FRONT")
                            .map((item, i) => (
                              <div
                                className="shrink-0 overflow-hidden border p-2 rounded relative flex items-center"
                                style={{
                                  width: "30%",
                                  height: "8rem",
                                }}
                                key={`aadhar-front-${i}`} // Use unique keys
                              >
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={item.docUrl}
                                >
                                  <DocumentPreview
                                    url={item.docUrl}
                                    docType={item.type}
                                  />
                                </a>
                              </div>
                            ))
                        ) : (
                          <div
                            className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                            style={{
                              width: "40%",
                              height: "8rem",
                            }}
                          >
                            Not Uploaded Aadhar
                          </div>
                        )}

                        {/* Aadhar Back */}
                        {borrowerDetails?.kycDocs.filter(
                          (doc) => doc.docType === "AADHAR_BACK"
                        ).length! > 0 &&
                          borrowerDetails?.kycDocs
                            .filter((doc) => doc.docType === "AADHAR_BACK")
                            .map((item, i) => (
                              <div
                                className="shrink-0 overflow-hidden border p-2 rounded relative flex items-center"
                                style={{
                                  width: "30%",
                                  height: "8rem",
                                }}
                                key={`aadhar-back-${i}`} // Use unique keys
                              >
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={item.docUrl}
                                >
                                  <DocumentPreview
                                    url={item.docUrl}
                                    docType={item.type}
                                  />
                                </a>
                              </div>
                            ))}
                      </div>
                    )}
                  </div>
                  <div>
                    <h2 className="color-text-primary pb-2 border-b text-sm">
                      Permanent Account Number(PAN)
                    </h2>
                    <div className="flex gap-4 py-4">
                      {borrowerDetails?.kycDocs.filter(
                        (doc) => doc.docType == "PAN_CARD"
                      ).length! > 0 ? (
                        <>
                          {borrowerDetails?.kycDocs
                            .filter((doc) => doc.docType == "PAN_CARD")
                            .map((item, i) => (
                              <div
                                className="shrink-0 overflow-hidden border p-2 rounded relative"
                                style={{
                                  width: "30%",
                                  height: "8rem",
                                }}
                                key={`arival-doc`}
                              >
                                <a target="_blank" href={item.docUrl}>
                                  <DocumentPreview
                                    url={item.docUrl}
                                    docType={item.type}
                                  />
                                </a>
                              </div>
                            ))}
                        </>
                      ) : (
                        <div
                          className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                          style={{
                            width: "40%",
                            height: "8rem",
                          }}
                        >
                          Not Uploaded PAN
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <h2 className="color-text-primary pb-2 border-b text-sm">
                      Bank Statements
                    </h2>
                    <div className="py-4">
                      <div
                        className="border overflow-hidden bg-white"
                        style={{
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      >
                        <div className="flex bg-light-primary text-sm font-medium py-2">
                          <div
                            className="text-center px-2"
                            style={{ flexBasis: "4rem", width: "5%" }}
                          >
                            #
                          </div>
                          <div
                            className="grow-[1.5] basis-0 px-2"
                            style={{ width: "20%" }}
                          >
                            Date Submitted
                          </div>
                          <div
                            className="grow-[2] basis-0 px-2 flex"
                            style={{ width: "25%" }}
                          >
                            File Name
                          </div>
                          <div
                            className="grow-[2] basis-0 px-2"
                            style={{ width: "25%" }}
                          >
                            Analysis Report
                          </div>
                          <div
                            className="grow-[2] basis-0 px-2"
                            style={{ width: "10%" }}
                          ></div>
                        </div>

                        {borrowerDetails?.kycDocs
                          .filter((doc) => doc.docType == "BANK_STATEMENT")
                          .map((item, i) => (
                            <div className="flex border-b text-sm py-2 cursor-pointer items-center">
                              <span
                                className="text-center px-2"
                                style={{ flexBasis: "4rem" }}
                              >
                                {i + 1}
                              </span>
                              <span
                                className="grow-[1.5] basis-0 flex px-2 text-sm"
                                style={{ width: "20%" }}
                              >
                                {"5-Mar-2024"}
                              </span>
                              <div
                                className="grow-[2] basis-0 flex flex-col px-2 text_overflow"
                                style={{ width: "25%" }}
                              >
                                <span className="text-sm color-blue">
                                  <a href={item.docUrl} target="_blank">
                                    {" "}
                                    {item.docUrl}
                                  </a>
                                </span>
                              </div>
                              <div
                                className="grow-[2] basis-0 px-2 color-blue text_overflow"
                                style={{ width: "25%" }}
                              >
                                <a href={item.reportUrl} target="_blank">
                                  {item.reportUrl || "-"}
                                </a>
                              </div>
                              <span
                                className="grow-[2] basis-0 px-2"
                                style={{ width: "10%" }}
                              >
                                {i == 0 && role === "ADMIN" && (
                                  <Button
                                    variant="contained"
                                    className=""
                                    size="small"
                                    onClick={() => {
                                      analyseEvent();
                                    }}
                                  >
                                    Analyse
                                  </Button>
                                )}
                              </span>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div>
                    <h2 className="color-text-primary pb-2 border-b text-sm">
                      ITR Document
                    </h2>
                    <div className="flex gap-4 py-4">
                      {borrowerDetails?.kycDocs.filter(
                        (doc) => doc.docType == "ITR_STATEMENT"
                      ).length! > 0 ? (
                        <>
                          {borrowerDetails?.kycDocs
                            .filter((doc) => doc.docType == "ITR_STATEMENT")
                            .map((item, i) => (
                              <div
                                className="shrink-0 overflow-hidden border p-2 rounded relative"
                                style={{
                                  width: "20%",
                                  height: "8rem",
                                }}
                                key={`arival-doc`}
                              >
                                <a target="_blank" href={item.docUrl}>
                                  <DocumentPreview
                                    url={item.docUrl}
                                    docType={item.type}
                                  />
                                </a>
                              </div>
                            ))}
                        </>
                      ) : (
                        <div
                          className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                          style={{
                            width: "20%",
                            height: "8rem",
                          }}
                        >
                          Not Uploaded ITR
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <h2 className="color-text-primary pb-2 border-b text-sm">
                      Trade License
                    </h2>
                    <div className="flex gap-4 py-4">
                      {borrowerDetails?.kycDocs.filter(
                        (doc) => doc.docType == "TRADE_LICENSE"
                      ).length! > 0 ? (
                        <>
                          {borrowerDetails?.kycDocs
                            .filter((doc) => doc.docType == "TRADE_LICENSE")
                            .map((item, i) => (
                              <div
                                className="shrink-0 overflow-hidden border p-2 rounded relative"
                                style={{
                                  width: "20%",
                                  height: "8rem",
                                }}
                                key={`arival-doc`}
                              >
                                <a target="_blank" href={item.docUrl}>
                                  <DocumentPreview
                                    url={item.docUrl}
                                    docType={item.type}
                                  />
                                </a>
                              </div>
                            ))}
                        </>
                      ) : (
                        <div
                          className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                          style={{
                            width: "20%",
                            height: "8rem",
                          }}
                        >
                          Not Uploaded TRADE_LICENSE
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </CardTitleBg>
          </div>
        </div>
      </div>
      {editBorrowerModal && (
        <Modal
          open={editBorrowerModal}
          onClose={() => {
            setEditBorrowerModal(false);
          }}
          width="80vw"
        >
          <>
            <ModalHeader title={"Edit Borrower"} content={<></>} />
            <EditBorrower
              onEditBorrowerModalClose={setEditBorrowerModal}
              type={"TRADER"}
            />
          </>
        </Modal>
      )}
    </>
  );
};
export default BorrowerDetails;
