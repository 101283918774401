
export const getPaymentStatus = (status: string) => {
    let text: string = '';
    switch (status) {
        case 'INITIATED':
            text = 'Initiated'
            break;
        case 'CANCELLED':
            text = 'Cancelled'
            break;
        case 'REVERTED':
            text = 'Reverted'
            break;
        case 'FAILED':
            text = 'Failed'
            break;
        case 'ACCEPTED':
        case 'PROCESSED':
            text = 'Processed'
            break;
        default:
            break;
    }
    return text;
}

export const getPaymentStatusColor = (status: string) => {
    let text: string = '';
    switch (status) {
        case 'INITIATED':
            text = 'color-orange'
            break;
        case 'CANCELLED':
        case 'FAILED':
            text = 'color-red'
            break;
        case 'REVERTED':
            text = 'color-blue'
            break;
        case 'ACCEPTED':
        case 'PROCESSED':
            text = 'color-primary'
            break;
        default:
            break;
    }
    return text;
}