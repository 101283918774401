import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAxiosClient from "./useAxiosClient";
import { TBorrowers, TTrader } from "../types/index";
import { TRootState } from "../store";
import { UpdateplatformType } from "../reducers/borrowerSlice";

const useAllBorrowers = () => {

  const dispatch = useDispatch();
  const { handleGetCall } = useAxiosClient();
  const { adminId, role } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
    role: state.auth.userInfo?.roleType,
  }));
  const [allBorrowers, setAllBorrowers] = useState<TBorrowers[]>([]);
  const [onRefresh, setOnRefresh] = useState(false);

  useEffect(() => {
    handleGetCall<TBorrowers[]>(`admin/cash/borrowers/adminId/${adminId}`).then(
      ({ data }) => {
        if (data) {
          setAllBorrowers(data);
          console.log(data, "data borrowers");
        } else {
          setAllBorrowers([]);
        }
      }
    );
  }, []);

  return { allBorrowers, onRefresh, setOnRefresh, role };
};
export default useAllBorrowers;
