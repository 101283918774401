import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useAxiosClient from "../../hooks/useAxiosClient";
import { TRootState } from "../../store";
import { TOpenTradeReportsData } from "../../types";

const useOpenTradeReports = () => {
  const { handleGetCall } = useAxiosClient();

  const [isLoading, setIsLoading] = useState(true);


  const { adminId } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
  }));

  const [openTradeReports, setOpenTradeReports] = useState<
    TOpenTradeReportsData[]
  >([]);
  useEffect(() => {
    handleGetCall<TOpenTradeReportsData[]>(
      `reports/mandi-recon/open-trades/adminId/${adminId}`
    ).then(({ data }) => {
      if (data) {
        setOpenTradeReports(data);
        setIsLoading(false);
      } else {
        setOpenTradeReports([]);
        setIsLoading(false);
      }
    });
  }, []);

  return {
    openTradeReports,
    setOpenTradeReports,
    isLoading
  };
};
export default useOpenTradeReports;
