import React from "react";
import GaugeChart from "react-gauge-chart";

type MemoizedGaugeChartProps = {
  percent: number; 
  id: string;
};

const MemoizedGaugeChart: React.FC<MemoizedGaugeChartProps> = React.memo(
  ({ percent, id }) => (
    <GaugeChart
      id={id}
      nrOfLevels={10}
      percent={percent}
      textColor="#00b300"
      colors={["#FF5F6D", "#FFC371", "#00b300"]}
      arcPadding={0.01}
      style={{ width: "100px", height: "60px" }}
      formatTextValue={() => ""}
    />
  )
);

export default MemoizedGaugeChart;
