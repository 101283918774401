import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import useAxiosClient from "../../../../hooks/useAxiosClient";
import { TRootState } from "../../../../store";
import { TApplicationBills, TBillsInfo } from "../../../../types";

const useAllBills = ({ bills }: { bills: TApplicationBills[] }) => {
  const { handleGetCall } = useAxiosClient();
  const { adminId, role } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
    role: state.auth.userInfo?.roleType,
  }));
  const [billArray, setBillArray] = useState<TBillsInfo[]>([]);

  const getBills = () => {
    if (bills.length > 0) {
      const url = `admin/cash/bills/adminId/${adminId}?${bills
        .map((item) => `billIds=${encodeURIComponent(item?.billId)}`)
        .join("&")}`;

      handleGetCall<TBillsInfo[]>(url).then(({ data }) => {
        if (data) {
          setBillArray(data);
          console.log(data, "data bills");
        } else {
          setBillArray([]);
        }
      });
    } else {
      setBillArray([]);
    }
  };

  return {
    getBills,
    billArray,
    bills
  };
};
export default useAllBills;
