import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { TRootState } from "../../../store";
import { TLoanApplications, TLoansSmartboard } from "../../../types";
import moment from "moment";

const useAllLoanApplications = ({
  fromDate,
  toDate,
}: {
  fromDate?: string;
  toDate?: string;
}) => {
  const { handleGetCall } = useAxiosClient();
  const { adminId, role } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
    role: state.auth.userInfo?.roleType,
  }));
  const [loanApplicationArray, setLoanApplicationArray] = useState<
    TLoanApplications[]
  >([]);

  const [dateRange, setDateRange] = useState({
    fromDate: moment().startOf("month").format("YYYY-MM-DD"),
    toDate: moment().endOf("month").format("YYYY-MM-DD"),
  });
  const onChangeDateRange = (data: any) => {
    if (
      (data.fromDate !== dateRange.fromDate &&
        data.toDate !== dateRange.toDate) ||
      true
    ) {
      setDateRange({ fromDate: data.fromDate, toDate: data.toDate });
    }
  };
  const [activeTab, setActiveTab] = useState("all");
  const [searchText, setSearchText] = useState("");
  const [filteredTraders, setFilteredTraders] = useState(loanApplicationArray);

  const filterTraders = () => {
    let filtered = loanApplicationArray;
    const trimmedSearchText = searchText.trim().toLowerCase();
    if (searchText.length > 0) {
      filtered = filtered.filter((option) => {
        const hasBwrNameMatch = option.bwrName
          ?.toLowerCase()
          .includes(trimmedSearchText);

        const hasPartyNameMatch = option.parties?.some((party) =>
          party.partyName?.toLowerCase().includes(trimmedSearchText)
        );

        const hasDisbIdMatch = option.parties?.some((party) =>
          party.disbId?.toString().toLowerCase().includes(searchText)
        );

        return hasBwrNameMatch || hasPartyNameMatch || hasDisbIdMatch;
      });
    }
    setFilteredTraders(filtered);
  };

  useEffect(() => {
    const { fromDate: selectedFromDate, toDate: selectedToDate } = dateRange;
    const tab = activeTab == "all" ? "" : activeTab.toUpperCase();
    handleGetCall<TLoanApplications[]>(
      `admin/cash/smart-board/disbursements/adminId/${adminId}?fromDate=${selectedFromDate}&toDate=${selectedToDate}${
        tab ? `&type=${tab}` : ""
      }`
    ).then(({ data }) => {
      if (data) {
        setLoanApplicationArray(data);
      } else {
        setLoanApplicationArray([]);
      }
    });
  }, [activeTab, dateRange]);

  useEffect(() => {
    if (activeTab == "all") {
      setDateRange({
        fromDate: moment().startOf("month").format("YYYY-MM-DD"),
        toDate: moment().endOf("month").format("YYYY-MM-DD"),
      });
    }
  }, [activeTab]);

  return {
    loanApplicationArray,
    activeTab,
    setActiveTab,
    searchText,
    setSearchText,
    onChangeDateRange,
    dateRange,
    filterTraders,
    filteredTraders,
  };
};
export default useAllLoanApplications;
