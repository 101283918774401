import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/commons/page-header";
import { Button } from "../../components/ui";
import { LOGGEDIN } from "../../constants/storage-keys";
import { handelLogout } from "../../reducers/authSlice";
import defultProfilePic from "../../assets/images/single_bill.svg";
import { TRootState } from "../../store";
import call from "../../assets/images/call.svg";
import market from "../../assets/images/mandi.svg";
import logout from "../../assets/images/logout.svg";
import { useContext } from "react";
import { deviceInfoContext } from "../../context";
import PageMainHeader from "../../components/commons/page-main-header";

const MyProfile = () => {
  const { width } = useContext(deviceInfoContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOut = () => {
    localStorage.clear();
    dispatch(handelLogout(null));
    navigate("/");
  };
  const { userInfo, adminId } = useSelector((state: TRootState) => ({
    userInfo: state.auth.userInfo,
    adminId: state.auth.adminId,
  }));
 
  return (
    <>
      <div className="p-4">
        <div className="bg-light-primary px-3 py-2 mt-2 rounded-t-md">
          <span className="text-sm">Personal Details</span>
        </div>
        <div className="bg-white px-3 py-4 border rounded-b-md">
          <div className="flex mb-3 gap-2">
            <img src={defultProfilePic} className="w-8" />
            <span className="flex flex-col">
              <span className="color-sub-text text-xs">Full Name</span>
              <span className="font-medium text-sm">
                {userInfo?.name || "-"}
              </span>
              <span className="font-medium text-sm">
              {`User Id: ${adminId}`}
              </span> 
            </span>
          </div>
          <div className="flex gap-2 mb-3">
            <img src={call} className="w-8" />
            <span className="flex flex-col">
              <span className="color-sub-text text-xs">Contact Number</span>
              <span className="font-medium text-sm">
                {userInfo?.mobile || "-"}
              </span>
            </span>
          </div>
        </div>
        {/* <div className="bg-light-primary px-3 py-2 mt-3 rounded-t-md">
          <span className="text-sm">User Details</span>
        </div>
        <div className="bg-white px-3 py-4 border rounded-b-md">
          <div className="flex items-start">
            <span>
              {" "}
              <span className="flex mb-3">
                <span className="color-sub-text text-xs">ID</span>
                <span className="font-medium text-sm">
                  {userInfo?.adminId || "-"}
                </span>
              </span>
              <span className="flex">
                <span className="color-sub-text text-xs">Role Type</span>
                <span className="font-medium text-sm">
                  {userInfo?.roleType || "-"}
                </span>
              </span>
            </span>
          </div>
        </div> */}
      </div>
      <div className="w-full fixed bottom-10" style={{ width: width }}>
        <Button
          className="mx-auto"
          shape="round"
          variant="red"
          // styles={{ left: "50%", transform: "translateX(-50%)" }}
          onClick={() => {
            logOut();
          }}
        >
          <img src={logout} className="pr-2" />
          Logout
        </Button>
      </div>
    </>
  );
};
export default MyProfile;
