import { FC, useEffect, useState } from "react";
import onoPayout from "../../../../../assets/images/ono_payout.svg";
import { TApplicationViewDetails, TBillsInfo } from "../../../../../types";
import RecordDisbursal from "../../Advance-Disbursal/record-disbursal";
import { onoArkAccount } from "../../../../../constants";
import { maskAccountNumber } from "../../../../../helpers/getText";
import { getCurrencyNumberWithSymbol } from "../../../../../helpers/get-currency-number";

const DisPayoutStep1: FC<{
  applicationData: TApplicationViewDetails | null;
  // tradeId: number;
  // recordType?: string;
  disbModalClose: any;
  selectedBills: TBillsInfo[];
  setStep: any;
  inputValue: any;
  disRecordPayoutData: any;
}> = ({
  applicationData,
  disbModalClose,
  selectedBills,
  setStep,
  inputValue,
  disRecordPayoutData,
}) => {
  // const {
  //   recordPayoutData,
  //   tradeDetail,
  //   setPaymentDate,
  //   setPaymentType,
  //   setPaymentMode,
  //   setComments,
  //   setAmount,
  //   setAdvance,
  // } = useDisPayoutStep1();

  return (
    <>
      <div
        className="py-2 overflow-y-auto"
        style={
          {
            // height: isMobile ? "calc(100vh - 18rem)" : "calc(100vh - 12rem)",
          }
        }
      >
        <div className="px-8 pb-2">
          {" "}
          <span className="font-semibold text-sm">From</span>
          <div className="flex border rounded-md p-2">
            <div className="flex border-b md:border-none pb-2 md:pb-0">
              <img src={onoPayout} className="w-12 h-12 br-100" />
              <div className="ml-2 flex flex-col">
                <span className="font-semibold text-sm">
                  {onoArkAccount.accountName}
                </span>
                <span className="text-sm font-light">
                  {maskAccountNumber(onoArkAccount.accountNumber)}
                </span>
                <span className="text-sm font-light">
                  {onoArkAccount.bankName}
                </span>
              </div>
            </div>
          </div>
          <div className="text-sm font-light">
            Balance Available: {""}
            {getCurrencyNumberWithSymbol(
              disRecordPayoutData?.availableBalance,
              true
            )}
          </div>
        </div>
        <RecordDisbursal
          applicationData={applicationData}
          disbModalClose={disbModalClose}
          fromPayout={true}
          setStep={setStep}
          inputValue={inputValue}
        />
      </div>
    </>
  );
};
export default DisPayoutStep1;
