import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useAxiosClient from "../../hooks/useAxiosClient";
import { TRootState } from "../../store";
import { TCashReportsData } from "../../types";
import { updateRecordPayoutData } from "../../reducers/tradeSlice";

const useReportsDetails = ({
  fromDate,
  toDate,
}: //openStatus,
{
  fromDate?: string;
  toDate?: string;
  //openStatus: boolean;
}) => {
  const { handleGetCall, handlePostCall } = useAxiosClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { adminId, role, reportsPayouts } = useSelector(
    (state: TRootState) => ({
      adminId: state.auth.adminId,
      role: state.auth.userInfo?.roleType,
      reportsPayouts: state.trades.reportsPayouts,
    })
  );
  const [allReportsData, setAllReportsData] =
    useState<TCashReportsData[]>(reportsPayouts);
  const [activeTab, setActiveTab] = useState("creditDebitReports");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const type = activeTab == "payables_report" ? "OUTWARD" : "INWARD";
    handleGetCall<TCashReportsData[]>(
      fromDate != ""
        ? `reports/mandi-recon/cash-flow/adminId/${adminId}/period/MONTHLY?fromDate=${fromDate}&toDate=${toDate}`
        : `reports/mandi-recon/cash-flow/adminId/${adminId}/period/MONTHLY?period=MONTHLY`
    ).then(({ data }) => {
      if (data) {
        setAllReportsData(data);
        dispatch(
          updateRecordPayoutData({
            reportsPayouts: data,
          })
        );
      } else {
        setAllReportsData([]);
      }
    });
  }, [activeTab, fromDate, toDate]);

  return {
    activeTab,
    setActiveTab,
    allReportsData,
    searchText,
    setSearchText,
    reportsPayouts,
  };
};
export default useReportsDetails;
