import moment from "moment";
import { getCurrencyNumberWithOutSymbol } from "../../helpers/get-currency-number";
import useOpenTradeReports from "./useOpenTradeReports";
import { useEffect, useState } from "react";
import NodataAvailable from "../../assets/images/NodataAvailable.svg";
import { Input } from "../../components/ui";
import { FaSearch } from "react-icons/fa";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import excel from "../../assets/images/excel.svg";
import Loading from "../../assets/images/loading.gif";

const OpenTradesPaymentReports = () => {
  const { openTradeReports, isLoading } = useOpenTradeReports();

  const [filteredTraders, setFilteredTraders] = useState<any[]>([]);
  const [searchText, setSearchText] = useState("");

  const filterTraders = () => {
    let filtered = openTradeReports;
    const trimmedSearchText = searchText.trim().toLowerCase();
    filtered = filtered?.filter(
      (option) =>
        option.tradeId &&
        option.tradeId.toString().toLowerCase().includes(trimmedSearchText)
    );
    setFilteredTraders(filtered);
  };

  const data = filteredTraders.map((item, i) => ({
    tradeId: item.tradeId,
    dispatchDate: item.dispatchDate,
    arlDate: item.arlDate,
    srcPmtTerm: item.srcPmtTerm,
    srcDueDate: item.srcDueDate,
    srcDelay: item.srcDelay,
    netPayable: item.netPayable,
    paidAmt: item.paidAmt,
    toBePaid: item.toBePaid,
    destPmtTerm: item.destPmtTerm,
    destDueDate: item.destDueDate,
    destDelay: item.destDelay,
    netReceivable: item.netReceivable,
    rcvdAmt: item.rcvdAmt,
    dueAmt: item.dueAmt,
  }));
  const handleExport = () => {
    const headers = [
      "Trade Id",
      "Dispatch Date",
      "Arrival Date",
      "Src Payment Term",
      "Src Due Date",
      "Src Delayed Days",
      "Net Payable",
      "Amount Paid",
      "Source Balance",
      "Dest Payment Term",
      "Dest Due Date",
      "Delayed Days",
      "Net Receivable",
      "Amount Received",
      "Destination Balance",
    ];
    const exportData = [
      headers,
      ...data.map((row) => [
        row.tradeId,
        row.dispatchDate,
        row.arlDate,
        row.srcPmtTerm,
        row.srcDueDate,
        row.srcDelay,
        row.netPayable,
        row.paidAmt,
        row.toBePaid,
        row.destPmtTerm,
        row.destDueDate,
        row.destDelay,
        row.netReceivable,
        row.rcvdAmt,
        row.dueAmt,
      ]),
    ];
    if (data.length > 0) {
      const worksheet = XLSX.utils.aoa_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Reports");
      // Export the workbook to an Excel file
      XLSX.writeFile(workbook, `Open Trade Summary report.xlsx`);
    } else {
      toast.error("No data available to export excel.");
    }
  };

  // Call filterTraders whenever searchText or selectedStatus in Redux changes
  useEffect(() => {
    filterTraders();
  }, [searchText, openTradeReports]);

  return (
    <>
      <div className="flex justify-between pb-3">
        <div className="relative w-full" style={{ width: "30%" }}>
          <FaSearch
            className="absolute color-primary"
            size={15}
            style={{ top: "14px", left: "6px" }}
          />
          <Input
            type="text"
            palceholder="Search by Trade Id"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            inputStyle={{ textIndent: "22px", padding: "8px 5px" }}
          />
        </div>
        <div
          onClick={() => {
            handleExport();
          }}
          className="text-sm font-medium cursor-pointer flex items-center justify-center br-10 gap-2 bg-white"
          style={{
            padding: "8px 5px",
          }}
        >
          <img src={excel} alt="img" className="" style={{ width: "25px" }} />{" "}
          <span>Export to Excel</span>
        </div>
      </div>
      <div
        className="overflow-auto"
        style={{
          height: "calc(100vh - 10rem)",
        }}
      >
        <table className="text-sm table-auto border-collapse">
          <thead className="sticky top-0 bg-gray-50 z-10">
            <tr className="bg-light-primary br-10">
              <th className="px-2 md:px-4 py-2 text-center border-r-gray-400">
                #
              </th>
              <tr>
                <th
                  colSpan={3}
                  className="px-4 py-4 bg-light-primary text-sm border-r-gray-400 font-bold md:px-4 border-b-gray-400"
                >
                  Trade Details
                </th>
                <th
                  colSpan={6}
                  className="px-4 md:px-4 py-4 bg-light-primary text-sm border-r-gray-400 font-bold border-b-gray-400"
                >
                  Source Details
                </th>
                <th
                  colSpan={6}
                  className="px-4 md:px-4 py-4 bg-light-primary text-sm font-bold border-b-gray-400"
                >
                  Destination Details
                </th>
              </tr>
              <tr>
                <th
                  className="px-2 md:px-4 py-2 font-medium"
                  style={{ minWidth: "100px" }}
                >
                  Trade Id
                </th>
                <th
                  className="px-2 md:px-4 py-2  font-medium"
                  style={{ minWidth: "150px" }}
                >
                  Dispatch Date
                </th>
                <th
                  className="px-2 md:px-4 py-2 border-r-gray-400 font-medium"
                  style={{ minWidth: "150px" }}
                >
                  Arrival Date
                </th>
                <th
                  className="px-2 md:px-4 py-2 font-medium"
                  style={{ minWidth: "150px", width: "150px" }}
                >
                  Payment Term
                </th>
                <th
                  className="px-2 md:px-4 py-2 font-medium"
                  style={{ minWidth: "150px" }}
                >
                  Due Date
                </th>
                <th
                  className="px-2 md:px-4 py-2  font-medium"
                  style={{ minWidth: "120px", width: "120px" }}
                >
                  Delayed Days
                </th>
                <th
                  className="px-2 md:px-4 py-2  font-medium"
                  style={{ minWidth: "150px" }}
                >
                  Net Payable (₹)
                </th>
                <th
                  className="px-2 md:px-4 py-2  font-medium"
                  style={{ minWidth: "180px" }}
                >
                  Amount Paid (₹)
                </th>

                <th
                  className="px-2 md:px-4 py-2  border-r-gray-400 font-medium"
                  style={{ minWidth: "150px" }}
                >
                  Balance (₹)
                </th>
                <th
                  className="px-2 md:px-4 py-2 font-medium"
                  style={{
                    minWidth: "150px",
                  }}
                >
                  Payment Term
                </th>
                <th
                  className="px-2 md:px-4 py-2 font-medium"
                  style={{
                    minWidth: "200px",
                  }}
                >
                  Due Date
                </th>
                <th
                  className="px-2 md:px-4 py-2 font-medium"
                  style={{
                    minWidth: "150px",
                  }}
                >
                  Delayed Days
                </th>
                <th
                  className="px-2 md:px-4 py-2 font-medium"
                  style={{ minWidth: "160px", width: "160px" }}
                >
                  Net Receivable (₹)
                </th>

                <th
                  className="px-2 md:px-4 py-2 font-medium"
                  style={{
                    minWidth: "180px",
                    width: "180px",
                  }}
                >
                  Amount Received (₹)
                </th>
                <th
                  className="px-2 md:px-4 py-2 font-medium"
                  style={{ minWidth: "170px" }}
                >
                  Balance (₹)
                </th>
              </tr>
            </tr>
          </thead>

          {isLoading ? (
            <tbody>
              <tr>
                <td colSpan={100} className="md:text-center p-0">
                  <div className="bg-white flex justify-center items-center py-5">
                    <img src={Loading} alt="loading" className="w-16 h-16" />
                  </div>
                </td>
              </tr>
            </tbody>
          ) : filteredTraders.length > 0 ? (
            <tbody>
              {(searchText !== ""
                ? filteredTraders.filter((trade) => {
                    return trade.tradeId.toString().includes(searchText);
                  })
                : filteredTraders
              ).map((trade, index) => (
                <tr key={trade.payoutId} className="cursor-pointer bg-white">
                  <td className="px-2 md:px-4 py-2 text-center border-r-gray-400">
                    {index + 1}
                  </td>
                  <tr>
                    <td
                      className="px-2 md:px-4 py-2 font-medium"
                      style={{
                        minWidth: "100px",
                      }}
                    >
                      {trade.tradeId}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2 text-sm"
                      style={{
                        minWidth: "150px",
                      }}
                    >
                      {trade.dispatchDate
                        ? moment(trade.dispatchDate).format("DD-MMM-YYYY")
                        : "-"}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2 border-r-gray-400"
                      style={{
                        minWidth: "150px",
                      }}
                    >
                      {trade.arlDate
                        ? moment(trade.arlDate).format("DD-MMM-YYYY")
                        : "-"}
                    </td>

                    <td
                      className="px-2 md:px-4 py-2 text-sm font-medium"
                      style={{ minWidth: "150px" }}
                    >
                      {" "}
                      {trade.srcPmtTerm || "-"}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2 text-sm"
                      style={{ minWidth: "150px" }}
                    >
                      {" "}
                      {trade.srcDueDate
                        ? moment(trade.srcDueDate).format("DD-MMM-YYYY")
                        : "-"}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2 tesxt-sm font-medium"
                      style={{ minWidth: "120px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.srcDelay) || 0}
                    </td>

                    <td
                      className="px-2 md:px-4 py-2 text-sm font-medium"
                      style={{ minWidth: "150px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.netPayable) || 0}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2 text-sm font-medium"
                      style={{ minWidth: "180px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.paidAmt) || 0}
                    </td>

                    <td
                      className="px-2 md:px-4 py-2 text-sm font-medium border-r-gray-400"
                      style={{ minWidth: "150px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.toBePaid) || 0}
                    </td>

                    <td
                      className="px-2 md:px-4 py-2 text-sm font-medium"
                      style={{ minWidth: "150px" }}
                    >
                      {" "}
                      {trade.destPmtTerm || "-"}
                    </td>

                    <td
                      className="px-2 md:px-4 py-2 text-sm"
                      style={{ minWidth: "200px" }}
                    >
                      {" "}
                      {trade.destDueDate
                        ? moment(trade.destDueDate).format("DD-MMM-YYYY")
                        : "-"}
                    </td>

                    <td
                      className="px-2 md:px-4 py-2 text-sm font-medium"
                      style={{ minWidth: "150px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.destDelay) || 0}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2 text-sm font-medium"
                      style={{ minWidth: "160px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.netReceivable) || 0}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2 text-sm font-medium"
                      style={{ minWidth: "180px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.rcvdAmt) || 0}
                    </td>

                    <td
                      className="px-2 md:px-4 py-2 text-sm font-medium"
                      style={{ minWidth: "170px" }}
                    >
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.dueAmt) || 0}
                    </td>
                  </tr>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={100} className="md:text-center p-0">
                  <div className="bg-white">
                    <img
                      src={NodataAvailable}
                      alt="No Data"
                      className="pl-12 md:pl-0 md:mx-auto py-5"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};
export default OpenTradesPaymentReports;
