import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../../store";
import { setPayoutCurrentStep, setRecordPayoutData, updateRecordPayoutData } from "../../../reducers/tradeSlice";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { TRecordPayoutData } from "../../../types";
import { getAccLastFourDigits } from "../../../helpers/getText";
import { useNavigate } from "react-router-dom";


const useRecordPayout = () => {

    const navigate = useNavigate();

    const { handleGetCall, handlePostCall } = useAxiosClient();

    const [billCreationStatus, setBillCreationStatus] = useState<{
        status: "" | "PENDING" | "COMPLETED" | "LOADING";
    }>({ status: "PENDING" });


    const dispatch = useDispatch();
    const {
        adminId,
        currentStep,
        recordPayoutData,
        tradeDetail,
    } = useSelector((state: TRootState) => ({
        adminId: state.auth.adminId,
        currentStep: state.trades.payoutCurrentStep,
        recordPayoutData: state.trades.recordPayoutData,
        tradeDetail: state.trades.tradeDetails,
    }));

    useEffect(() => {
        if (recordPayoutData.availableBalance == 0) {
            getAvaliableBalance();
        }
    }, []);

    const getAvaliableBalance = () => {
        handleGetCall(`admin/payouts/avlBal/adminId/${adminId}`).then((data) => {
            if (data) {
                dispatch(
                    updateRecordPayoutData({
                        ...recordPayoutData,
                        availableBalance: data.data,
                    })
                )
            }
        });
    }

    const onNext = (closeModal: any) => {
        if (currentStep == 1 && !validateStep1()) {
            sendOtp();
        }
        else if (currentStep == 2) {
            submitRecordPayout(closeModal);
        }
    }

    const onPrevious = () => {
        if (currentStep == 2) {
            dispatch(setPayoutCurrentStep(currentStep - 1));
        }
    }

    const validateStep1 = () => {
        let isValidationErrors = false;
        if (!tradeDetail?.payAcc?.rzpFa) {
            toast.error("Please activate a fund account");
            isValidationErrors = true;
        }
        else if (!recordPayoutData.partyId) {
            toast.error("Select Party");
            setBillCreationStatus({ ...billCreationStatus, status: "" });
            isValidationErrors = true;
        }
        else if (!recordPayoutData.paidAmount) {
            toast.error("Enter Paid/Received amount");
            setBillCreationStatus({ ...billCreationStatus, status: "" });
            isValidationErrors = true;
        }
        else if (recordPayoutData.paymentType == "") {
            toast.error("Please select payment type");
            setBillCreationStatus({ ...billCreationStatus, status: "" });
            isValidationErrors = true;
        }
        else if (recordPayoutData.paymentMode == "") {
            toast.error("Please select payment mode");
            setBillCreationStatus({ ...billCreationStatus, status: "" });
            isValidationErrors = true;
        }
        return isValidationErrors;

    }

    const sendOtp = (isResend = false) => {
        setBillCreationStatus({ ...billCreationStatus, status: "LOADING" });
        let apiParams = {
            "id": recordPayoutData.id,
            "adminId": adminId,
            "type": "TRADE",
            "reqId": tradeDetail?.tradeId,
            "pmtMode": recordPayoutData.paymentMode,
            "amount": recordPayoutData.paidAmount,
            "accNum": getAccLastFourDigits(recordPayoutData.accountNumber),
            "name": tradeDetail?.payAcc?.actHolder,
        };
        handlePostCall({
            URL: 'admin/trade-platform/trades/payments/otp',
            apiParams: apiParams,
        }).then(({ data, status }) => {
            let d = data as any;
            if (data) {
                if (!isResend) {
                    dispatch(setPayoutCurrentStep(currentStep + 1));
                }
                dispatch(
                    updateRecordPayoutData({
                        ...recordPayoutData,
                        id: recordPayoutData.id <= 0 ? d.id : recordPayoutData.id,
                        refId: "",
                        otp: "",
                    }),
                );
                toast.success(status.description);
            } else {
                toast.error(status.description);
            }
            setBillCreationStatus({ ...billCreationStatus, status: "COMPLETED" });
        }).catch(() => {
            setBillCreationStatus({ ...billCreationStatus, status: "" });
        });
    }


    const submitRecordPayout = (
        closeModal: (paymentDone: boolean) => void
    ) => {
        setBillCreationStatus({ ...billCreationStatus, status: "LOADING" });
        handlePostCall({
            URL: "admin/payouts/traders/payouts",
            apiParams: {
                adminId: adminId,
                date: moment(recordPayoutData.paymentDate).format("YYYY-MM-DD"),
                amount: recordPayoutData?.paidAmount,
                currency: "INR",
                fund_account_id: tradeDetail?.payAcc?.rzpFa,
                mode: recordPayoutData.paymentMode,
                purpose: recordPayoutData.paymentType,
                tradeId: tradeDetail?.tradeId,
                traderId: recordPayoutData.partyId,
                notes: {
                    notes_key_1:
                        tradeDetail?.payAcc?.note1 ?? recordPayoutData?.comments,
                    notes_key_2:
                        tradeDetail?.payAcc?.note2 ?? recordPayoutData?.comments,
                },
                comments: recordPayoutData.comments,
                reference_id: recordPayoutData.refId,
            },
        })
            .then(({ data, status }) => {
                if (data) {
                    toast.success(status.description);
                    dispatch(
                        setRecordPayoutData({
                            partyId: 0,
                            inOutWard: "",
                            accountNumber: "",
                            fundAccId: ""
                        })
                    );
                    closeModal(true);
                    navigate(
                        `/trade-detail/${tradeDetail?.srcPartyId}/${tradeDetail?.tradeId}?tab=OPEN`
                    );
                } else {
                    toast.error(status.description);
                }
                setBillCreationStatus({ ...billCreationStatus, status: "COMPLETED" });
            })
            .catch(() => {
                setBillCreationStatus({ ...billCreationStatus, status: "" });
            });
    };


    return {
        currentStep,
        onPrevious,
        onNext,
        billCreationStatus,
        recordPayoutData,
        setBillCreationStatus,
        sendOtp,
    };
}

export default useRecordPayout;