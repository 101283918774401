import { FC } from "react";
import PayoutStep1 from "./step1/payout_step1";
import useRecordPayout from "./useRecordPayout";
import Steps from "../../../components/steps";
import Button from "../../../components/ui/button";
import PayoutStep2 from "./step2/payout_step2";



const RecordPayout: FC<{
  traderId: number;
  tradeId: number;
  onClose: (paymentDone: boolean) => void;
  recordType?: string;
}> = ({ traderId, tradeId, onClose, recordType }) => {

  const {
    currentStep,
    onPrevious,
    onNext,
    billCreationStatus,
    recordPayoutData,
  } = useRecordPayout();

  return (
    <div>
      <div className="flex justify-center pr-8 mt-2">
        <Steps currentStep={currentStep} stepCount={2} />
      </div>

      {
        currentStep == 1 &&
        <PayoutStep1
          traderId={traderId}
          tradeId={tradeId}
          recordType={recordType}
        />
      }
      {
        currentStep == 2 &&
        <PayoutStep2 />
      }

      <div className="bg-white flex justify-between mt-4 w-full px-5 pb-2 absolute bottom-3">
        {
          currentStep == 2 ?
            <Button
              variant="outlined"
              onClick={onPrevious}
            >
              &nbsp;PREVIOUS
            </Button>
            :
            <Button
              variant="outlined"
              onClick={() => {
                onClose(false);
              }}
            >
              CANCEL
            </Button>
        }
        <Button
          onClick={() => {
            onNext(onClose);
          }}
          disabled={billCreationStatus.status == 'LOADING' || (currentStep==2 && recordPayoutData.refId.length == 0) ? true : false}
        >
          {currentStep < 2 ? 'NEXT' : 'SUBMIT'}
        </Button>
      </div>
    </div>
  );
}

export default RecordPayout;