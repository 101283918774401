import { useEffect, useState } from "react"; //hooks
import { useDispatch, useSelector } from "react-redux"; //hook from react redux allows component to extract data from redux store state
import useAxiosClient from "./useAxiosClient"; // custom hook used to making HTTP requests using Axios
//TypeScript types/interfaces used for defining data structures.
import {
  TPaymentHistory,
  TPaymentNotifications,
  TTrade,
  TTradeDocument,
} from "../types/index";
import { TRootState } from "../store";
import moment from "moment";
import { toast } from "react-toastify";
import { updateNotifiTraderId, updateRefresh } from "../reducers/tradeSlice";

const usePayNotifications = ({
  status,
}: //openStatus,
{
  status?: boolean;
  //openStatus: boolean;
}) => {
  const { handleGetCall } = useAxiosClient();
  const { adminId, tententType, refresh } = useSelector(
    (state: TRootState) => ({
      adminId: state.auth.adminId,
      tententType: state.auth.userInfo?.type,
      refresh: state.trades.refresh,
    })
  );
  const [notifications, setNotifications] = useState<TPaymentHistory[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetCall<TPaymentHistory[]>(
      `/admin/trade-platform/trades/adminId/${adminId}?tenant=${tententType}`
    ).then(({ data }) => {
      if (data) {
        let d = data.map((d) => {
          let images: TTradeDocument[] = [];
          d.imgs.forEach((img) => {
            images.push({
              ...img,
              docType: img.url.includes(".pdf") ? "pdf" : "image",
            });
          });
          return {
            ...d,
            id: d.pmtId,
            pmtType: d.paymentType,
            images: images,
          };
        });
        setNotifications(d);
        setIsLoading(false);
      } else {
        setNotifications([]);
        setIsLoading(false);
      }
    });
  }, [status, refresh]);

  return { notifications, refresh, isLoading, setIsLoading };
};
export default usePayNotifications;
