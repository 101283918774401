import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import useAxiosClient from "../../../../hooks/useAxiosClient";
import { TRootState } from "../../../../store";
import { setPayoutCurrentStep } from "../../../../reducers/tradeSlice";
import { getAccLastFourDigits } from "../../../../helpers/getText";
import { useNavigate } from "react-router-dom";
import { updateDisRecordPayoutData } from "../../../../reducers/loansSlice";

const useDisRecordPayout = () => {
  const { handleGetCall, handlePostCall } = useAxiosClient();

  const [billCreationStatus, setBillCreationStatus] = useState<{
    status: "" | "PENDING" | "COMPLETED" | "LOADING";
  }>({ status: "PENDING" });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    adminId,
    currentStep,
    disRecordPayoutData,
    applicationViewDetails,
    disburseComments,
  } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
    currentStep: state.trades.payoutCurrentStep,
    disRecordPayoutData: state.loans.disRecordPayoutData,
    applicationViewDetails: state.loans.applicationViewDetails,
    disburseComments: state.loans.disburseComment,
  }));

  useEffect(() => {
    if (disRecordPayoutData.availableBalance == 0) {
      getAvaliableBalance();
    }
  }, []);

  const getAvaliableBalance = () => {
    handleGetCall(`admin/payouts/avlBal/adminId/${adminId}`).then((data) => {
      if (data) {
        dispatch(
          updateDisRecordPayoutData({
            ...disRecordPayoutData,
            availableBalance: data.data,
          })
        );
      }
    });
  };

  const onNext = (closeModal: any) => {
    if (currentStep == 1 && !validateStep1()) {
      sendOtp();
    } else if (currentStep == 2) {
      submitRecordPayout(closeModal);
    }
  };

  const onPrevious = () => {
    console.log(disRecordPayoutData, "disRecordPayoutData");
    if (currentStep == 2) {
      dispatch(setPayoutCurrentStep(currentStep - 1));
    }
  };

  const validateStep1 = () => {
    let isValidationErrors = false;
    // if (!applicationViewDetails?.payAcc?.rzpFa) {
    //   toast.error("Please activate a fund account");
    //   isValidationErrors = true;
    // } else
    if (!applicationViewDetails?.disbId) {
      toast.error("Select Party");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      isValidationErrors = true;
    } else if (applicationViewDetails?.payoutAcc == null) {
      toast.error("Please add account");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      isValidationErrors = true;
    } else if (
      applicationViewDetails?.loanType == 'TRADE_ADV' &&
      (!disRecordPayoutData?.selectedBills ||
      disRecordPayoutData?.selectedBills.length === 0)
    ) {
      toast.error("Please select at least one bill");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      isValidationErrors = true;
    } else if (!disRecordPayoutData.disbAmt) {
      toast.error("Enter Paid/Received amount");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      isValidationErrors = true;
    } else if (
      applicationViewDetails?.loanType == "WORKING_CAPITAL" &&
      !disRecordPayoutData.tenure
    ) {
      toast.error("Please select tenure");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      isValidationErrors = true;
    } else if (disRecordPayoutData.pmtMode == "") {
      toast.error("Please select payment mode");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      isValidationErrors = true;
    }
    return isValidationErrors;
  };

  const sendOtp = (isResend = false) => {
    setBillCreationStatus({ ...billCreationStatus, status: "LOADING" });
    let apiParams = {
      id: disRecordPayoutData.id,
      adminId: adminId,
      type: "LOAN",
      reqId: applicationViewDetails?.disbId,
      pmtMode: disRecordPayoutData.pmtMode,
      amount: disRecordPayoutData.disbAmt,
      accNum: getAccLastFourDigits(applicationViewDetails?.payoutAcc?.actNum),
      name: applicationViewDetails?.partyName,
    };
    handlePostCall({
      URL: "admin/trade-platform/trades/payments/otp",
      apiParams: apiParams,
    })
      .then(({ data, status }) => {
        let d = data as any;
        if (data) {
          if (!isResend) {
            dispatch(setPayoutCurrentStep(currentStep + 1));
          }
          dispatch(
            updateDisRecordPayoutData({
              ...disRecordPayoutData,
              id: disRecordPayoutData.id <= 0 ? d.id : disRecordPayoutData.id,
              refId: "",
              otp: "",
            })
          );
          toast.success(status.description);
        } else {
          toast.error(status.description);
        }
        setBillCreationStatus({ ...billCreationStatus, status: "COMPLETED" });
      })
      .catch(() => {
        setBillCreationStatus({ ...billCreationStatus, status: "" });
      });
  };

  const submitRecordPayout = (closeModal: (paymentDone: boolean) => void) => {
    setBillCreationStatus({ ...billCreationStatus, status: "LOADING" });
    handlePostCall({
      URL: "admin/payouts/loan/payouts",

      apiParams: {
        // accountNumber: applicationViewDetails?.payoutAcc?.actNum,
        adminId: adminId,
        billIds: disRecordPayoutData?.selectedBills?.map((i) => i.billId) || [],
        comments: disRecordPayoutData.comments,
        cpId: applicationViewDetails?.cpId,
        currency: "INR",
        disbAmt: disRecordPayoutData?.disbAmt,
        disbDate: moment(disRecordPayoutData.disbDate).format("YYYY-MM-DD"),
        disbId: applicationViewDetails?.disbId,
        farmerId: applicationViewDetails?.partyId,
        fundAccountId: applicationViewDetails?.payoutAcc?.rzpFa,
        interestRate: applicationViewDetails?.interestRate,
        notes: {
          notes_key_1:
            applicationViewDetails?.payoutAcc?.note1 ||
            disRecordPayoutData?.comments,
          notes_key_2:
            applicationViewDetails?.payoutAcc?.note1 ||
            disRecordPayoutData?.comments,
        },
        pfId: applicationViewDetails?.pfId,
        pmtMode: disRecordPayoutData?.pmtMode,
        referenceId: disRecordPayoutData.refId,
        remarks: disburseComments,
        tenure:
          applicationViewDetails?.loanType == "TRADE_ADV"
            ? applicationViewDetails.tenure
            : disRecordPayoutData?.tenure || "",
      },
    })
      .then(({ data, status }) => {
        console.log(data);
        if (data) {
          toast.success(status.description);

          closeModal(true);
          navigate(`/application-view/${applicationViewDetails?.disbId}`);
        } else {
          toast.error(status.description);
        }
        setBillCreationStatus({ ...billCreationStatus, status: "COMPLETED" });
      })
      .catch(() => {
        setBillCreationStatus({ ...billCreationStatus, status: "" });
      });
  };

  return {
    currentStep,
    onPrevious,
    onNext,
    billCreationStatus,
    disRecordPayoutData,
    setBillCreationStatus,
    sendOtp,
  };
};

export default useDisRecordPayout;
