import bank from "../../../../assets/images/bank.svg";
import NodataAvailable from "../../../../assets/images/NodataAvailable.svg";
import { Button } from "../../../../components/ui";
import Modal from "../../../../components/ui/modal";
import { useState } from "react";
import AddPayoutAccount from "./AddPayoutAccount";
import EmptyProfile from "../../../../assets/images/profile.jpg";
import { TPayoutDetails } from "../../../../types";
import moment from "moment";
import usePayoutDetails from "./usePayoutDetails";
import { useSelector } from "react-redux";
import { TRootState } from "../../../../store";

const PayoutDetails: React.FC<{
  payouts: TPayoutDetails[];
  onAddAcccount: () => void;
  onSubmit: () => void;
  fromBorrower?: boolean;
}> = ({ payouts, onAddAcccount, onSubmit, fromBorrower }) => {
  const [payoutModal, openPayoutModal] = useState(false);

  const { loading, updatePayoutAccount, selectedPayout, onSelectPayout } =
    usePayoutDetails();

  const { role } = useSelector((state: TRootState) => ({
    role: state.auth.userInfo?.roleType,
  }));
  return (
    <div>
      <div
        className={`h-auto border-gray mt-[20px]  br-10 bg-white ${
          fromBorrower ? "" : "ml-[20px] mr-[20px]"
        }`}
      >
        <div className="h-[50px] border-b bg-light-primary rounded-t-md flex items-center justify-between px-2">
          <div className="flex items-center">
            <img src={bank} className="h-5 " />{" "}
            <span className="ml-[10px] text-base">Payout Details</span>
          </div>
          {payouts?.length !== 0 && role === "ADMIN" && (
            <Button
              onClick={() => {
                openPayoutModal(true);
                onAddAcccount();
              }}
            >
              Add Account
            </Button>
          )}
        </div>
        {payouts?.length === 0 ? (
          <div>
            <div className="flex items-center justify-center py-2">
              <img src={NodataAvailable} alt="image" />
            </div>
            {role === "ADMIN" && (
              <div>
                <div className="text-sm text-gray-500 text-center">
                  Please Add an Account to start with Payments.
                </div>
                <div className="flex justify-center py-5">
                  <Button
                    onClick={() => {
                      openPayoutModal(true);
                      onAddAcccount();
                    }}
                  >
                    Add Account
                  </Button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="">
            <div className="flex flex-row space-x-4 overflow-x-scroll border-b">
              {payouts?.map((payout) => (
                <button
                  className={`cursor-pointer px-4 py-2 rounded-t-lg bg-white-200 
                                        ${
                                          selectedPayout?.id === payout.id
                                            ? "color-primary border-green-600 border-b-4"
                                            : ""
                                        }`}
                  onClick={() => {
                    onSelectPayout(payout);
                  }}
                >
                  <span className="flex items-center">
                    {payout.status == 1 && (
                      <div className="br-100 bg-primary mr-1 h-[7px] w-[7px]"></div>
                    )}
                    A/c{" "}
                    {payout.actNum.substring(
                      payout.actNum.length - 4,
                      payout.actNum.length
                    )}
                  </span>
                </button>
              ))}
            </div>
            <div className="flex mt-2 px-2">
              <div className="m-1">
                <img src={EmptyProfile} className="w-8 h-8 " />
              </div>
              <div className="grow-[1] pb-5">
                <div className="flex">
                  <div className="grow-[1] basis-0 px-2">
                    <span className="color-sub-text">Account Holder </span>
                    <br />
                    <span>
                      <span className="text-sm font-semibold">
                        {selectedPayout?.actHolder || "-"}
                      </span>
                    </span>
                  </div>
                  <div className="grow-[1] basis-0 px-2">
                    <span className="color-sub-text">Account Number </span>
                    <br />
                    <span>
                      <span className="text-sm font-semibold">
                        {selectedPayout?.actNum || "-"}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="flex mt-2">
                  <div className="grow-[1] basis-0 px-2">
                    <span className="color-sub-text">Bank Name </span>
                    <br />
                    <span>
                      <span className="text-sm font-semibold">
                        {selectedPayout?.bank || "-"}
                      </span>
                    </span>
                  </div>
                  <div className="grow-[1] basis-0 px-2">
                    <span className="color-sub-text">IFSC Code </span>
                    <br />
                    <span>
                      <span className="text-sm font-semibold">
                        {selectedPayout?.ifsc || "-"}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="flex mt-2">
                  <div className="grow-[1] basis-0 px-2">
                    <span className="color-sub-text">Account Type </span>
                    <br />
                    <span>
                      <span className="text-sm font-semibold">
                        {selectedPayout?.actType || "-"}
                      </span>
                    </span>
                  </div>
                  <div className="grow-[1] basis-0 px-2">
                    <span className="color-sub-text">Mobile Number </span>
                    <br />
                    <span>
                      <span className="text-sm font-semibold">
                        {selectedPayout?.contactNumber || "-"}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="flex mt-2">
                  <div className="grow-[1] basis-0 px-2">
                    <span className="color-sub-text">Email Address </span>
                    <br />
                    <span>
                      <span className="text-sm font-semibold">
                        {selectedPayout?.emailAddress || "-"}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="border-b px-2 py-2"></div>
                <div className="flex mt-2">
                  <div className="grow-[1] basis-0 px-2">
                    <span className="color-sub-text">Customer ID </span>
                    <br />
                    <span>
                      <span className="text-sm font-semibold">
                        {selectedPayout?.rzpCont ?? "-"}
                      </span>
                    </span>
                  </div>
                  {/* <div className="grow-[1] basis-0 px-2">
                                            <span className="color-sub-text">Reference ID </span>
                                            <br />
                                            <span>
                                                <span className="text-sm font-semibold">
                                                </span>
                                            </span>
                                        </div> */}
                  <div className="grow-[1] basis-0 px-2">
                    <span className="color-sub-text">Fund Account ID </span>
                    <br />
                    <span>
                      <span className="text-sm font-semibold">
                        {selectedPayout?.rzpFa ?? "-"}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="flex mt-2">
                  <div className="grow-[1] basis-0 px-2">
                    <span className="color-sub-text">Created On </span>
                    <br />
                    <span>
                      <span className="text-sm font-semibold">
                        {moment(selectedPayout?.createdOn).format(
                          "DD-MMM-YYYY"
                        ) ?? "-"}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {role === "ADMIN" && (
              <div className="flex p-2 justify-end">
                <Button
                  variant="primaryLight"
                  disabled={loading}
                  onClick={() => {
                    updatePayoutAccount(
                      selectedPayout?.id!,
                      selectedPayout?.status === 1 ? 0 : 1,
                      onSubmit
                    );
                  }}
                >
                  {selectedPayout?.status === 1
                    ? "Mark As Inactive"
                    : "Mark As Active"}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        open={payoutModal}
        onClose={() => {
          openPayoutModal(false);
        }}
        width="80vw"
      >
        <AddPayoutAccount
          onClose={() => {
            openPayoutModal(false);
            onSubmit();
          }}
          fromBorrower={fromBorrower}
        />
      </Modal>
    </div>
  );
};

export default PayoutDetails;
