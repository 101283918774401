import moment from "moment";
import {
  getCurrencyNumberWithOutSymbol,
  getCurrencyNumberWithSymbol,
  totalBagsValue,
} from "../../../../helpers/get-currency-number";
import useBillview from "./useBillview";
import getBills from "./useBills";
import React, { useEffect, useMemo, useState } from "react";
import { TPdfSettings } from "../../../../types";
import classes from "./style.module.scss";
import { colorAdjustBg } from "../../../../helpers/getText";
import PartnerInfo from "../../../../components/partner-info";
import { useSelector } from "react-redux";
import { TRootState } from "../../../../store";
import { TApplicationBills, TBillsInfo } from "../../../../types";
import useAllBills from "./useBills";
import onoClick from "../../../../assets/images/ono-click-connect.svg";
import Quantity from "./quantity";

const BillView: React.FC = () => {
  const { billsInfo, billsView } = useSelector((state: TRootState) => ({
    billsInfo: state.loans.billsInfo,
    billsView: state.loans.billsView,
  }));

  const { pdfSettings } = useSelector((state: TRootState) => ({
    pdfSettings: state.loans.pdfSettings,
  }));

  const [pdfSettingsObj, setPdfSettingsObj] = useState<TPdfSettings>();

  const { themeBackground, themeColor } = useMemo(() => {
    let themeBackground =
      pdfSettingsObj != null && pdfSettingsObj?.colorTheme != ""
        ? colorAdjustBg(pdfSettingsObj?.colorTheme, 180) === "#ffffff"
          ? colorAdjustBg(pdfSettingsObj?.colorTheme, 80)
          : colorAdjustBg(pdfSettingsObj?.colorTheme, 180)
        : "#D7F3DD";
    let themeColor =
      pdfSettingsObj != null && pdfSettingsObj?.colorTheme != ""
        ? pdfSettingsObj?.colorTheme
        : "#16a12c";
    return { themeBackground, themeColor };
  }, [pdfSettingsObj?.colorTheme]);

  useEffect(() => {
    if (pdfSettings.length > 0) {
      for (var i = 0; i < pdfSettings.length; i++) {
        if (pdfSettings[i].type == "BUY_BILL") {
          setPdfSettingsObj(pdfSettings[i]);
          break;
        }
      }
    }
  }, []);

  return (
    <>
      <div className="px-10 py-4 h-[90vh] overflow-y-scroll">
        <div
          className={classes.billContainer}
          style={{
            borderColor: themeColor,
          }}
        >
          <div
            className={`${classes.billheader}`}
            style={{
              backgroundColor: themeColor,
            }}
          >
            <div className={`flex px-2 pt-2 justify-between`}>
              <span className="flex flex-col">
                <span className="text-xs">
                  {pdfSettingsObj != null
                    ? pdfSettingsObj?.header?.userLabel
                    : "Owner:"}
                </span>
              </span>
              <span
                className={`text-sm ${pdfSettingsObj ? "" : "color-yellow font-bold"
                  }`}
              >
                {pdfSettingsObj != null
                  ? pdfSettingsObj?.header?.billTypeLabel
                  : "Cash / Credit Bill"}
              </span>
              <span className="flex flex-col">
                <span className="text-right text-xs">Phone:</span>
              </span>
            </div>
            <div className={`flex px-2 pb-2 justify-between ${classes.caInfo}`}>
              <span className="flex flex-col">
                <span className="text-sm">{billsView?.ownerName}</span>
              </span>
              <span></span>
              <span className="flex flex-col">
                <span className="text-sm">
                  {billsView?.contactNum +
                    (billsView?.altMobile ? " | " + billsView.altMobile : "")}
                </span>
              </span>
            </div>
            <div className="flex px-2 py-2">
              <div className="grow-[2] flex justify-center items-center">
                <span>
                  {pdfSettingsObj?.logoUrl ? (
                    <span
                      className={`flex flex-col items-center justify-center w-20 py-1 `}
                    >
                      <img
                        src={pdfSettingsObj?.logoUrl}
                        className={`${classes.shopInfo}`}
                      />
                    </span>
                  ) : (
                    <span
                      className={`flex flex-col items-center justify-center w-20 py-1 color-primary bg-yellow ${classes.shopInfo}`}
                      style={{
                        color: themeColor,
                        height: "3rem",
                      }}
                    >
                      <span>{billsView?.shortCode}</span>
                    </span>
                  )}
                </span>
              </div>
              <div className="grow-[8] flex flex-col items-center text-center">
                <span className="text-3xl font-semibold">
                  {billsView?.businessName}
                </span>
                <span className="font-medium">{billsView?.businessType}</span>
                <span className="font-medium text-xs">
                  {billsView?.addressLine ? billsView?.addressLine : ""},
                  {billsView?.dist},Pincode-
                  {billsView?.pincode},{billsView?.state}
                </span>
              </div>
              <div className="grow-[2] flex justify-center items-center">
                <span>
                  <span
                    className={`flex flex-col items-center justify-center w-20 py-1 color-primary bg-yellow ${classes.shopInfo}`}
                    style={{
                      color: themeColor,
                      height: "3rem",
                    }}
                  >
                    <span className="text-xs">Shop No</span>
                    <span>{billsView?.shopNum}</span>
                  </span>
                </span>
              </div>
            </div>
            <div className="flex relative mt-2">
              <span className="grow-[2] flex justify-center items-center">
                <span
                  className="absolute px-2 py-1"
                  style={{
                    fontSize: "10px",
                    backgroundColor: colorAdjustBg(themeColor, -40),
                    bottom: 0,
                    borderTopLeftRadius: ".4rem",
                    borderTopRightRadius: ".4rem",
                  }}
                >
                  Bill ID: {billsInfo?.billId}
                </span>
              </span>
              <span className="grow-[8]"></span>
              <span className="grow-[2] flex justify-center items-center">
                <span
                  className="absolute px-3 py-1"
                  style={{
                    fontSize: "10px",
                    backgroundColor: colorAdjustBg(themeColor, -40),
                    bottom: 0,
                    borderTopLeftRadius: ".4rem",
                    borderTopRightRadius: ".4rem",
                  }}
                >
                  {moment(billsInfo?.billDate).format("DD-MMM-YY")}
                </span>
              </span>
            </div>
          </div>
          <div className={classes.billBody}>
            <>
              {billsView !== null && (
                <div className="flex justify-between px-2 py-2">
                  <div className="px-2 text-xs mt-2">
                    <span>Bill To : </span>
                    <p className="text-sm font-semibold">
                      {billsInfo?.farmerName}
                    </p>
                  </div>
                  <div className="px-2 text-xs mt-2">
                    <span>Address :</span>
                    <p className="text-sm font-semibold">
                      {billsInfo?.farmerAddress}
                    </p>
                  </div>
                </div>
              )}
              {billsView !== null && (
                <>
                  <div className="overflow-auto bg-white">
                    <table className="min-w-full border-collapse border">
                      <thead style={{ backgroundColor: themeBackground }}>
                        <tr className="bg-light-primary">
                          <th className="px-2 py-2 border text-xs font-semibold ">
                            #
                          </th>
                          <th className="px-2 py-2 border text-xs font-semibold text-left">
                            Particulars
                          </th>
                          <th className="px-2 py-2 border text-xs font-semibold text-left">
                            Qty
                          </th>
                          <th className="px-2 py-2 border text-xs font-semibold text-left">
                            Rate (₹)
                          </th>
                          <th className="px-2 py-2 border text-xs font-semibold text-left">
                            Total (₹)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {billsInfo?.lineItems.map((items, index) => (
                          <tr key={index} className="cursor-pointer border-b">
                            <td className="px-2 py-2 border text-xs text-center">
                              {index + 1}
                            </td>
                            <td className="px-2 py-2 border text-xs">
                              <div className="text-xs flex">
                                <img
                                  src={items?.imageUrl}
                                  className="h-8"
                                  alt="Crop"
                                />
                                <span className="py-2 pl-2">
                                  {items?.cropSufx
                                    ? `${items?.cropName} (${items?.cropSufx})`
                                    : items?.cropName}
                                </span>
                              </div>
                            </td>
                            <td className="px-2 py-2 border text-xs">
                              <Quantity
                                quantity={items.qty}
                                unit={items.qtyUnit}
                                wastage={items.wastage}
                                weight={items.weight}
                                rateType={items.rateType}
                                className="text-xs"
                              />
                              {items.bags !== null ? (
                                <div className="flex_class">
                                  <span>
                                    <span>
                                      {items.bags.map((i) => {
                                        return (
                                          <span>
                                            <span>
                                              {i.weight
                                                ? (i.netWeight
                                                  ? i.weight
                                                  : i.weight + i.wastage) +
                                                " "
                                                : ""}
                                            </span>
                                            <span>
                                              {i.wastage ? " - " : ""}
                                            </span>
                                            <span>
                                              {i.wastage ? i.wastage : ""}
                                            </span>
                                            <span>, </span>
                                          </span>
                                        );
                                      })}
                                      <span>
                                        {" "}
                                        {items.bags
                                          ? `= ${totalBagsValue(items.bags) +
                                          " KGS"
                                          } `
                                          : ""}
                                      </span>
                                    </span>
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                            </td>
                            <td className="px-2 py-2 border text-xs">
                              {getCurrencyNumberWithOutSymbol(items?.rate)}
                            </td>
                            <td className="px-2 py-2 border text-xs color-primary">
                              {getCurrencyNumberWithOutSymbol(items?.total)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}

              {billsView !== null && (
                <>
                  <div
                    className={`flex py-2 font-bold ${classes.heading}`}
                    style={{ backgroundColor: themeBackground }}
                  >
                    <span className="basis-0 grow-[10] text-right text-xs">
                      Gross Total:
                    </span>
                    <span className="basis-0 grow-[2] text-xs pl-6">
                      {getCurrencyNumberWithSymbol(billsInfo?.grossTotal)}
                    </span>
                  </div>
                  <hr />
                  <div style={{ width: "100%" }} className="flex">
                    <div style={{ width: "50%" }}></div>

                    <div
                      className="border-l border-l-green-500"
                      style={{ width: "50%" }}
                    >
                      <div className="items-center justify-center">
                        <div>
                          {billsInfo?.comm !== 0 ? (
                            <div className="flex justify-between py-1 border-b text-xs font-semibold">
                              <span className="grow-[5] basis-0 text-right">
                                Commission:
                              </span>
                              <span className="mx-2 basis-0 grow-[2] text-xs pl-6">
                                {getCurrencyNumberWithSymbol(billsInfo?.comm)}
                              </span>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>

                        {billsInfo?.rtComm !== 0 ? (
                          <div className="flex justify-between py-1 border-b text-xs font-semibold">
                            <span className="grow-[5] basis-0 text-right">
                              Return Commission:
                            </span>
                            <span className="mx-2 basis-0 grow-[2] text-xs pl-6">
                              {getCurrencyNumberWithSymbol(billsInfo?.rtComm)}
                            </span>
                          </div>
                        ) : (
                          <div></div>
                        )}

                        {billsInfo?.labourCharges !== 0 ? (
                          <div className="flex justify-between py-1 border-b text-xs font-semibold">
                            <span className="grow-[5] basis-0 text-right">
                              Labour:
                            </span>
                            <span className="mx-2 basis-0 grow-[2] text-xs pl-6">
                              {getCurrencyNumberWithSymbol(
                                billsInfo?.labourCharges
                              )}
                            </span>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {billsInfo?.transportation !== 0 ? (
                          <div className="flex justify-between py-1 border-b text-xs font-semibold">
                            <span className="grow-[5] basis-0 text-right">
                              Transportaion:
                            </span>
                            <span className="mx-2 basis-0 grow-[2] text-xs pl-6">
                              {getCurrencyNumberWithSymbol(
                                billsInfo?.transportation
                              )}
                            </span>
                          </div>
                        ) : (
                          <div></div>
                        )}

                        {billsInfo?.rent !== 0 ? (
                          <div className="flex justify-between py-1 border-b text-xs font-semibold">
                            <span className="grow-[5] basis-0 text-right">
                              Rent:
                            </span>
                            <span className="mx-2 basis-0 grow-[2] text-xs pl-6">
                              {getCurrencyNumberWithSymbol(billsInfo?.rent)}
                            </span>
                          </div>
                        ) : (
                          <div></div>
                        )}

                        {billsInfo?.wastage !== 0 ? (
                          <div className="flex justify-between py-1 border-b text-xs font-semibold">
                            <span className="grow-[5] basis-0 text-right">
                              Wastage:
                            </span>
                            <span className="mx-2 basis-0 grow-[2] text-xs pl-6">
                              {getCurrencyNumberWithSymbol(billsInfo?.wastage)}
                            </span>
                          </div>
                        ) : (
                          <div>

                          </div>
                        )}

                        {billsInfo?.mandiFee !== 0 ? (
                          <div className="flex justify-between py-1 border-b text-xs font-semibold">
                            <span className="grow-[5] basis-0 text-right">
                              Mandi Fee:
                            </span>
                            <span className="mx-2 basis-0 grow-[2] text-xs pl-6">
                              {getCurrencyNumberWithSymbol(billsInfo?.mandiFee)}
                            </span>
                          </div>
                        ) : (
                          <div></div>
                        )}

                        {billsInfo?.govtLevies !== 0 ? (
                          <div className="flex justify-between py-1 border-b text-xs font-semibold">
                            <span className="grow-[5] basis-0 text-right">
                              Govt Levies:
                            </span>
                            <span className="mx-2 basis-0 grow-[2] text-xs pl-6">
                              {getCurrencyNumberWithSymbol(
                                billsInfo?.govtLevies
                              )}
                            </span>
                          </div>
                        ) : (
                          <div></div>
                        )}

                        {billsInfo?.misc !== 0 ? (
                          <div className="flex justify-between py-1 border-b text-xs font-semibold">
                            <span className="grow-[5] basis-0 text-right">
                              Other Fee:
                            </span>
                            <span className="mx-2 basis-0 grow-[2] text-xs pl-6">
                              {billsInfo?.misc}
                            </span>
                          </div>
                        ) : (
                          <div></div>
                        )}

                        {billsInfo?.advance !== 0 ? (
                          <div className="flex justify-between py-1 border-b text-xs font-semibold">
                            <span className="grow-[5] basis-0 text-right">
                              Advances:
                            </span>
                            <span className="mx-2 basis-0 grow-[2] text-xs pl-6">
                              {getCurrencyNumberWithSymbol(billsInfo?.advance)}
                            </span>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <div>
                          {billsInfo?.customFields
                            .filter((item) => item.fee > 0)
                            .map((cf) => (
                              <div className="flex justify-between py-1 border-b text-xs font-semibold">
                                <span className="grow-[5] basis-0 text-right">
                                  {cf.fieldName}:
                                </span>
                                <span className="mx-2 basis-0 grow-[2] text-xs pl-6">
                                  {cf.less ? "-" : "+"}
                                  {getCurrencyNumberWithSymbol(cf.fee)}
                                </span>
                              </div>
                            ))}
                        </div>
                        <div className="flex justify-between py-1 border-b text-xs font-semibold">
                          <span className="grow-[5] basis-0 text-right">
                            Total Bill Amount:
                          </span>
                          <span className="mx-2 basis-0 grow-[2] text-xs pl-6">
                            {getCurrencyNumberWithSymbol(
                              billsInfo?.totalPayables
                            )}
                          </span>
                        </div>

                        <div className="flex justify-between py-1 border-b text-xs font-semibold">
                          <span className="grow-[5] basis-0 text-right">
                            Outstanding Balance:
                          </span>
                          <span className="mx-2 basis-0 grow-[2] text-xs pl-6">
                            {getCurrencyNumberWithSymbol(
                             billsInfo?.outStBal
                            )}
                          </span>
                        </div>

                        {billsInfo?.cashPaid !== 0 ? (
                          <div className="flex justify-between py-1 border-b text-xs font-semibold">
                            <span className="grow-[5] basis-0 text-right">
                              Cash Paid:
                            </span>
                            <span className="mx-2 basis-0 grow-[2] text-xs pl-6">
                              {getCurrencyNumberWithSymbol(billsInfo?.cashPaid)}
                            </span>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div
                    className="flex text-xs br-5 py-2"
                    style={{
                      backgroundColor: themeBackground,
                    }}
                  >
                    <img src={onoClick} alt="ono_connect" className="pl-3" />
                    <div className="flex py-1 ml-auto w-2/3 font-bold">
                      <span className="basis-0 grow-[6] text-right text-xs font-bold">
                        Final Ledger Balance:
                      </span>
                      <span className="basis-0 grow-[2] text-xs pl-6">
                        {getCurrencyNumberWithSymbol(billsInfo?.finalLedgerBal)}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
};
export default BillView;
